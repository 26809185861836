let firebase = require("firebase/app");
require("firebase/functions");
require("firebase/auth");
require("firebase/firestore");

/* this is the main connection to firebase
in order to initialize a new website you need to open a new firebase project and then:
1. past your project firebaseConfig in this file 
2. configure mail, gmail and facebook authentication
3. manually create this empty documents at the database: Debaters\All_debaters, Debate_History\All_Debate_History
*/

/* main site configuration */
const firebaseConfig = {
  apiKey: "AIzaSyBxFkuO0OKTB2aCUaTAmcGQn_fBt36GZfY",
  authDomain: "debatetest-1399a.firebaseapp.com",
  databaseURL: "https://debatetest-1399a.firebaseio.com",
  projectId: "debatetest-1399a",
  storageBucket: "debatetest-1399a.appspot.com",
  messagingSenderId: "1068732717085",
  appId: "1:1068732717085:web:1f2dc065129b79c2"
};

/* test site configuration */
const firebaseConfigTest = {
  apiKey: "AIzaSyCSpAR81Jl2zOy7MnapFH1eBrxElEwbsaI",
  authDomain: "debateproject.firebaseapp.com",
  databaseURL: "https://debateproject.firebaseio.com",
  projectId: "debateproject",
  storageBucket: "debateproject.appspot.com",
  messagingSenderId: "453183305311",
  appId: "1:453183305311:web:e35865d952c06b00"
};

/* determain which firebase project to connect by url */
if (
  window.location.hostname.includes("debate.org.il") ||
  window.location.hostname.includes("debatetest-1399a.firebaseapp.com")
) {
  firebase.initializeApp(firebaseConfig);

} else {
  firebase.initializeApp(firebaseConfigTest);
  console.log('Test App');
}

export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const sendEmails = functions.httpsCallable("sendEmails");
export const changePermissions = functions.httpsCallable("changePermissions");
export default firebase;
