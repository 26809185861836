import React, { Component } from "react"
import {toast} from "react-toastify";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {CancelButton} from "../Common/Buttons";
import {SubmitButton} from "../Common/Buttons";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import {getFullName} from "../Utilities/DebaterUtilities";

/**
 * A dialog for registering a "temporary" debater with full name and ad-hoc id only
 * To be used in assignments page to assign debaters that are not signed-up to the system
 */
export class AddFakeDebaters extends Component {

  constructor(props) {
      super(props);
      this.state={
          fakeDebaters: []
      };
      this.handleClose = this.handleClose.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.addFakeDebater = this.addFakeDebater.bind(this);
      this.deleteFakeDebater = this.deleteFakeDebater.bind(this);
      this.renameDebater = this.renameDebater.bind(this);
  }

  componentDidMount(){
      if(this.state.fakeDebaters.length === 0) this.addFakeDebater();
  }

  handleClose(){
      this.props.onClose();
  }

  handleSubmit(){
      toast.info("Added " + this.state.fakeDebaters.length + " mock debaters");
      this.props.onSubmit(this.state.fakeDebaters);
  }

  addFakeDebater(){
      const now = new Date();
      const fakeDebaters = this.state.fakeDebaters;
      const newDebater = {
          id: "FAKE"+now.valueOf().toString(),
          full_name_eng: "Mock Debater " + (fakeDebaters.length + 1).toString()
      };
      fakeDebaters.push(newDebater);
      this.setState({fakeDebaters: fakeDebaters});
  }

  deleteFakeDebater(index) {
      const fakeDebaters = this.state.fakeDebaters;
      fakeDebaters.splice(index, 1);
      this.setState({fakeDebaters: fakeDebaters});
  }

  renameDebater(index, value){
      const fakeDebaters = this.state.fakeDebaters;
      fakeDebaters[index].full_name_eng = value;
      this.setState({fakeDebaters: fakeDebaters});
  }

  render() {
      return (
          <div style={{minWidth: 500}}>
              <h3>
                  Add "Mock" Debaters
                  <Fab
                      title={"Create New Events"}
                      onClick={this.addFakeDebater}
                      size="small"
                      color="primary"
                      style={{marginLeft: 15, marginRight: 15}}>
                      <Add />
                  </Fab>
              </h3>

              <div>
                  {this.state.fakeDebaters.map((fakeDebater, index) => {
                      return(
                          <span key={index}>
                              <TextField
                                  value={getFullName(fakeDebater)}
                                  onChange={(e)=>this.renameDebater(index, e.target.value)}/>
                              <IconButton onClick={()=>this.deleteFakeDebater(index)}>
                                  <DeleteIcon/>
                              </IconButton>
                              <br/>
                          </span>
                      );
                  })}
              </div>

              <div align="center" style={{width:'100%'}}>
                  <CancelButton onClick={this.handleClose} disabled={this.props.loading}/>
                  <SubmitButton onClick={this.handleSubmit} disabled={this.props.loading || this.state.fakeDebaters.length === 0}/>
              </div>
          </div>
      )
  }
}
