import React, { Component } from "react"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio";
import {toast} from "react-toastify";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {getFullName, getInitials} from "../Utilities/DebaterUtilities";
import {formatDate, tsToDate} from "../Utilities/DateTimeUtils";
import {isMobile} from "../Utilities/OtherUtils";
import {CancelButton, SubmitButton} from "../Common/Buttons";

const textFieldStyle = {
    width: '92%',
    maxWidth: 400,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    paddingLeft: 5,
};

/**
 * To be filled by chairs after events for scoring and assessing juniors' performances
 */
export class FeedbackForm extends Component {

  constructor(props) {
      super(props);

      this.handleScoreChange = this.handleScoreChange.bind(this);
      this.handlePositiveChange = this.handlePositiveChange.bind(this);
      this.handleNegativeChange = this.handleNegativeChange.bind(this);
      this.handleNotesChange = this.handleNotesChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.clearAll = this.clearAll.bind(this);
  }

  clearAll() {
      Object.keys(this.props.feedback).map(key=>this.props.feedback[key]="");
      this.props.onChange();
  }

  handleSubmit() {
      if(!this.props.feedback['score'] ||
          this.props.feedback['score'] === 0 ||
          !this.props.feedback['positive_feedback'] ||
          !this.props.feedback['negative_feedback']) {
          toast.error("Missing Required Fields");
          return;
      } else {
          this.props.onFinish();
      }
  }

  handlePositiveChange(event) {
      this.props.feedback.positive_feedback = event.target.value;
      this.props.onChange();
  }

  handleNegativeChange(event) {
      this.props.feedback.negative_feedback = event.target.value;
      this.props.onChange();
  }

  handleNotesChange(event){
      this.props.feedback.notes = event.target.value;
      this.props.onChange();
  }

  handleScoreChange(event){
      this.props.feedback.score = event.target.value;
      this.props.onChange();
  }

  render() {
      if(!this.props.debater || !this.props.feedback){
          return null;
      }
      const radioStyle = {padding: 0, margin: 0, width: isMobile() ? 10:25};
      return (
      <div align={"left"}>
          <h2 style={{marginLeft: 10}}>Feedback for {getFullName(this.props.debater)}</h2>
          <span style={{marginLeft: 10}}>
              {formatDate(tsToDate(this.props.event.time)) + " "
              + this.props.event.event_type + " - "
              + (isMobile() ? getInitials(this.props.debater.position) : this.props.debater.position)}
              <br/>
          </span>
        <br/>
      <FormControl component="fieldset">

          <br/><label style={{marginLeft: 10}}>Score:</label><br/>
          <RadioGroup
              name="score"
              style={{display:'inline'}}
              value={this.props.feedback.score.toString()}
              onChange={(e)=>this.handleScoreChange(e)}
              row>

              <FormControlLabel
                value="1"
                control={<Radio style={radioStyle}/>}
                label="1"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="2"
                control={<Radio style={radioStyle}/>}
                label="2"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="3"
                control={<Radio style={radioStyle}/>}
                label="3"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="4"
                control={<Radio style={radioStyle}/>}
                label="4"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="5"
                control={<Radio style={radioStyle}/>}
                label="5"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="6"
                control={<Radio style={radioStyle}/>}
                label="6"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="7"
                control={<Radio style={radioStyle}/>}
                label="7"
                labelPlacement="bottom"
              />
          </RadioGroup>
      </FormControl>
          <br />

          <TextField
              label = "* Positive Feedback"
              margin="normal"
              variant='outlined'
              multiline={true}
              rows="3"
              style={textFieldStyle}
              onChange={e => this.handlePositiveChange(e)}
              value={this.props.feedback.positive_feedback}
          />
          <br />

          <TextField
            label= "* Negative Feedback"

              style={textFieldStyle}
              onChange={e => this.handleNegativeChange(e)}
              value={this.props.feedback.negative_feedback}
              margin="normal"
              variant='outlined'
              multiline={true}
              rows="3"
          />
          <br />

          <TextField
            label="Comments"
            style={textFieldStyle}
            onChange={e => this.handleNotesChange(e)}
            value={this.props.feedback.notes}
            margin="normal"
            variant='outlined'
            multiline={true}
            rows="3"
          />
          <br />
          <p style={{color:'#888888', marginLeft: 10}}>* Marked fields are required</p>
          <div align="center">
              <CancelButton onClick={this.clearAll} label={"Clear"}/>
              <SubmitButton onClick={this.handleSubmit}/>
          </div>
        <br />
        <br />
      </div>
    );
  }

}
