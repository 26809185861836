import React from "react";
import {isSystemAdmin} from "../../Utilities/DebaterUtilities";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import {ToggleButton} from "../../Common/ToggleButton";

/**
 * Toolbar for events management
 */
export function getEventsManagementToolbar(that){
    return (
        <div align="left" style={{width: that.state.width, maxWidth: that.tableWidth - 50, marginTop: 20}}>
            <h1 style={{display: "inline", paddingLeft: 10, marginTop: 0, paddingTop: 0}}>
                Events
                {isSystemAdmin(that.props.user) &&
                <Fab
                    title={"Create New Events"}
                    onClick={() => that.setState({openDialog: true})}
                    size="small"
                    color="primary"
                    style={{marginLeft: 15, marginRight: 15}}>
                    <Add />
                </Fab>}
            </h1>
            <span style={{float: that.state.width < 500 ? 'left':'right', marginTop: 0, marginBottom: 10}}>
                        <ToggleButton
                            values={["past", "future", "both"]}
                            default={that.state.pastFutureFilter}
                            onChange={(value)=>that.setState({pastFutureFilter: value})}/>
                    </span>
            <br/><br/>
        </div>);
}
