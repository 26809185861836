import { db, auth } from "./fire";

/* this page contains all backend functions regarding registration and checkin to events
the registrations are saved in two places:
1. user registration to a specific event is saved under "Debater/debateid/MyEvents/eventsid"
the doc contains an object with registration filed with the users registration data
2. all the registrations rellevant to the specific event are saved at "Events/eventid/EventDat/Registraions"
Registrations is an object with all event registrations with userid as keys

user query their own registration and admin function query all the registration for the event
*/

/* get an eventId and return all all registrations for a specific event from "Events/eventid/EventDat/Registraions" */
export function get_registrations_for_event(eventID) {
  if (!eventID) return;
  let p = new Promise((resolve, reject) => {
    let eventDataRef = db
      .collection("Events")
      .doc(eventID)
      .collection("EventData")
      .doc("Registrations");
    eventDataRef
      .get()
      .then(eventData => {
        if (eventData.exists) {
          resolve(eventData.data());
        } else {
          resolve({});
        }
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* this function return a listener for live registration changes, the caller should manage firebase api for reading the data
  the listener return the whole doc again for every change (and not only the filed the users registration that changed)
  */
export function get_live_registrations_for_event(eventID) {
  let p = new Promise((resolve, reject) => {
    let eventDataRef = db
      .collection("Events")
      .doc(eventID)
      .collection("EventData")
      .doc("Registrations");
    eventDataRef.onSnapshot(snapshot => {
      resolve(snapshot);
    });
  });

  return p;
}

/* add the registration data to the user
  the data is saved both under ther user and under the event (see first page comment for more)
   */
export function add_registration(registration) {
  let userId = auth.currentUser.uid;
  let batch = db.batch();

  //send registration details to firestore in "Events/eventId/EventData/Registrations"
  // Registrations is an object with all event registrations with userid as keys
  let eventRef = db
    .collection("Events")
    .doc(registration.id)
    .collection("EventData")
    .doc("Registrations");

  batch.update(eventRef, {
    [userId]: registration
  });

  // send registration data Debaters/userid/MyEvents/eventid
  let debaterRef = db
    .collection("Debaters")
    .doc(userId)
    .collection("MyEvents")
    .doc(registration.id);
  batch.set(debaterRef, {
    registration: registration
  });

  //save registration data to events and debater in one batch
  return batch.commit();
}

/* this function allows admin to register other users to event
  this registrations has the comment "Manually registered by admins" under additionalComments
  */
export function add_registration_for_user(
  eventId,
  userId,
  registration = null
) {
  if (!registration)
    registration = {
      additionalComments: "Manually registered by admins",
      debateJudge: "No preference",
      debaterRegistrationDate: Date.now(),
      has_error: false,
      id: eventId,
      language: "No preference",
      position: "No preference"
    };
  let batch = db.batch();
  //send registration details to firestore in "Events/eventId/registrationData/Registrations"
  let eventRef = db
    .collection("Events")
    .doc(eventId)
    .collection("EventData")
    .doc("Registrations");

  batch.update(eventRef, {
    [userId]: registration
  });

  // send registration data Debaters/userid/MyEvents/eventid
  if (!userId.includes("FAKE")) {
    let debaterRef = db
      .collection("Debaters")
      .doc(userId)
      .collection("MyEvents")
      .doc(eventId);
    batch.set(debaterRef, {
      registration: registration
    });
  }
  //save registration data to events and debater in one batch
  return batch.commit();
}

/* adds a fake user to event registration */
export function add_fake_registration(
  eventId,
  fakeUserId,
  fakeUserName,
  registration = null
) {
  if (!registration)
    registration = {
      fake_user_name: fakeUserName,
      additionalComments: "Manually registered by admins",
      debateJudge: "No preference",
      debaterRegistrationDate: Date.now(),
      has_error: false,
      id: eventId,
      language: "No preference",
      position: "No preference"
    };

  //send registration details to firestore in "Events/eventId/registrationData/Registrations"
  let eventRef = db
    .collection("Events")
    .doc(eventId)
    .collection("EventData")
    .doc("Registrations");

  return eventRef.update({
    [fakeUserId]: registration
  });
}

/* allow users to checkin - this changes the checkedIn filed in registration to true and change their color to green in the assignment*/
export function check_in(registration) {
  let userId = auth.currentUser.uid;
  registration.checkedIn = true;
  let batch = db.batch();
  //send registration details to firestore in "Events/eventId/registrationData/Registrations"
  let eventRef = db
    .collection("Events")
    .doc(registration.id)
    .collection("EventData")
    .doc("Registrations");

  batch.update(eventRef, {
    [userId]: registration
  });

  // send registration data Debaters/userid/MyEvents/eventid
  if (!userId.includes("FAKE")) {
    let debaterRef = db
      .collection("Debaters")
      .doc(userId)
      .collection("MyEvents")
      .doc(registration.id);
    batch.update(debaterRef, {
      "registration.checkedIn": true
    });
  }
  //save registration data to events and debater in one batch
  return batch.commit();
}

/* allow to mark that users cancel attendance (after assignment where published) - this added a cancelled field in registration to true and change thier color to red in the assignment
when this happens the user is written as cancelled and might get punished
*/
export function cancel_attendance(registration) {
  let userId = auth.currentUser.uid;

  // get registration date from props, user preference form state, and current date for registratino time
  let batch = db.batch();
  //send registration details to firestore in "Events/eventId registrationData"
  let eventRef = db
    .collection("Events")
    .doc(registration.id)
    .collection("EventData")
    .doc("Registrations"); //.doc(registration.eventId)

  batch.update(eventRef, {
    [userId + ".cancelled"]: true,
    [userId + ".checkedIn"]: false
  });

  if (!userId.includes("FAKE")) {
    let debaterRef = db
      .collection("Debaters")
      .doc(userId)
      .collection("MyEvents")
      .doc(registration.id); //.doc(registration.eventId);
    batch.update(debaterRef, {
      "registration.cancelled": true,
      "registration.checkedIn:": false
    });
  }
  // //save registration data to events and debater in one batch
  return batch.commit();
}

/* allow users to cancel attendance on time - this added a cancelledOnTime field in registration to true and change their color to red in the assignment
users wont be punished for this*/
export function cancel_registration(registration) {
  let userId = auth.currentUser.uid;
  // get registration date from props, user preference form state, and current date for registration time
  let batch = db.batch();
  //send registration details to firestore in "Events/eventId registrationData"
  let eventRef = db
    .collection("Events")
    .doc(registration.id)
    .collection("EventData")
    .doc("Registrations"); //.doc(registration.eventId)

  batch.update(eventRef, {
    [userId + ".cancelledOnTime"]: true,
    [userId + ".checkedIn:"]: false
  });

  if (!userId.includes("FAKE")) {
    let debaterRef = db
      .collection("Debaters")
      .doc(userId)
      .collection("MyEvents")
      .doc(registration.id); //.doc(registration.eventId);
    batch.update(debaterRef, {
      "registration.cancelledOnTime": true,
      "registration.checkedIn:": false
    });
  }
  //save registration data to events and debater in one batch
  return batch.commit();
}

/* allow user to register to event, even if the event is closed for his group (e.g. junior to seniors event)*/
export function add_user_to_special_registration(eventId) {
  let uid = auth.currentUser.uid;
  let eventRef = db.collection("Events").doc(eventId);

  return eventRef.update({ ["allowRegToUsers." + uid]: true });
}
