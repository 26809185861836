import React from "react";
import { NavLink } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Tooltip from "@material-ui/core/Tooltip";
import { auth } from "../../../Backend/fire";

const DebaterLinks = props => {
  const getIconStyle = path => ({
    color: props.currentLocation === path ? "#AADDFF" : "#FFFFFF"
  });
  return (
    <ul>
      <li>
        {/* user details show current user details (same Component can show other users details, for admin use) */}
        <NavLink
          to={{
            pathname: "/profile",
            state: { id: "1" }
          }}
        >
          <Tooltip title="My Profile" placement="bottom">
            <AccountCircle style={getIconStyle("profile")} />
          </Tooltip>
        </NavLink>
      </li>

      {/* <li>
        <NavLink to="/AboutUs">
          <Tooltip title="About" placement="bottom">
            <InfoIcon style={getIconStyle("AboutUs")} />
          </Tooltip>
        </NavLink>
      </li> */}

      <li>
        <NavLink
          to="/login"
          onClick={() => {
            auth.signOut().then(() => {
              window.location.reload();
            });
          }}
        >
          Sign Out
        </NavLink>
      </li>
    </ul>
  );
};

export default DebaterLinks;
