import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { formatDateTime, tsToDate } from "../Utilities/DateTimeUtils";
import { handleError, isMobile } from "../Utilities/OtherUtils";
import { confirmAlert } from "react-confirm-alert";
import {
  add_registration,
  cancel_registration,
} from "../../Backend/BackendFunctions";
import { toast } from "react-toastify";
import {
  checkInStarted,
  registrationEnded,
} from "../EventManagementComps/EventTimelineStatus";
import { ConfirmAlertBody } from "../Common/ConfirmAlert";
import { CancelAttendanceButton, CheckInButton } from "../Common/Buttons";
import { getFullName } from "../Utilities/DebaterUtilities";
import { check_in } from "../../Backend/registratinoBackendFunctions";
import { getCheckInIcon } from "./EventListItem";

const tableStyle = {
  margin: "0 auto",
  width: "100%",
};

const highlight = "#DEFFBD";

const tableHeadStyle = {
  margin: "0 auto",
  maxWidth: "100%",
  padding: 3,
  background: "#EFF7FF",
  color: "#000000",
  textAlign: "center",
};
const tablePaperStyle = {
  margin: "0 auto",
  maxWidth: "100%",
  background: "#C9E9FF",
};
const tableCellStyle = {
  margin: "0 auto",
  maxWidth: "100%",
  padding: 3,
};

/**
 * Table visualisation of a single user's assignment - room info, position, fellow debaters, etc.
 * Includes some features regarding event's life-cycle (check-in and cancellation)
 */
class MyAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedIn: this.props.checkedIn,
      cancelled: false,
    };
    this.getName = this.getName.bind(this);
    this.getCell = this.getCell.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    this.setState({ checkedIn: nextProps.checkedIn || false });
  }

  cancelAttendance = () => {
    const registrationData = this.props.event.registration;
    registrationData.id = this.props.event.id; // Just to be safe

    if (registrationEnded(this.props.event)) {
      registrationData.cancelled = true;
      registrationData.checkedIn = false;
      add_registration(registrationData)
        .then(() => {
          this.props.onCancel();
          toast.info("Cancelled your registration");
        })
        .catch((error) => handleError(error));
    } else {
      registrationData.cancelledOnTime = true;
      cancel_registration(registrationData)
        .then(() => {
          this.setState({ checkedIn: false });
          this.props.onCancelOnTime();
          toast.info("Cancelled your registration");
        })
        .catch((error) => handleError(error));
    }
  };

  onCheckIn = () => {
    const registrationData = this.props.event.registration;
    registrationData.id = this.props.event.id; // Just to be safe
    check_in(registrationData)
      .then(() => {
        this.setState({ checkedIn: true });
        this.props.onCheckIn();
        toast.info("You are checked in!");
      })
      .catch((error) => handleError(error));
  };

  popupCancelConfirm = () => {
    const message = "Are you sure you want to cancel your attendance?";
    confirmAlert({
      onClickOutside: () => {},
      customUI: ({ onClose }) => (
        <ConfirmAlertBody
          onClose={onClose}
          message={message}
          onSubmit={() => this.cancelAttendance()}
          variant={"warning"}
        />
      ),
    });
  };

  getName(user) {
    return user ? getFullName(user) : " - ";
  }

  getCell(user) {
    const highlightedStyle = Object.assign({}, tableCellStyle, {
      fontWeight: "bold",
      backgroundColor: highlight,
    });
    return (
      <TableCell
        style={
          user !== undefined && user.uid === this.props.user.uid
            ? highlightedStyle
            : tableCellStyle
        }
      >
        {this.getName(user)}
      </TableCell>
    );
  }

  render() {
    let assignment = this.props.event.assignment;
    const panel = (
      <span>
        {Object.keys(assignment["Panel"]).map((key, index) => {
          const user = assignment["Panel"][key];
          return (
            <span
              key={key}
              style={
                user !== undefined && user.uid === this.props.user.uid
                  ? {
                      fontWeight: "bold",
                      backgroundColor: highlight,
                      marginRight: 2,
                    }
                  : { marginRight: 2 }
              }
            >
              {getFullName(user) +
                (index < Object.keys(assignment["Panel"]).length - 1
                  ? ","
                  : "")}
            </span>
          );
        })}
      </span>
    );

    return (
      <div className="app-container-2">
        <div align={"left"}>
          <Paper style={{ padding: isMobile() ? 10 : 20 }}>
            <table style={{ padding: 0, margin: 0, borderSpacing: 0 }}>
              <tbody>
                <tr>
                  {this.state.checkedIn && (
                    <td style={{ padding: 0, width: "5%" }}>
                      {getCheckInIcon(32)}
                    </td>
                  )}
                  <td style={{ width: "80%" }}>
                    <h2>
                      {this.state.checkedIn ? "Checked In" : "My Assignment"}
                    </h2>
                  </td>
                </tr>
              </tbody>
            </table>

            <h4 style={{ marginTop: "1%", marginBottom: 0 }}>
              Start Time:
              <span style={{ fontWeight: "normal" }}>
                {` ${formatDateTime(tsToDate(this.props.event.time))}`}
              </span>
            </h4>
            <h4 style={{ marginTop: "0.5%" }}>
              Event:
              <span style={{ fontWeight: "normal" }}>
                {` ${this.props.event.event_type}`}
              </span>
              <br />
              Gathering location:{" "}
              <span style={{ fontWeight: "normal", lineHeight: 1 }}>
                {console.log(this.props.event)}
                {this.props.event.location.includes("http") ? (
                  <a
                    href={this.props.event.location}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.event.location}
                  </a>
                ) : (
                  this.props.event.location
                )}
              </span>
            </h4>

            <h4 style={{ marginTop: "0.5%", marginBottom: "5px" }}>
              Room:
              <span style={{ fontWeight: "normal" }}>
                {` ${assignment["Name"]}`}
              </span>
            </h4>

            <h4 style={{ marginTop: "0" }}>
              Language:
              <span style={{ fontWeight: "normal" }}>
                {` ${assignment["Language"]}`}
              </span>
            </h4>

            <h4 style={{ marginTop: "0.5%", marginBottom: "5px" }}>
              Chair:{" "}
              <span
                style={
                  typeof assignment["Chair"][0] !== "undefined"
                    ? assignment["Chair"][0].uid === this.props.user.uid
                      ? { fontWeight: "bold", backgroundColor: highlight }
                      : { fontWeight: "normal" }
                    : { fontWeight: "normal" }
                }
              >
                {this.getName(assignment["Chair"][0])}
              </span>
            </h4>
            <h4 style={{ marginTop: "0" }}>
              Panel: <span style={{ fontWeight: "normal" }}>{panel}</span>
            </h4>

            <Paper style={tablePaperStyle} rounded="true">
              <Table style={tableStyle}>
                <TableHead style={tableHeadStyle}>
                  <TableRow>
                    <TableCell style={tableHeadStyle}>
                      Opening Government
                    </TableCell>
                    <TableCell style={tableHeadStyle}>
                      Opening Opposition
                    </TableCell>
                    <TableCell style={tableHeadStyle}>
                      Closing Government
                    </TableCell>
                    <TableCell style={tableHeadStyle}>
                      Closing Opposition
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    {this.getCell(assignment["Opening Government"][0])}
                    {this.getCell(assignment["Opening Opposition"][0])}
                    {this.getCell(assignment["Closing Government"][0])}
                    {this.getCell(assignment["Closing Opposition"][0])}
                  </TableRow>

                  <TableRow>
                    {this.getCell(assignment["Opening Government"][1])}
                    {this.getCell(assignment["Opening Opposition"][1])}
                    {this.getCell(assignment["Closing Government"][1])}
                    {this.getCell(assignment["Closing Opposition"][1])}
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>

            <div align={"center"}>
              <br />
              <CancelAttendanceButton
                onClick={this.popupCancelConfirm}
                label={"Cancel"}
              />

              {checkInStarted(this.props.event) && (
                <CheckInButton
                  disabled={this.state.checkedIn}
                  onClick={this.onCheckIn}
                />
              )}
              <br />
              <br />
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default MyAssignment;
