import React from "react";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// main toolbar Component
const SignOutLinks = props => (
  <List component="nav-list" aria-label="nav-list">
    <NavLink to="/login">
      <ListItem
        button
        onClick={() => {
          if (props.show) props.close();
        }}
      >
        <ListItemText primary="Login" />
      </ListItem>
    </NavLink>
  </List>
);

export default SignOutLinks;
