/*
* A function validating that an assignemnt in a single room is valid.
* This function is called every time an assignemnt is changed, and return relevant 
* warnings and error messages to display.
*/

export default function validateRoom(room){
    let errorStatus = "";
    let errorMessage = [];
    if(room["Chair"].length >= 2){
        errorStatus = 'error';
        errorMessage.push('More than one chair assigned')
    }
    if(room["Opening Government"].length > 2){
        errorStatus = 'error';
        errorMessage.push('Too many assigned in Opening Government')
    }
    if(room["Opening Opposition"].length > 2){
        errorStatus = 'error';
        errorMessage.push('Too many assigned in Opening Opposition')
    }
    if(room["Closing Government"].length > 2){
        errorStatus = 'error';
        errorMessage.push('Too many assigned in Closing Government')
    }
    if(room["Closing Opposition"].length > 2){
        errorStatus = 'error';
        errorMessage.push('Too many assigned in Closing Opposition')
    }
    if(errorStatus !== 'error'){
        if(room["Chair"].length === 0){
            errorStatus = 'warning';
            errorMessage.push('No chair assigned')
        }
        if(room["Opening Government"].length < 2){
            errorStatus = 'warning';
            errorMessage.push('Opening Government not fully assigned')
        }
        if(room["Opening Opposition"].length < 2){
            errorStatus = 'warning';
            errorMessage.push('Opening Opposition not fully assigned')
        }
        if(room["Closing Government"].length < 2){
            errorStatus = 'warning';
            errorMessage.push('Closing Government not fully assigned')
        }
        if(room["Closing Opposition"].length < 2){
            errorStatus = 'warning';
            errorMessage.push('Closing Opposition not fully assigned')
        }
        
    }
    room.errorMessage = errorMessage;
    room.errorStatus = errorStatus;
    return room;
}