import React from "react";
import {CloseButton} from "../../Common/Buttons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {ProfilePictureEdit} from "./ProfilePictureEdit";

/**
 * Simple dialog to wrap profile picture editor component
 */
export class ProfilePictureEditDialog extends React.Component {
    render(){
        return (

            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <DialogTitle>
                    Edit Profile Picture
                    <CloseButton onClick={this.props.onClose}/>
                </DialogTitle>
                <DialogContent style={{ width: 400 }}>
                    <ProfilePictureEdit
                        onClose={() => this.props.onClose()}
                        onChange={picture => this.props.onChange(picture)}
                        onError={() => this.props.onError}
                        userId={this.props.userId}
                        picture={this.props.picture}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}
