import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  getDefaultRank,
  getFullName,
  getFullNameHeb,
  getStartYearOptions,
  isApprovedUser,
  isSystemAdmin
} from "../../Utilities/DebaterUtilities";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from "@material-ui/icons/Edit";
import { CancelButton, DeleteButton, SubmitButton } from "../../Common/Buttons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {handleError, isMobile} from "../../Utilities/OtherUtils";
import {validEmail,validFullNameEng,validFullNameHeb,validPhoneNumber,deepClone,titleCase} from "../../Utilities/StringUtils"
import { toast } from "react-toastify";
import { update_user_details } from "../../../Backend/BackendFunctions";
import Fab from "@material-ui/core/Fab";
import Checkbox from "@material-ui/core/Checkbox";

const textFieldStyle = {
    width: isMobile()?"95%":"45%",
    marginBottom: 5,
    marginLeft: "2.5%",
    marginRight: "2.5%"
};

const categoryHeaderStyle = (edit_mode) => {
    return {
        marginBottom: 0,
        marginLeft: "2.5%",
        color: edit_mode ? "#000000" : "#999999"
    };
};

/**
 * Actual form for filling/editing/viewing personal information
 */
export class UserDetailsForm extends Component {
  menuOptionsJson = require("./personal_details_fields.json");
  debater;
  systemUser;
  relevantDetails = [
    "full_name_eng",
    "full_name_heb",
    "email",
    "phone_number",
    "gender",
    "club",
    "start_year",
    "student",
    "official_judgement_level",
    "rank",
    "extra_info",
    "email_subscribed",
    "permissions"
  ];
  startYearOptions = getStartYearOptions();

  constructor(props) {
    super(props);
    this.debater = props.debater;
    this.systemUser = props.systemUser;
    this.state = {
      details: {},
      loading: false,
      edit_mode: props.editMode,
      showPermissionsWarning: false,
      showExplanation: false,
      wereChanges: false,
      email_subscribed: this.debater.email_subscribed || false
    };

    this.setDefaults = this.setDefaults.bind(this);
    this.refreshDetails = this.refreshDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeInner = this.handleChangeInner.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.wereChanges = this.wereChanges.bind(this);
    this.checkChanges = this.checkChanges.bind(this);
    this.handlePermissionsChange = this.handlePermissionsChange.bind(this);
    this.toggleEmailSubscription = this.toggleEmailSubscription.bind(this);
  }

  componentDidMount() {
    this.refreshDetails();
  }

  setDefaults(details) {
    details.full_name_eng = getFullName(this.debater);
    details.full_name_heb = getFullNameHeb(this.debater);
    if (!details.rank) details.rank = getDefaultRank(this.debater);
    if (!details.official_judgement_level)
      details.official_judgement_level = "W1";
    if (details.student === undefined) details.student = "Yes";
    else details.student = this.debater.student ? "Yes" : "No";
    if (!details.club) details.club = this.menuOptionsJson["club"]["values"][0];
    if (!details.start_year) details.start_year = this.startYearOptions[0];
    if (!details.permissions) details.permissions = "Registration";
    if (!details.extra_info) details.extra_info = null;
    if (!Object.keys(this.debater).includes("email_subscribed"))
      details.email_subscribed = true;
    this.setState({ email_subscribed: details.email_subscribed });
  }

  refreshDetails() {
    // Setting up two "details" dictionaries to check for changes
    const details = {};
    const originalDetails = {};

    // eslint-disable-next-line
    this.relevantDetails.map(attribute => {
      details[attribute] = this.debater[attribute];
      originalDetails[attribute] = this.debater[attribute];
    });

    // Setting default values for initial login
    this.setDefaults(originalDetails);
    this.setDefaults(details);
    this.setState(
      { details: details, originalDetails: originalDetails },
      () => {
        // For the case where a new field was added to this form but the user doesn't have it in firebase
        const wereChanges = this.wereChanges();
        if (wereChanges) this.setState({ wereChanges: true });
      }
    );
  }

  handleSubmit() {
    this.setState({ loading: true });
    let hasErrors = false;
    let missingFields = "XX";
    // eslint-disable-next-line
    Object.keys(this.state.details).map(key => {
      if (
        key !== "extra_info" &&
        (this.state.details[key] === null ||
          this.state.details[key] === undefined)
      ) {
        key = key.replace("_heb", "_hebrew").replace("_eng", "_english");
        missingFields = missingFields + ", " + titleCase(key);
        hasErrors = true;
      }
    });
    if (hasErrors) {
      toast.error(
        "Missing Required Fields: " + missingFields.replace("XX, ", "")
      );
      this.setState({ loading: false });
      return;
    }

    let errorMessage = "Invalid ";
    if (!validEmail(this.state.details.email)) {
      hasErrors = true;
      errorMessage += "Email, ";
    }
    if (!validPhoneNumber(this.state.details.phone_number)) {
      hasErrors = true;
      errorMessage += "Phone Number, ";
    }
    if (!validFullNameEng(this.state.details.full_name_eng)) {
      hasErrors = true;
      errorMessage += "English Full Name, ";
    }
    if (!validFullNameHeb(this.state.details.full_name_heb)) {
      hasErrors = true;
      errorMessage += "Hebrew Full Name, ";
    }

    if (hasErrors) {
      errorMessage = (errorMessage + "XX").replace(", XX", "");
      toast.error(errorMessage);
      this.setState({ loading: false });
      return;
    } else {
      //Add submission time to details
      const details = this.state.details;
      details.picture = this.debater.picture;
      details.signup_time = this.debater.signup_time
        ? this.debater.signup_time
        : new Date();

      // Switch boolean fields from string back to boolean
      details.student = details.student === "Yes";

      // Split full names - 18/06 we decided to remove first and last names and save full names only
      /*details.first_name_eng = details.full_name_eng.split(' ')[0];
            details.last_name_eng = details.full_name_eng.split(' ').slice(1).join(' ');
            details.first_name_heb = details.full_name_heb.split(' ')[0];
            details.last_name_heb = details.full_name_heb.split(' ').slice(1).join(' ');*/

      //Change permissions to pending
      if (!isApprovedUser(this.debater)) details.permissions = "Pending";

      //Send user details to firestore
      update_user_details({ ...this.debater, ...details })
        .then(() => {
          // If user came from local storage, update that too
          if (localStorage.debater && isSystemAdmin(this.systemUser)) {
            localStorage.setItem("debater", JSON.stringify(details));
          }

          this.setState(
            { showPermissionsWarning: false, edit_mode: false, loading: false },
            () => window.location.reload()
          );
        })
        .catch(error => handleError(error));
    }
  }

  handlePermissionsChange(event) {
    let newState = deepClone(this.state);
    newState.details["permissions"] = event.target.value;
    newState.showPermissionsWarning =
      event.target.value !== this.debater.permissions;
    this.setState(newState, () => this.checkChanges());
  }

  handleChange(event, index) {
    this.handleChangeInner(event.target.value, index);
  }

  handleChangeInner(value, index) {
    let newDetails = this.state.details;
    const field = Object.keys(this.state.details)[index];
    newDetails[field] = value;
    this.setState({ details: newDetails }, () => this.checkChanges());
  }

  wereChanges() {
    for (const field in this.state.details) {
      if (
        !Object.keys(this.debater).includes(field) ||
        this.state.details[field] !== this.state.originalDetails[field]
      ) {
        return true;
      }
    }
    return false;
  }

  checkChanges() {
    const wereChanges = this.wereChanges();
    this.setState({ wereChanges: wereChanges }, () =>
      this.props.onChanges(wereChanges)
    );
  }

  setEditMode(bool) {
    this.setState({ edit_mode: bool });
    this.props.onToggleEditMode(bool);
  }

  toggleEmailSubscription() {
    const current = this.state.email_subscribed;
    this.setState({ email_subscribed: !current }, () => {
      this.handleChangeInner(!current, 11);
    });
  }

  render() {
    return (
      <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
        {// Allow only admins to edit other users
        !this.state.edit_mode &&
          (this.debater.uid === this.systemUser.uid ||
            isSystemAdmin(this.systemUser)) && (
            <div style={{padding: 0, margin: 0}}>
              <Fab
                onClick={() => this.setEditMode(true)}
                size="small"
                style={{marginTop: 0, marginBottom: 10}}>
                <EditIcon>EditIcon</EditIcon>
              </Fab>
            </div>
          )}

        <div align={"left"}>
        <TextValidator
          style={textFieldStyle}
          disabled={!this.state.edit_mode || this.state.loading}
          onChange={e => this.handleChange(e, 0)}
          value={getFullName(this.state.details) || ""}
          validators={[
            "matchRegexp:^[A-z]([-']?[A-z]+)*( [A-z]([-']?[A-z]+)*)+$"
          ]}
          errorMessages={["Invalid full name"]}
          label="* Full Name (English)"
          margin="normal"
          variant="outlined"
          inputProps={{
            style: { textAlign: "left" }
          }}
        />

        <TextValidator
          style={textFieldStyle}
          disabled={!this.state.edit_mode || this.state.loading}
          onChange={e => this.handleChange(e, 1)}
          value={getFullNameHeb(this.state.details) || ""}
          validators={[
            "matchRegexp:^[א-ת]([-']?[א-ת]+)*( [א-ת]([-']?[א-ת]+)*)+$"
          ]}
          errorMessages={["שם מלא לא תקין"]}
          label="* שם מלא"
          margin="normal"
          variant="outlined"
          inputProps={{
            style: { textAlign: "right" }
          }}
        />

        <TextValidator
          style={textFieldStyle}
          onChange={e => this.handleChange(e, 2)}
          value={this.state.details.email || ""}
          disabled={!this.state.edit_mode || this.state.loading}
          validators={["isEmail"]}
          errorMessages={["Invalid Email Address"]}
          label="* Email"
          margin="normal"
          variant="outlined"
        />

        <TextValidator
          style={textFieldStyle}
          onChange={e => this.handleChange(e, 3)}
          value={this.state.details.phone_number || ""}
          disabled={!this.state.edit_mode || this.state.loading}
          validators={[
            "matchRegexp:05[0-9]{8}",
            "maxNumber:599999999",
            "isNumber"
          ]}
          errorMessages={["Invalid Phone Number"]}
          label="* Phone Number"
          margin="normal"
          variant="outlined"
        />
        <br />
        <span align="left"
              style={{
                  float: 'left',
                  marginTop: 10,
                  marginRight: 20,
                  marginBottom: 0,
                  marginLeft: "2.5%",
                  color: this.state.edit_mode ? "#777777" : "#AAAAAA"}}>
        * Gender:
            <RadioGroup
              row
              style={{
                justifyContent: "center",
                display: "inline-block"
              }}
              id="gender"
              name="gender"
              value={this.state.details.gender || ""}
              onChange={e => this.handleChange(e, 4)}>
              {this.menuOptionsJson["gender"]["values"].map(val => (
                <FormControlLabel
                  key={val}
                  id={"gender"}
                  value={val}
                  label={val}
                  style={{marginRight: 5, marginLeft: 5}}
                  disabled={!this.state.edit_mode || this.state.loading}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
        </span><br /><br />

        <h3 align="left"
            style={categoryHeaderStyle(this.state.edit_mode && !this.state.loading)}>Debate Club</h3>
        <TextField
            style={textFieldStyle}
            id="club"
            select
            label="Club Name"
            disabled={!this.state.edit_mode || this.state.loading}
            value={this.state.details.club || ""}
            onChange={e => this.handleChange(e, 5)}
            margin="normal"
            variant="outlined">
          {this.menuOptionsJson["club"]["values"].map(val => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}{" "}
        </TextField>

        <TextField
          style={textFieldStyle}
          id="start_year"
          select
          label="Start Year"
          disabled={!this.state.edit_mode || this.state.loading}
          value={this.state.details.start_year || ""}
          onChange={e => this.handleChange(e, 6)}
          margin="normal"
          variant="outlined"
        >
          {this.startYearOptions.map(val => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </TextField>

        <span align="left"
            style={{
                float: 'left',
                marginTop: 10,
                marginRight: 20,
                marginBottom: 0,
                marginLeft: "2.5%",
                color: this.state.edit_mode ? "#777777" : "#AAAAAA"}}>
            * Are you officially a TAU student?
            <RadioGroup
                style={{display: 'inline'}}
                row
                id="student"
                name="student"
                disabled={!this.state.edit_mode || this.state.loading}
                value={this.state.details.student || ""}
                onChange={e => this.handleChange(e, 7)}>
                <FormControlLabel
                    value={"Yes"}
                    label={"Yes"}
                    style={{marginRight: 5, marginLeft: 5}}
                    disabled={!this.state.edit_mode}
                    control={<Radio />}/>
                <FormControlLabel
                    value={"No"}
                    label={"No"}
                    style={{marginRight: 5, marginLeft: 5}}
                    disabled={!this.state.edit_mode}
                    control={<Radio />}/>
            </RadioGroup>
        </span>
        <br/><br/><br/>
        {/* The whole "Debating Level" section is only visible after user is approved*/
        isApprovedUser(this.debater) && (
          <div style={{ marginBottom: 0, display: "inline" }}>
            <h3 align="left" style={categoryHeaderStyle(this.state.edit_mode && !this.state.loading)}>
              Debating Level
              <span
                style={{ display: "inline", marginBottom: 0 }}
                onClick={() => {
                  const current = this.state.showExplanation;
                  this.setState({ showExplanation: !current });
                }}>
                <HelpIcon
                  style={{ height: 15, color: "#999999", marginBottom: 0 }}
                />
              </span>
            </h3>
            {this.state.showExplanation && (
              <p style={{color: "#777777", marginBottom: 0, textAlign: 'left', marginLeft: 30}}>
                This information can only be updated by debate club management
              </p>
            )}
            <TextField
              style={textFieldStyle}
              id="judgment"
              select
              label="Official Judgement Level"
              disabled={
                !this.state.edit_mode ||
                !isSystemAdmin(this.systemUser) ||
                this.state.loading
              }
              value={this.state.details.official_judgement_level || ""}
              onChange={e => this.handleChange(e, 8)}
              margin="normal"
              variant="outlined"
            >
              {this.menuOptionsJson["official_judgement_level"]["values"].map(
                val => (
                  <MenuItem key={val} value={val || ""}>
                    {val}
                  </MenuItem>
                )
              )}
            </TextField>

            <TextField
              style={textFieldStyle}
              id="rank"
              select
              label="Rank"
              disabled={
                !this.state.edit_mode ||
                !isSystemAdmin(this.systemUser) ||
                this.state.loading
              }
              value={this.state.details.rank || ""}
              onChange={e => this.handleChange(e, 9)}
              margin="normal"
              variant="outlined"
            >
              {this.menuOptionsJson["rank"]["values"].map(val => (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
        <br /><br />
        <TextField
          style={{
              width: "95%",
              marginLeft: "2.5%",
              marginRight: "2.5%"
          }}
          multiline
          rowsMax="4"
          label={"Tell us more about yourself..."}
          disabled={!this.state.edit_mode}
          value={this.state.details.extra_info || ""}
          onChange={e => this.handleChange(e, 10)}
          margin="normal"
          variant="outlined"/>

        <br /><br />
        <Checkbox
          style={{marginLeft: "2.5%", padding: 0}}
          checked={this.state.email_subscribed}
          disabled={!this.state.edit_mode || this.state.loading}
          onChange={this.toggleEmailSubscription}
          label={"email_subscribed"}
        />
        <h4 align={"left"}
            style={{
                display: "inline",
                marginRight: 20,
                marginBottom: 0,
                color: this.state.edit_mode ? "#777777" : "#AAAAAA"
          }}>
          Subscribe to Emails
        </h4>
        <br />
        <br />

        {isSystemAdmin(this.systemUser) &&
          isApprovedUser(this.debater) &&
          this.debater.uid !== this.systemUser.uid && (
            <div>
              <h3 style={categoryHeaderStyle(this.state.edit_mode && !this.state.loading)}>Permissions</h3>
              <TextField
                style={textFieldStyle}
                id="permissions"
                select
                label="Permissions"
                disabled={!this.state.edit_mode || this.state.loading}
                value={this.state.details.permissions || ""}
                onChange={e => this.handlePermissionsChange(e)}
                margin="normal"
                variant="outlined">
                {this.menuOptionsJson["permissions"]["values"].map(val => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <label
                hidden={!this.state.showPermissionsWarning}
                style={{color: "#CC0000"}}>
                WARNING: Are you sure you want to change this user's
                permissions?
                <br />
              </label>
              <br />
            </div>
          )}

        </div>

        <div align={"center"}>
        {this.state.edit_mode && (
          <CancelButton
            disabled={this.state.loading}
            label={"Cancel"}
            onClick={() =>
              this.setState({ edit_mode: false }, () => {
                this.refreshDetails();
                if (this.props.onClose) this.props.onClose();
              })
            }
          />
        )}

        {this.state.edit_mode && (
          <SubmitButton
            disabled={this.state.loading || !this.state.wereChanges}
            label={
              this.state.details.permissions === "Registration"
                ? "Submit"
                : "Save"
            }
            onClick={this.handleSubmit}
          />
        )}

        {this.state.edit_mode && this.debater.uid !== this.systemUser.uid && (
          <DeleteButton
            disabled={this.state.loading}
            label={"Delete User"}
            onClick={this.props.onDelete}
          />
        )}

        {this.state.loading && (
            <p><CircularProgress /></p>
        )}
        </div>
      </ValidatorForm>
    );
  }
}
