import React, { Component } from "react";
import "./App.css";
import { Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Toolbar from "./components/Toolbar/Toolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import { routingOptions } from "./routedirector/routedirector";
import { authenticateUser } from "./Backend/BackendFunctions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import {getCurrentLocation, isMobile} from "./components/Utilities/OtherUtils";
import { toast, ToastContainer } from "react-toastify";
import ProjectionPage from "./components/assignmentComps/projection";
import Favicon from "react-favicon";
import CircularProgress from "@material-ui/core/CircularProgress";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      refreshToolbar: false,
      currentLocation: "/"
    };

    this.authListener = this.authListener.bind(this);
  }

  componentDidMount = () => {
    this.authListener();
  };

  // function to check if user is authenticated
  authListener() {
    let userPromise = authenticateUser();
    userPromise
      .then(user => {
        this.setState({ user: user });
      })
      .catch(error => {
        console.log(error);
        alert("Connection problem");
      });
  }

  // function for opening and closing the side drawer
  // is here so that sideDrawerOpen in state could be changed
  // probably can be moved to DrawerToggelButton using Store or different props
  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  closeSideDrawer = () => {
    this.setState({
      sideDrawerOpen: false
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  onUpdate = () => {
    const currentLocation = getCurrentLocation();
    this.setState({
      loading: false,
      refreshToolbar: !this.state.refreshToolbar,
      currentLocation: currentLocation
    });
  };

  render() {
    return (
      <BrowserRouter>
        <Favicon url="https://firebasestorage.googleapis.com/v0/b/debateproject.appspot.com/o/taufavicon.png?alt=media&token=ee171baa-c2e2-419d-9680-4a2ff30d2643"/>
        <div style={{ height: "100%" }}>
          {this.state.loading && <h3 align={"center"}>{isMobile() && <p><br/><br/></p>}<CircularProgress/></h3>}
          <Toolbar
            currentLocation={this.state.currentLocation}
            drawerClickHandler={this.drawerToggleClickHandler}
            permissions={this.state.user.permissions}
            refresh={this.state.refreshToolbar}
          />
          <SideDrawer
            close={this.closeSideDrawer}
            show={this.state.sideDrawerOpen}
            permissions={this.state.user.permissions}
          />
          {/* {Backdrop} - why is this? */}
          <main style={{ marginTop: "55px" }}>
            <ToastContainer
              position={toast.POSITION.TOP_CENTER}
              hideProgressBar={true}
              autoClose={2000}
            />
            {routingOptions(this.state.user, this.onUpdate)}
            <Route
              path="/privacy_policy"
              render={() => <PrivacyPolicy onLoad={this.onUpdate} />}
            />
            <Route
              path="/TermsAndConditions"
              render={() => <TermsAndConditions onLoad={this.onUpdate}/>}
            />
            <Route
              path="/projection"
              render={() => <ProjectionPage onLoad={this.onUpdate}/>}
            />
          </main>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
