import React, { Component } from "react";
import {
  formatDateTime,
  tsToDate
} from "../components/Utilities/DateTimeUtils";
import {
  handleError,
  isMobile,
  pageLoaded
} from "../components/Utilities/OtherUtils";
import { deepClone } from "../components/Utilities/StringUtils";
import {
  delete_user,
  get_all_waiting_debaters,
  update_user_details,
  sendApprovedEmail
} from "../Backend/BackendFunctions";
import { toast } from "react-toastify";
import MaterialTable from "material-table";
import { getFullName } from "../components/Utilities/DebaterUtilities";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { DebaterHeader } from "../components/UserComponents/DebaterHeader";
import { UserDetailsDialog } from "../components/UserComponents/UserDetailsPageComponents/UserDetailsDialog";
import { ConfirmAlertBody } from "../components/Common/ConfirmAlert";
import { EmailComposerDialog } from "../components/Common/EmailComposerDialog";

const baseStyle = { textAlign: "left", padding: 0, margin: 0 };

/**
 * Table of new users waiting for approval
 * Includes personal information (some editable), some toolbar widgets, and action items
 * Actions include approving, blocking (and deleting) and contacting by email for further inquiry
 */
export class ApproveNewUsers extends Component {
  menuOptionsJson = require("../components/UserComponents/UserDetailsPageComponents/personal_details_fields.json");

  constructor(props) {
    super(props);
    this.props.history.push(this.props.match.url);
    this.state = {
      debaters: {},
      tableData: [],
      selectedUser: null,
      loading: true,
      emailDebater: null,
      toUserProfile: false
    };

    this.refresh = this.refresh.bind(this);
    this.processRow = this.processRow.bind(this);
    this.closeEmailDialog = this.closeEmailDialog.bind(this);
    this.selectDebater = this.selectDebater.bind(this);
    this.contactDebater = this.contactDebater.bind(this);
    this.approveUserConfirmation = this.approveUserConfirmation.bind(this);
    this.deleteUserConfirmation = this.deleteUserConfirmation.bind(this);
    this.approveUser = this.approveUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    if (pageLoaded("new_users")) {
      this.props.onLoad();
      this.refresh();
    }
  }

  refresh() {
    const data = [];

    get_all_waiting_debaters(1000, null)
      .then(debaters => {
        Object.keys(debaters).map(id => {
          const debater = debaters[id];
          const row = this.processRow(debater, id);
          data.push(row);
          return "";
        });
        this.setState({ debaters: debaters, tableData: data, loading: false });
      })
      .catch(error => console.log(error));
  }

  processRow(debater, id) {
    const row = deepClone(debater);
    row.id = id;
    row.full_name_eng = getFullName(debater);
    return row;
  }

  approveUserConfirmation(id) {
    this.setState({ loading: true });
    const debater = this.state.debaters[id];
    const message = (
      <span>
        {"Are you sure you want to approve " + getFullName(debater) + "?"}
      </span>
    );
    confirmAlert({
      onClickOutside: () => this.setState({ loading: false }),
      customUI: ({ onClose }) => {
        const closeMe = () => {
          this.setState({ loading: false });
          onClose();
        };
        return (
          <ConfirmAlertBody
            onClose={closeMe}
            message={message}
            onSubmit={() => this.approveUser(debater)}
          />
        );
      }
    });
  }

  approveUser(debater) {
    this.setState({ loading: true });

    // If the permissions are still on "Pending" the new default is "Student"
    if (debater.permissions === "Pending") debater.permissions = "Student";
    update_user_details(debater)
      .then(() => {
        //toast.info("Approved " + getFullName(debater));
        this.props.onUpdate();
        this.refresh();
        sendApprovedEmail(debater).then(() => {
          toast.info("Approval email was sent to " + getFullName(debater));
        });
      })
      .catch(error => handleError(error));
  }

  deleteUserConfirmation(id) {
    this.setState({ loading: true });
    const debater = this.state.debaters[id];
    const message = (
      <span>
        {"Are you sure you want to "}
        <b>permanently</b>
        {" delete " + getFullName(debater) + "?"}
      </span>
    );
    confirmAlert({
      onClickOutside: () => this.setState({ loading: false }),
      customUI: ({ onClose }) => {
        const closeMe = () => {
          this.setState({ loading: false });
          onClose();
        };
        return (
          <ConfirmAlertBody
            onClose={closeMe}
            message={message}
            onSubmit={() => this.deleteUser(id)}
            variant={"warning"}
          />
        );
      }
    });
  }

  deleteUser(id) {
    this.setState({ loading: true });
    delete_user(id)
      .then(() => {
        toast.info("Deleted User Successfully");
        this.props.onUpdate();
        this.refresh();
      })
      .catch(e => handleError(e));
  }

  contactDebater(debaterId) {
    const emailDebaterDict = {};
    emailDebaterDict[debaterId] = this.state.debaters[debaterId];
    this.setState({ emailDebater: emailDebaterDict });
  }

  closeEmailDialog() {
    this.setState({ emailDebater: null });
  }

  selectDebater(debater) {
    this.setState({ selectedUser: debater });
  }

  tableColumns = [
    {
      title: "Debater",
      removable: false,
      render: rowData => {
        const debater = this.state.debaters[rowData.id];
        return (
          <DebaterHeader
            debater={debater}
            onClick={() => {
              this.selectDebater(debater);
            }}
            tooltip={"User Details"}
          />
        );
      }
    },
    {
      title: "Signup Time",
      customSort: (a, b) =>
        tsToDate(a.signup_time).valueOf() > tsToDate(b.signup_time).valueOf()
          ? 1
          : -1,
      render: rowData => {
        if (!rowData.signup_time) return "";
        return formatDateTime(tsToDate(rowData.signup_time));
      }
    },
    { title: "Extra Info", field: "extra_info" },
    { title: "Club", field: "club" },
    { title: "Official Student", field: "student", type: "boolean" },
    { title: "Start Year", field: "start_year" },
    {
      title: "Rank",
      render: rowData => {
        return (
          <span>
            <TextField
              select
              value={rowData.rank ? rowData.rank : ""}
              onChange={e => {
                rowData.rank = e.target.value;
                const debaters = this.state.debaters;
                debaters[rowData.id].rank = e.target.value;
                this.setState({ debaters: debaters });
              }}
            >
              {this.menuOptionsJson["rank"]["values"].map(val => (
                <MenuItem key={val} value={val}>
                  <span style={{ fontSize: 14 }}>{val}</span>
                </MenuItem>
              ))}
            </TextField>
          </span>
        );
      }
    },
    {
      title: "Official Judgement Level",
      render: rowData => {
        return (
          <span>
            <TextField
              select
              value={rowData.official_judgement_level}
              onChange={e => {
                rowData.official_judgement_level = e.target.value;
                const debaters = this.state.debaters;
                debaters[rowData.id].official_judgement_level = e.target.value;
                this.setState({ debaters: debaters });
              }}
            >
              {this.menuOptionsJson["official_judgement_level"]["values"].map(
                val => (
                  <MenuItem key={val} value={val}>
                    <span style={{ fontSize: 14 }}>{val}</span>
                  </MenuItem>
                )
              )}
            </TextField>
          </span>
        );
      }
    },
    { title: "Gender", field: "gender" },
    { title: "Email", field: "email" },
    { title: "Phone Number", field: "phone_number" },
    {
      title: "Full Name (Search)",
      field: "full_name_eng",
      hidden: true,
      removable: false,
      searchable: true
    }
  ];

  render() {
    for (const column of this.tableColumns) {
      column["cellStyle"] = baseStyle;
      column["headerStyle"] = baseStyle;
    }
    return (
      <div align="center">
        {this.state.selectedUser && (
          <UserDetailsDialog
            open={true}
            debater={this.state.selectedUser}
            user={this.props.user}
            onClose={() => this.setState({ selectedUser: null })}
          />
        )}

        {this.state.emailDebater && (
          <EmailComposerDialog
            title={`Email ${getFullName(
              this.state.emailDebater[Object.keys(this.state.emailDebater)[0]]
            )}`}
            debaters={this.state.emailDebater}
            onClose={() => this.closeEmailDialog()}
          />
        )}

        <div align="center" style={{ maxWidth: "90%" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <MaterialTable
            isLoading={this.state.loading}
            columns={this.tableColumns}
            data={this.state.tableData}
            title={
              (this.state.tableData.length > 0
                ? this.state.tableData.length
                : "") + " New Users to Approve"
            }
            options={{
              sorting: true,
              search: !isMobile(),
              columnsButton: !isMobile(),
              emptyRowsWhenPaging: false,
              pageSize: 20
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "No new users are waiting for approval"
              },
              header: { actions: "" }
            }}
            actions={[
              {
                icon: "done",
                color: "#00AA00",
                tooltip: "Approve User",
                onClick: (event, rowData) => {
                  this.approveUserConfirmation(rowData.id);
                }
              },
              {
                icon: "email",
                color: "#00AA00",
                tooltip: "Contact User",
                onClick: (event, rowData) => {
                  this.contactDebater(rowData.id);
                }
              },
              {
                icon: "clear",
                color: "#00AA00",
                tooltip: "Block User",
                onClick: (event, rowData) => {
                  this.deleteUserConfirmation(rowData.id);
                }
              }
            ]}
          />
        </div>
      </div>
    );
  }
}
