import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { handleError } from "../Utilities/OtherUtils";
import { sendEmailsTo } from "../../Backend/emailFunctions";
import { CancelButton, SubmitButton } from "./Buttons";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../Announcements/EditComponent";

/**
 * Custom component for emailing a given list of debaters
 */
export class EmailComposer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      body: "",
      loading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleSubmit() {
    this.setState({ loading: true });
    sendEmailsTo(this.props.debaters, this.state.subject, this.state.body)
      .then(res => {
        this.props.onSubmit();
        this.setState({ loading: false });
      })
      .catch(e => handleError(e));
  }

  validate() {
    return (
      this.state.subject &&
      this.state.body &&
      this.props.debaters &&
      Object.keys(this.props.debaters).length > 0 &&
      this.state.subject.length > 0 &&
      this.state.body.length > 0
    );
  }
  render() {
    const width = "100%";
    let debatersString = "";
    for (const id in this.props.debaters) {
      debatersString += this.props.debaters[id]["email"] + ", ";
      // debatersString += getFullName(this.props.debaters[id])+", ";
    }
    debatersString = debatersString.substr(0, debatersString.length - 2);
    return (
      <div>
        <br />
        <span style={{ color: "#777777" }}>
          Recipients ({Object.keys(this.props.debaters).length})
        </span>
        <br />
        <TextField
          style={{ width: width, marginTop: 10 }}
          multiline={true}
          id="debaters"
          margin="normal"
          variant="outlined"
          disabled={true}
          value={debatersString}
        />

        <br />
        <br />
        <span style={{ color: "#777777" }}>Subject</span>
        <br />
        <TextField
          style={{ width: width, marginTop: 10 }}
          id="Subject"
          value={this.state.subject}
          onChange={e => this.setState({ subject: e.target.value })}
          margin="normal"
          variant="outlined"
          disabled={this.state.loading}
        />

        <br />
        <br />
        <span style={{ color: "#777777" }}>Message Body</span>
        <br />
        <ReactQuill
          style={{ marginTop: 10, borderRadius: 5 }}
          value={this.state.body}
          onChange={value => this.setState({ body: value })}
          modules={quillModules}
          formats={quillFormats}
          placeholder=""
          theme="snow"
        />
        <br />

        <div align="center">
          <CancelButton
            onClick={this.props.onClose}
            label={"Close"}
            disabled={this.state.loading}
          />
          <SubmitButton
            onClick={this.handleSubmit}
            label={"Send"}
            disabled={this.state.loading || !this.validate()}
          />
          {this.state.loading && (
            <p>
              <CircularProgress />
            </p>
          )}
        </div>
      </div>
    );
  }
}
