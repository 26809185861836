import "firebase/storage";
import { db } from "./fire";

/* this page contains all backend functions regarding events (not registrations, assignment or feedbaks)
The events are saved under the collaction Events, document for each event

all the functions return a promise
*/

/* this function get an object with events and creates
events docs and registration doc under Events/eventid/Eventdata/Registrations */
export function add_event(events) {
  const batch = db.batch();

  events.forEach(event => {
    let eventRef = db.collection("Events").doc();
    let eventData = eventRef.collection("EventData").doc("Registrations");
    batch.set(eventRef, event);
    batch.set(eventData, {});
  });

  return batch.commit();
}

/* the function recives an object of events and deletes the events docs from Events collection,
it does not delete the event from users documents,
but the other pages just ignore this data if the event does not exist */
export function delete_event(events) {
  const batch = db.batch();

  events.forEach(event => {
    let eventRef = db.collection("Events").doc(event.id);
    batch.delete(eventRef.collection("EventData").doc("Registrations"));
    batch.delete(eventRef.collection("EventData").doc("Assignments"));
    batch.delete(eventRef);
  });

  return batch.commit();
}

/* the function recives an object of events update  the events docs from Events collection*/
export function update_event(events) {
  const batch = db.batch();
  events.forEach(event => {
    var eventRef = db.collection("Events").doc(event.id);
    batch.update(eventRef, event);
  });
  return batch.commit();
}

/* get event id are return the rellevant event */
export function get_event_by_id(eventID) {
  let p = new Promise((resolve, reject) => {
    db.collection("Events")
      .doc(eventID)
      .get()
      .then(event => {
        resolve(event.data());
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* returns an object with all the events at the Events Collection in rellevant dates */
export function get_events(start_date, end_date, page_size, previous_event_id) {
  var eventsRef = db.collection("Events");
  var query = eventsRef
    .where("time", ">=", start_date)
    .where("time", "<=", end_date);
  query.orderBy("time").limit(page_size);
  if (previous_event_id) {
    query.startAfter(previous_event_id);
  }

  let result = {};
  let p = new Promise((resolve, reject) => {
    query
      .get()
      .then(events => {
        events.forEach(event => {
          let fullEventData = { id: event.id, ...event.data() };
          //add registrations data
          let eventDataRef = eventsRef.doc(event.id).collection("EventData");
          eventDataRef
            .get()
            .then(eventData => {
              fullEventData = { ...eventData, ...fullEventData };
            })
            .catch(error => {
              reject(error);
            });

          result[event.id] = fullEventData;
        });
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* returns an object with all the events at the Events Collection in rellevant dates */
export function get_events_nofuturelimit(
  start_date,
  page_size,
  previous_event_id
) {
  var eventsRef = db.collection("Events");
  var query = eventsRef.where("time", ">=", start_date);
  query.orderBy("time").limit(page_size);
  if (previous_event_id) {
    query.startAfter(previous_event_id);
  }

  let result = {};
  let p = new Promise((resolve, reject) => {
    query
      .get()
      .then(events => {
        events.forEach(event => {
          let fullEventData = { id: event.id, ...event.data() };
          //add registrations data
          let eventDataRef = eventsRef.doc(event.id).collection("EventData");
          eventDataRef
            .get()
            .then(eventData => {
              fullEventData = { ...eventData, ...fullEventData };
            })
            .catch(error => {
              reject(error);
            });

          result[event.id] = fullEventData;
        });
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* get user object and returns all events that are open to him
    users can see events up to a day after the debate ends.
*/
// returns all events in this range with the same offset and page_size logic. Each event has meta data (date, location, type, etc.)
export function get_user_events(user) {
  let yestrday = new Date(Date.now() - 864e5); // we only want event future events (keep events one day after they happend)
  var eventsRef = db.collection("Events");
  var query = eventsRef.where("time", ">=", yestrday);
  //query.orderBy("time").limit(10); // max 10 events

  let p = new Promise((resolve, reject) => {
    query
      .get()
      .then(events => {
        // check which events are relevent for user
        let relevent_events = [];
        events.forEach(event => {
          if (is_this_event_for_me(event.data(), user)) {
            relevent_events.push({ ...event.data(), id: event.id });
          }
        });

        // check if user already registered to the event
        let result = {};
        if (relevent_events.length === 0) {
          resolve([]);
          return;
        }
        relevent_events.forEach(event => {
          // check if event is for this user
          let registrationRef = db
            .collection("Debaters")
            .doc(user.uid)
            .collection("MyEvents")
            .doc(event.id);

          registrationRef
            .get()
            .then(reg => {
              let eventData;
              // if registered add the reg data to the event
              if (reg.exists) {
                eventData = {
                  id: event.id,
                  registration: reg.data().registration,
                  ...event
                };
                if (reg.data().assignment) {
                  eventData = {
                    assignment: reg.data().assignment,
                    ...eventData
                  };
                }
              } else {
                // else add empty reg data
                eventData = {
                  id: event.id,
                  registration: {},
                  ...event
                };
              }
              result = { ...result, [event.id]: eventData };
              // wait for the last event to finish the queries
              if (relevent_events.length === Object.keys(result).length) {
                resolve(result);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* get event and return if current user should see it */
function is_this_event_for_me(event, user) {
  let uid = user.uid;
  let open_to_jr = event.open_to_jr;
  let open_to_other_clubs = event.open_to_other_clubs;
  let open_to_sr = event.open_to_sr;
  let open_to_non_students = event.open_to_non_students; //todo:change!
  let rank = user.rank;
  let student = user.student;
  let club = user.club;

  if (event["allowRegToUsers"]) {
    if (event["allowRegToUsers"][uid]) {
      return true;
    }
  }
  if (!open_to_jr && rank === "Junior") {
    return false;
  }
  if (!open_to_other_clubs && club !== "TAU") {
    return false;
  }
  if (!open_to_sr && rank === "Senior") {
    return false;
  }
  if (!open_to_non_students & (student === false)) {
    return false;
  }
  return true;
}

/* function for maintenece it does not delte all the event data so don't use it if you don't know what you are doing */
// export function delete_all_events() {
//   db.collection("Events")
//     .get()
//     .then(events => {
//       events.forEach(event => {
//         delete_event(event);
//       });
//     });
// }
