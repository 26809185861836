import {UserDetails} from "../../../pages/UserDetails";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import React, {Component} from "react";
import {CloseButton} from "../../Common/Buttons";
import {isMobile} from "../../Utilities/OtherUtils";

/**
 * Dialog for displaying user details component on top of background
 * For admins viewing/editing other users
 */
export class UserDetailsDialog extends Component {

    constructor(props) {
        super(props);
        this.state={open: true};
        this.handleClose = this.handleClose.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
    }

    handleDeleteUser(){
        if(this.props.onDeleteUser) this.props.onDeleteUser();
        this.handleClose();
    }

    handleClose(){
        this.setState({open:false});
        this.props.onClose();
        this.setState({open:true});
    }
    render() {
        if (!this.props.debater || !this.props.user) {
            return null;
        }
        const thin = {margin: 0, padding: 0, overflowX: 'hidden'};
        return (
            <div>
                <Dialog open={this.state.open}
                        fullWidth={isMobile()}
                        maxWidth={"md"}
                        onClose={this.handleClose}
                        style={thin}>
                    <DialogContent style={thin}>
                        <CloseButton onClick={this.handleClose}/>
                        <UserDetails
                            user={this.props.user}
                            debater={this.props.debater}
                            onClose={this.handleClose}
                            onDeleteUser={this.handleDeleteUser}
                            isDialog={true}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}
