import {DebaterAvatar} from "../UserComponents/DebaterAvatar";
import React from "react";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import Avatar from "@material-ui/core/Avatar";

/**
 * @param debater
 * @returns {boolean}
 */
export function isApprovedUser(debater) {
    return (
        debater.permissions !== "Registration" &&
        debater.permissions !== "Login" &&
        debater.permissions !== "Pending"
    );
}

/**
 * @param debater
 * @returns {boolean}
 */
export function isPendingApproval(debater) {
    return debater.permissions === "Pending";
}

/**
 * @param debater
 * @returns {boolean}
 */
export function isSystemAdmin(debater) {
    return debater.permissions === "Admin";
}

/**
 * @param debater
 * @returns {boolean}
 */
export function isManager(debater) {
    return isSystemAdmin(debater) || debater.permissions === "Manager";
}

/**
 * @param debater
 * @returns {Array}
 * Last 3 academic years + "Dinosaur"
 */
export function getStartYearOptions(){
    const options = [];
    const len = 3;
    let thisYear = (new Date()).getFullYear();

    // After september is already next year. Months are 0-bases, so comparing to 8
    if((new Date().getMonth() >= 8)) thisYear++;

    for (let i = 1; i <= len; i++) {
        let start_year = (thisYear - i).toString() + '-' + (thisYear - i + 1).toString();
        options.push(start_year)
    }
    options.push('Dinosaur (before ' + (thisYear - len).toString() + ')');
    return options;
}

/**
 * @param debater
 * @returns {string}
 */
export function getDefaultRank(debater) {
    if (!debater || !debater.start_year) return "Junior";
    if(debater.start_year.split('-')[1] < new Date().getFullYear()) return "Senior";
    return new Date().getMonth() <= 7 ? "Junior" : "Senior";
}

/**
 * @param debater
 * @returns {string|null|*|null|string}
 */
export function getFullName(debater) {
    if (!debater) return "";
    if (debater.full_name_eng) return debater.full_name_eng;
    if (debater.first_name_eng && debater.last_name_eng)
        return debater.first_name_eng + " " + debater.last_name_eng;
    return null;
}

/**
 * @param debater
 * @returns {number}
 */
export function getAvgScore(debater){
    if (!debater) return 0;
    if(!debater.average_score) return 0;
    return debater.average_score;
}

/**
 * @param debater
 * @returns {string|null|*|null}
 */
export function getFullNameHeb(debater) {
    if (!debater) return "";
    if (debater.full_name_heb) return debater.full_name_heb;
    if (debater.first_name_heb && debater.last_name_heb)
        return debater.first_name_heb + " " + debater.last_name_heb;
    return null;
}

/**
 * @param debater
 * @param size
 * @param onClick
 * @param tooltip
 * @returns {*}
 */
export function getAvatar(debater, size = 40, onClick=null, tooltip=null) {
    return(
        <DebaterAvatar
            debater={debater}
            size={size}
            onClick={onClick}
            tooltip={tooltip}/>
    );
}

/**
 * @param debater
 * @returns {string|string|*}
 */
export function getInitialFullName(debater) {
    if(getFullName(debater)) return getFullName(debater);
    if(getFullNameHeb(debater)) return getFullNameHeb(debater);
    return "New User";
}

/**
 * @param debater
 * @param size
 * @param that
 * @returns {*}
 */
export function getAvatarEditable(debater, size=40, that) {
    if (debater.picture) {
        return (
            <div
                style={{
                    backgroundImage: `url(${debater.picture})`,
                    backgroundSize: "cover", //TODO: THIS SHOULD LOOK EXACTLY LIKE AVATAR
                    verticalAlign: "middle",
                    borderRadius: "50%",
                    width: size,
                    height: size,
                    display: "inline-block",
                    marginBottom: 0
                }}
            >
                <Fab
                    onClick={() => that.setState({ editProfilePicture: true })}
                    style={{
                        cursor: "pointer",
                        marginLeft: size / 2,
                        marginTop: size / 1.3,
                        height: 20,
                        width: 35
                    }}
                >
                    <EditIcon />
                </Fab>
            </div>
        );
    } else {
        return (
            <Avatar
                style={{
                    verticalAlign: "middle",
                    borderRadius: "50%",
                    width: size,
                    height: size,
                    display: "inline-block"
                }}
            >
        <span onClick={() => that.setState({ editProfilePicture: true })}
              style={{ cursor: "pointer", color: "#000000", fontSize: 24}}>
          <br />
          Add
        </span>
            </Avatar>
        );
    }
}

/**
 * @param name
 * @returns {string}
 */
export const getInitials = (name)=>
    name.split(' ').length < 2 ? name :
        name.split(' ')[0].charAt(0).toUpperCase() +
        name.split(' ')[1].charAt(0).toUpperCase();


/**
 * @param score
 * @returns {string}
 */
export function getScoreColor(score) {
    const maxScore = 8;
    const percent = 100*(score / maxScore);
    let r, g, b = 0;
    if(percent < 50){
        r = 220;
        g = Math.round(5.1 * percent);
    } else {
        g = 220;
        r = Math.round(510 - 5.1 * percent);
    }
    const alpha = 0.8;
    return "rgba(" + String(r) + "," + String(g) + "," + String(b) +"," + String(alpha) + ")";
}
