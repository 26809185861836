import React, { Component } from "react";

import { Draggable } from "react-beautiful-dnd";

import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";
import { ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import UserDetailsPopper from "./userDetailsPopper";
import Avatar from "@material-ui/core/Avatar";
import { TAUfilter } from "../EventManagementComps/EventsTable/EventStats";
import AnonIcon from "@material-ui/icons/PermIdentity";
import Tooltip from "@material-ui/core/Tooltip";

const grid = 4;

const getItemStyle = (isDragging, draggableStyle, stateColor, borderColor) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "0 8px",
  margin: `${grid}px`,

  border: `${borderColor} solid 1.7px`,
  width: 140,
  minWidth: 140,

  // change background colour if dragging
  background: isDragging ? "#00A5CF" : stateColor,

  // styles we need to apply on draggables
  ...draggableStyle
});

const getIconStyle = color => ({
  textAlign: "right",
  float: "right",
  padding: 2,
  marginRight: 5,
  right: -5,
  color: color
});

const getIconColor = registration => {
  if (
    registration &&
    registration.additionalComments &&
    registration.additionalComments !== "" &&
    registration.additionalComments !== "Manually registered by admins"
  ) {
    return "#FFD426";
  } else {
    return null;
  }
};

const getAvgBackground = avg => {
  if (avg <= 2) {
    return "#C42525";
  }
  if (avg <= 3) {
    return "#E36135";
  }
  if (avg <= 4) {
    return "#FFCC4F";
  }
  if (avg <= 5) {
    return "#F7FF4F";
  }
  if (avg <= 6) {
    return "#C7FF4F";
  }
  if (avg <= 8) {
    return "#8CFF4F";
  } else {
    return "#ffffff";
  }
};

const styles = theme => ({
  listItemTextStyleSec: {
    fontSize: 12
  },
  listItemTextStyle: {
    fontSize: 13
  },
  margin: {
    margin: 1
  },
  avg: {
    marginLeft: 0,
    marginRight: 5,
    marginTop: 10,
    width: 22,
    height: 22,

    color: "#000000",
    fontSize: 11
  }
});

const getBorderColor = registration => {
  if (registration.debateJudge === "Judge") {
    return "#197DF6";
  } else if (registration.debateJudge === "Debate") {
    return "#000000";
  } else {
    return "#F8F9F9";
  }
};

const getColor = registration => {
  if (registration.cancelledOnTime) {
    return "#F7C2BC";
  } else if (registration.checkedIn) {
    return "#D7F6CD";
  } else if (registration.cancelled) {
    return "#F7C2BC";
  } else {
    return "#F8F9F9";
  }
};

/*
 * A draggable card containing a signle debater's details
 */

class MyDraggable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserId: false,
      color: this.props.registrations[this.props.draggableId]
        ? getColor(this.props.registrations[this.props.draggableId])
        : "#F8F9F9",
      borderColor: this.props.registrations[this.props.draggableId]
        ? getBorderColor(this.props.registrations[this.props.draggableId])
        : "#F8F9F9"
    };
    this.openUserCard = this.openUserCard.bind(this);
  }

  close = () => {
    this.setState({ showUserId: false });
  };

  /* Update the card if the registrations have updated live */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      prevProps.registrations[this.props.draggableId] !==
      this.props.registrations[this.props.draggableId]
    ) {
      this.updateDraggable();
    }
  };

  updateDraggable = () => {
    this.setState({
      showUserId: false,
      color: this.props.registrations[this.props.draggableId]
        ? getColor(this.props.registrations[this.props.draggableId])
        : "#F8F9F9",
      borderColor: this.props.registrations[this.props.draggableId]
        ? getBorderColor(this.props.registrations[this.props.draggableId])
        : "#F8F9F9"
    });
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  openUserCard = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      showUserId: !state.showUserId
    }));
  };

  changeColor = () => {
    let c = "#AF9FA5";
    if (this.state.color === "#AF9FA5") {
      c = "#AFF28B";
    }
    this.setState({
      color: c
    });
  };

  /* During the live event- mark who is in the room */
  handleDoubleClick = () => {
    if (this.state.color === "#F8F9F9") {
      // no status-> item is white (now checking in)
      this.props.updateRegistaration(this.props.item.uid, "checkIn");
    } else if (this.state.color === "#D7F6CD") {
      // checked in -> item is green (now cancelling)
      this.props.updateRegistaration(this.props.item.uid, "cancel");
    } else {
      //cancelled -> item is red (now color white)
      this.props.updateRegistaration(this.props.item.uid, "undo");
    }
  };

  render() {
    let average = this.props.item.average_score;
    average = parseFloat(average);
    average = average.toPrecision(2);
    // eslint-disable-next-line
    if (average == 0.0 || this.props.item.rank === "Senior") {
      //leave '==' in the first one
      average = NaN;
    }

    const { classes } = this.props;
    const id = this.state.open ? "simple-popper" : null;
    // backwords compatability (we changed names fields)
    let name;

    if (this.props.item.full_name_eng) {
      name = this.props.item.full_name_eng;
    } else {
      name = `${this.props.item.first_name_eng} ${this.props.item.last_name_eng}`;
    }
    if (name.length > 9 && !this.props.item.fake) {
      if (!this.props.incognito) {
        name = name.substring(0, 9) + "...";
      } else {
      }
    }
    return (
      <Draggable
        key={this.props.draggableId}
        draggableId={this.props.draggableId}
        index={this.props.index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              this.state.color,
              this.state.borderColor
            )}
          >
            {/* <Fab style={{ fontSize: 13, height : 1, width:15 }} color="secondary" aria-label="avg" >
                            {average}
                        </Fab> */}
            <div
              style={{ display: "flex", flexDirection: "row", height: 45 }}
              onDoubleClick={this.handleDoubleClick}
            >
              {!this.props.incognito && !isNaN(average) && (
                <Avatar
                  style={{ background: getAvgBackground(average) }}
                  className={classes.avg}
                >
                  {average}
                </Avatar>
              )}
              <ListItemText
                style={{ padding: 0 }}
                classes={{
                  primary: classes.listItemTextStyle,
                  secondary: classes.listItemTextStyleSec
                }}
                primary={name}
                secondary={
                  !this.props.incognito && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div>
                        {TAUfilter(this.props.item) && (
                          <img
                            alt="tau"
                            src={require("../../images/tau-logo.png")}
                            style={{
                              width: 16,
                              height: 16,
                              marginLeft: 2,
                              marginRight: 8
                            }}
                          />
                        )}
                      </div>
                      <div> {this.props.item.rank}</div>
                    </div>
                  )
                }
              />
              {!this.props.item.fake && (
                <IconButton
                  aria-label="Account"
                  title={"info"}
                  style={getIconStyle(
                    getIconColor(
                      this.props.registrations[this.props.draggableId]
                    )
                  )}
                  onClick={this.openUserCard}
                >
                  <Info style={{ fontSize: 18 }} />
                </IconButton>
              )}
              {this.props.item.fake && (
                <Tooltip title="Fake user" placement="top">
                  <IconButton aria-label="anon">
                    <AnonIcon style={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <UserDetailsPopper
              incognito={this.props.incognito}
              id={id}
              debater={this.props.item}
              showUserId={this.state.showUserId}
              anchorEl={this.state.anchorEl}
              draggableId={this.props.draggableId}
              changeDebaterId={this.props.changeDebaterId}
              close={this.close}
              registration={
                this.props.registrations[this.props.draggableId]
                  ? this.props.registrations[this.props.draggableId]
                  : {}
              }
            />
          </div>
        )}
      </Draggable>
    );
  }
}

export default withStyles(styles)(MyDraggable);
