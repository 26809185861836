import React, { Component } from "react";
import { CSVExporter } from "../Common/CSVExporter";
import { formatDate, formatDateTime } from "../Utilities/DateTimeUtils";
//import {getFullName} from "../Utilities/DebaterUtilities";

class AssignmentCSVExporter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: "",
      data: [],
      loading: true,
    };
    this.roomsCSV = this.roomsCSV.bind(this);
    this.eventName = this.eventName.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const roomsdata = this.roomsCSV(this.props.rooms);
    const couplesdata = this.couplesCSV(this.props.rooms);
    const eventName =
      formatDate(this.props.event.date) + " - " + this.props.event.event_type;
    const fileName = "Assignments " + eventName;
    this.setState({
      roomsdata: roomsdata,
      couplesdata: couplesdata,
      fileName: fileName,
      loading: false,
    });
  }

  eventName(event) {
    return (
      formatDateTime(this.state.event.time) + " " + this.state.event.event_type
    );
  }

  roomsCSV(rooms) {
    const data = [["Pre-assign Room Name", "Email Address"]];
    let roomNumber = 0;
    for (const roomId in rooms) {
      roomNumber++;
      let keepGoing = true;
      let i = 0;
      const room = rooms[roomId];
      console.log(rooms);
      while (keepGoing) {
        if (i < room["Opening Government"].length) {
          data.push([
            "room" + roomNumber,
            room["Opening Government"][i]["email"],
          ]);
        }
        if (i < room["Opening Opposition"].length) {
          data.push([
            "room" + roomNumber,
            room["Opening Opposition"][i]["email"],
          ]);
        }
        if (i < room["Closing Government"].length) {
          data.push([
            "room" + roomNumber,
            room["Closing Government"][i]["email"],
          ]);
        }
        if (i < room["Closing Opposition"].length) {
          data.push([
            "room" + roomNumber,
            room["Closing Opposition"][i]["email"],
          ]);
        }
        if (i < room["Chair"].length) {
          data.push(["room" + roomNumber, room["Chair"][i]["email"]]);
        }
        if (i < room["Panel"].length) {
          data.push(["room" + roomNumber, room["Panel"][i]["email"]]);
        }
        i++;
        keepGoing =
          i < room["Opening Government"].length ||
          i < room["Opening Opposition"].length ||
          i < room["Closing Government"].length ||
          i < room["Closing Opposition"].length ||
          i < room["Chair"].length ||
          i < room["Panel"].length;
      }
      data.push(["panel room " + roomNumber, "fakeemail@panel.com"]);
    }
    return data;
  }

  couplesCSV(rooms) {
    const data = [["Pre-assign Room Name", "Email Address"]];
    let roomNumber = 0;
    for (const roomId in rooms) {
      roomNumber++;
      let keepGoing = true;
      let i = 0;
      const room = rooms[roomId];
      while (keepGoing) {
        if (i < room["Opening Government"].length) {
          data.push([
            "room" + roomNumber + "Opening Government",
            room["Opening Government"][i]["email"],
          ]);
        }
        if (i < room["Opening Opposition"].length) {
          data.push([
            "room" + roomNumber + "Opening Opposition",
            room["Opening Opposition"][i]["email"],
          ]);
        }
        if (i < room["Closing Government"].length) {
          data.push([
            "room" + roomNumber + "Closing Government",
            room["Closing Government"][i]["email"],
          ]);
        }
        if (i < room["Closing Opposition"].length) {
          data.push([
            "room" + roomNumber + "Closing Opposition",
            room["Closing Opposition"][i]["email"],
          ]);
        }
        if (i < room["Chair"].length) {
          data.push(["room" + roomNumber + "Panel", room["Chair"][i]["email"]]);
        }
        if (i < room["Panel"].length) {
          data.push(["room" + roomNumber + "Panel", room["Panel"][i]["email"]]);
        }
        i++;
        keepGoing =
          i < room["Opening Government"].length ||
          i < room["Opening Opposition"].length ||
          i < room["Closing Government"].length ||
          i < room["Closing Opposition"].length ||
          i < room["Chair"].length ||
          i < room["Panel"].length;
      }
    }
    return data;
  }

  // roomsCSV(rooms) {
  //     const header = ['Room Number', 'Room Name', 'Room Language',
  //                     'Opening Government', 'Opening Opposition', 'Closing Government', 'Closing Opposition',
  //                     'Chair', 'Panel'];
  //     const data=[header];
  //     let roomNumber = 0;
  //     for(const roomId in rooms) {
  //         roomNumber ++;
  //         let keepGoing = true;
  //         let i = 0;
  //         const room = rooms[roomId];
  //         while(keepGoing) {
  //             let row = [roomNumber.toString(), room['Name'], room['Language'],
  //                 i < room['Opening Government'].length ? getFullName(room['Opening Government'][i]):'',
  //                 i < room['Opening Opposition'].length ? getFullName(room['Opening Opposition'][i]):'',
  //                 i < room['Closing Government'].length ? getFullName(room['Closing Government'][i]):'',
  //                 i < room['Closing Opposition'].length ? getFullName(room['Closing Opposition'][i]):'',
  //                 i < room['Chair'].length ? getFullName(room['Chair'][i]):'',
  //                 i < room['Panel'].length ? getFullName(room['Panel'][i]):'',
  //             ];
  //             data.push(row);
  //             i++;
  //             keepGoing =
  //                 (i < room['Opening Government'].length ||
  //                 i < room['Opening Opposition'].length ||
  //                 i < room['Closing Government'].length ||
  //                 i < room['Closing Opposition'].length ||
  //                 i < room['Chair'].length ||
  //                 i < room['Panel'].length);
  //         }
  //     }
  //     return data;
  // }

  render() {
    if (this.state.loading) return null;
    return (
      <div>
        <CSVExporter
          fileName={"Couples " + this.state.fileName}
          data={this.state.couplesdata}
          title="Couples assignment"
        />

        <CSVExporter
          fileName={"Rooms" + this.state.fileName}
          data={this.state.roomsdata}
          title="Rooms assignment"
        />
      </div>
    );
  }
}

export default AssignmentCSVExporter;
