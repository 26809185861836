import React, { Component } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import emptyRoom from "../components/assignmentComps/emptyRoom";
import HorizontalDroppable from "../components/assignmentComps/horizontalDroppable";
import Room from "../components/assignmentComps/room";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { toast, ToastContainer } from "react-toastify";
import {
  get_registrations_for_event,
  get_all_debaters,
  save_assignment,
  load_assignment,
  publish_assignment,
  publish_feedbacks,
  get_debate_history,
  get_event_by_id
} from "../Backend/BackendFunctions";
import { add_fake_registration } from "../Backend/registratinoBackendFunctions";
import AssignmentToolbar from "../components/assignmentComps/assignmentToolbar";
import { confirmAlert } from "react-confirm-alert";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { formatDateTime } from "../components/Utilities/DateTimeUtils";
import {
  getCurrentLocation,
  handleError
} from "../components/Utilities/OtherUtils";
import { ManuallyRegisterDebatersDialog } from "../components/EventManagementComps/ManuallyRegisterDebatersDialog";
import { db } from "../Backend/fire";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  isSystemAdmin,
  getFullName,
  getAvgScore
} from "../components/Utilities/DebaterUtilities";
import { ConfirmAlertBody } from "../components/Common/ConfirmAlert";
import { add_registration_for_user } from "../Backend/registratinoBackendFunctions";
import { configureInitialState } from "../components/assignmentComps/assignmentUtils";
import validateRoom from "../components/assignmentComps/roomValidation";
import automaticAssignment from "../components/assignmentComps/autoAssignment";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  if (droppableSource.droppableId === "database") {
    result["userId"] = removed.uid;
  }

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getBoardStyle = () => ({
  marginTop: 20,
  textAlign: "center"
});

const commentFieldStyle = {
  maxWidth: "90%",
  width: 500,
  marginBottom: 10,
  marginLeft: 5,
  marginRight: 20
};

const getPaperStyle = () => ({
  padding: 20,
  paddingBottom: 160,
  width: "95%",
  marginTop: 40,

  margin: "0 auto",
  overflow: "hidden"
});

export class AssignmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      allDebaters: [],
      registrations: [],
      debaters: [],
      rooms: [],
      rankFilter: "All",
      studentFilter: "All",
      languageFilter: "All",
      clubFilter: "All",
      requestFilter: "All",
      waitList: [],
      trashList: [],
      openWaitListDialog: false,
      openTrashListDialog: false,
      openComment: false,
      incognito: true,
      comment: "",
      openDatabase: false,
      previouslyRegistered: [],
      feedbackRequested: false,
      databaseEntry: 1,
      history: {},
      loading: true,
      failed: false
    };
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  id2List = {
    droppable1: "debaters",
    droppable2: "debaters",
    droppable3: "debaters",
    droppable: "debaters",
    opGov: "Opening Government",
    opOp: "Opening Opposition",
    cloGov: "Closing Government",
    cloOp: "Closing Opposition",
    panel: "Panel",
    chair: "Chair",
    trashListIcon: "trashList",
    trashList: "trashList",
    waitList: "waitList",
    waitListIcon: "waitList",
    database: "database"
  };

  getList = id => {
    if (this.id2List[id] === "debaters") {
      return this.state.debaters;
    } else if (id === "database") {
      return this.state.allDebaters;
    } else if (this.id2List[id] === "waitList") {
      return this.state.waitList;
    } else if (this.id2List[id] === "trashList") {
      return this.state.trashList;
    } else {
      let splitId = id.split(".");
      let room = splitId[0];
      let newId = this.id2List[splitId[1]];
      return this.state.rooms[room][newId];
    }
  };

  onDragEnd = result => {
    window.removeEventListener("beforeunload", this.onUnload);
    window.addEventListener("beforeunload", this.onUnload);
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const debaters = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );
      if (this.id2List[source.droppableId] === "debaters") {
        this.setState({ debaters });
      } else if (this.id2List[source.droppableId] === "database") {
        this.setState({ allDebaters: debaters });
      } else if (this.id2List[source.droppableId] === "waitList") {
        this.setState({ waitList: debaters });
      } else if (this.id2List[source.droppableId] === "trashList") {
        this.setState({ trashList: debaters });
      } else {
        let splitId = source.droppableId.split(".");
        let room = splitId[0];
        let newId = this.id2List[splitId[1]];
        let rooms = this.state.rooms;
        rooms[room][newId] = debaters;
        rooms[room] = validateRoom(rooms[room]);
        this.setState({ rooms });
      }
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );
      let sourceId, destId;
      let debaters = this.state.debaters;
      let rooms = this.state.rooms;
      let waitList = this.state.waitList;
      let trashList = this.state.trashList;
      let allDebaters = this.state.allDebaters;

      if (this.id2List[source.droppableId] === "debaters") {
        debaters = result[source.droppableId];
      } else if (this.id2List[source.droppableId] === "waitList") {
        waitList = result[source.droppableId];
      } else if (this.id2List[source.droppableId] === "trashList") {
        trashList = result[source.droppableId];
      } else if (source.droppableId === "database") {
        if (this.state.previouslyRegistered.includes(result["userId"])) {
          toast.error("User already registered - no duplicates allowed!");
          return;
        } else {
          allDebaters = result[source.droppableId];
        }
      } else {
        sourceId = source.droppableId.split(".");
        let room = sourceId[0];
        let position = this.id2List[sourceId[1]];
        rooms[room][position] = result[source.droppableId];
        rooms[room] = validateRoom(rooms[room]);
      }
      if (this.id2List[destination.droppableId] === "debaters") {
        debaters = result[destination.droppableId];
      } else if (this.id2List[destination.droppableId] === "waitList") {
        waitList = result[destination.droppableId];
      } else if (this.id2List[destination.droppableId] === "trashList") {
        trashList = result[destination.droppableId];
      } else {
        destId = destination.droppableId.split(".");
        let room = destId[0];
        let position = this.id2List[destId[1]];
        rooms[room][position] = result[destination.droppableId];
        rooms[room] = validateRoom(rooms[room]);
      }

      this.setState({
        debaters,
        rooms,
        waitList,
        trashList,
        allDebaters
      });
    }
  };

  handleChangeRankFilter = (event, value) => {
    this.setState({ rankFilter: event.target.value });
  };

  handleChangeStudentFilter = (event, value) => {
    this.setState({ studentFilter: event.target.value });
  };


  handleChangeLanguageFilter = (event, value) => {
    this.setState({languageFilter: event.target.value });
  };

  handleChangeClubFilter = (event, value) => {
    this.setState({ clubFilter: event.target.value });
  };

  handleChangeRequestFilter = (event, value) => {
    this.setState({ requestFilter: event.target.value });
  };

  onUnload = event => {
    // the method that will be used for both add and remove event
    console.log("hellooww");
    event.returnValue = "Hellooww";
  };

  componentDidMount() {
    this.props.onLoad();
    window.addEventListener("beforeunload", this.onUnload);

    const eventId = getCurrentLocation();
    get_event_by_id(eventId)
      .then(dbevent => {
        get_registrations_for_event(eventId).then(dbregistrations => {
          get_all_debaters(10, null).then(dballDebaters => {
            load_assignment(eventId).then(dbprevAssignment => {
              get_debate_history().then(dbdebateHistory => {
                let configuredState = configureInitialState(
                  eventId,
                  dbevent,
                  dbregistrations,
                  dballDebaters,
                  dbprevAssignment,
                  dbdebateHistory
                );

                let {
                  event,
                  registrations,
                  allDebaters,
                  debaters: debatersToAssign,
                  rooms,
                  trashList,
                  comment,
                  previouslyRegistered,
                  feedbackRequested,
                  history,
                  loading
                } = configuredState;

                this.setState({
                  event,
                  registrations,
                  allDebaters,
                  debaters: debatersToAssign,
                  rooms,
                  trashList,
                  comment,
                  previouslyRegistered,
                  feedbackRequested,
                  history,
                  loading
                });

                var eventDataRef = db
                  .collection("Events")
                  .doc(event.id)
                  .collection("EventData")
                  .doc("Registrations");
                eventDataRef.onSnapshot(snapshot => {
                  let debatersToAssign = this.state.debaters;
                  let previouslyRegistered = this.state.previouslyRegistered;
                  let liveRegistrations = snapshot.data();
                  for (let id in liveRegistrations) {
                    if (!(id in dballDebaters)) {
                      if (previouslyRegistered.indexOf(id) === -1) {
                        let fakeDebater = {
                          id: id,
                          uid: id,
                          full_name_eng: liveRegistrations[id].fake_user_name,
                          registration: liveRegistrations[id],
                          fake: true,
                          average_score: 0
                        };
                        debatersToAssign.push(fakeDebater);
                        previouslyRegistered.push(id);

                        continue;
                      }
                    }
                    if (history[id]) {
                      dballDebaters[id]["average_score"] =
                        history[id]["average_score"];
                    }
                    if (previouslyRegistered.indexOf(id) === -1) {
                      debatersToAssign.push(dballDebaters[id]);
                      previouslyRegistered.push(id);
                    }
                  }
                  window.removeEventListener("beforeunload", this.onUnload);
                  window.addEventListener("beforeunload", this.onUnload);
                  this.setState({
                    registrations: liveRegistrations,
                    debaters: debatersToAssign,
                    previouslyRegistered
                  });
                });
              });
            });
          });
        });
      })
      .catch(error => {
        handleError(error, "Failed loading event");
        console.log(error);
        this.setState({ loading: false, failed: true });
      });
  }

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.onUnload);
  };

  automaticAssignment = () => {
    let { debatersToAssign, rooms } = automaticAssignment(
      this.state.debaters,
      this.state.rooms
    );
    window.removeEventListener("beforeunload", this.onUnload);
    window.addEventListener("beforeunload", this.onUnload);
    this.setState({ debaters: debatersToAssign, rooms });
  };

  addRoom = () => {
    let newEmptyRoom = JSON.parse(JSON.stringify(emptyRoom));
    newEmptyRoom = validateRoom(newEmptyRoom);
    let rooms = this.state.rooms;
    rooms.push(newEmptyRoom);
    window.removeEventListener("beforeunload", this.onUnload);
    window.addEventListener("beforeunload", this.onUnload);
    this.setState({ rooms });
  };

  isRoomEmpty(room) {
    let result = true;
    if (
      room["Opening Government"].length > 0 ||
      room["Opening Opposition"].length > 0 ||
      room["Closing Government"].length > 0 ||
      room["Closing Opposition"].length > 0 ||
      room["Panel"].length > 0 ||
      room["Chair"].length > 0
    ) {
      result = false;
    }
    return result;
  }

  deleteRoom = roomId => {
    let rooms = this.state.rooms;
    if (this.isRoomEmpty(rooms[roomId])) {
      rooms.splice(roomId, 1);
      window.removeEventListener("beforeunload", this.onUnload);
      window.addEventListener("beforeunload", this.onUnload);
      this.setState({ rooms });
    } else {
      toast.error("Cannot delete non empty room!");
    }
  };

  generateRoomList = () => {
    const roomsList = [];
    let i = 0;
    for (let room in this.state.rooms) {
      roomsList[i] = (
        <ListItem style={{ display: "inline-block", maxWidth: 1340 }}>
          <Room
            registrations={this.state.registrations}
            incognito={this.state.incognito}
            id={room}
            data={this.state.rooms[room]}
            changeDebaterId={this.props.changeDebaterId}
            changeRoomName={this.changeRoomName}
            changeRoomLanguage={this.changeRoomLanguage}
            deleteRoom={this.deleteRoom}
            updateRegistaration={this.updateRegistaration}
          />
        </ListItem>
      );
      i++;
    }
    return roomsList;
  };

  changeRoomName = (roomId, event) => {
    let rooms = this.state.rooms;
    rooms[roomId]["Name"] = event.currentTarget.value;
    window.removeEventListener("beforeunload", this.onUnload);
    window.addEventListener("beforeunload", this.onUnload);
    this.setState({ rooms });
  };

  changeRoomLanguage = (roomId, event) => {
    let rooms = this.state.rooms;
    rooms[roomId]["Language"] = event.target.value;
    window.removeEventListener("beforeunload", this.onUnload);
    window.addEventListener("beforeunload", this.onUnload);
    this.setState({ rooms });
  };

  toggleWaitRoom = () => {
    let currWaitRoomStatus = this.state.openWaitListDialog;
    this.setState({
      openWaitListDialog: !currWaitRoomStatus
    });
  };

  toggleTrashRoom = () => {
    let currentWaitRoomStatus = this.state.openTrashListDialog;
    this.setState({
      openTrashListDialog: !currentWaitRoomStatus
    });
  };

  saveAsDraft = () => {
    let assignment = {
      debaters: this.state.debaters,
      rooms: this.state.rooms,
      trashList: this.state.trashList,
      comment: this.state.comment,
      previouslyRegistered: this.state.previouslyRegistered,
      feedbackRequested: this.state.feedbackRequested
    };
    if (!this.validateNoErroneousRoom(assignment.rooms)) {
      toast.error("Cannot save- error in one of the rooms!");
      return;
    }
    save_assignment(assignment, this.state.event.id)
      .then(() => {
        window.removeEventListener("beforeunload", this.onUnload);
        toast.info("Saved as draft");
      })
      .catch(error => {
        console.log(error);
        toast.error("There was a problem");
      });
  };

  publishAssignment = () => {
    let assignment = {
      debaters: this.state.debaters,
      rooms: this.state.rooms,
      trashList: this.state.trashList,
      comment: this.state.comment,
      previouslyRegistered: this.state.previouslyRegistered,
      feedbackRequested: this.state.feedbackRequested
    };
    publish_assignment(assignment, this.state.event)
      .then(() => {
        window.removeEventListener("beforeunload", this.onUnload);
        toast.info("Assignment is published!");
      })
      .catch(error => {
        console.log(error);
        toast.error("There was a problem");
      });
  };

  validateNoErroneousRoom = rooms => {
    for (let room in rooms) {
      if (rooms[room].errorStatus === "error") {
        return false;
      }
    }
    return true;
  };

  requestFeedbacks = () => {
    let assignment = {
      debaters: this.state.debaters,
      rooms: this.state.rooms,
      trashList: this.state.trashList,
      comment: this.state.comment,
      previouslyRegistered: this.state.previouslyRegistered
    };
    publish_feedbacks(assignment, this.state.event)
      .then(() => {
        this.setState({ feedbackRequested: true });
        toast.info("Feedbacks have been requested from the chairs!");
      })
      .catch(error => {
        console.log(error);
        toast.error("There was a problem");
      });
  };

  toggleIncognito = () => {
    let incognito = !this.state.incognito;
    this.setState({ incognito });
  };

  toggleComment = () => {
    let openComment = !this.state.openComment;
    this.setState({ openComment });
  };

  handleChangeComment = e => {
    window.removeEventListener("beforeunload", this.onUnload);
    window.addEventListener("beforeunload", this.onUnload);
    this.setState({
      comment: e.target.value
    });
  };

  toggleDatabase = () => {
    let openDatabase = !this.state.openDatabase;
    this.setState({ openDatabase });
  };

  popupPublishConfirm = () => {
    if (!this.validateNoErroneousRoom(this.state.rooms)) {
      toast.error("Cannot publish- error in one of the rooms!");
      return;
    }
    const message = (
      <span>{"Are you sure you want to publish this assignment?"}</span>
    );
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlertBody
            onClose={onClose}
            message={message}
            onSubmit={() => this.publishAssignment()}
          />
        );
      }
    });
  };

  popupIncognitoConfirm = () => {
    if (!this.state.incognito) {
      this.toggleIncognito();
      return;
    }
    const message = (
      <span>{"Are you sure you want to turn off incognito?"}</span>
    );
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlertBody
            onClose={onClose}
            message={message}
            onSubmit={() => this.toggleIncognito()}
          />
        );
      }
    });
  };

  addFakeDebaters = debaters => {
    debaters.forEach(debater => {
      // eslint-disable-next-line
      let res = add_fake_registration(
        this.state.event.id,
        debater.id,
        debater.full_name_eng
      );
    });
  };
  updateRegistaration = (id, state) => {
    let cancelledOnTime = false;
    let cancelled = false;
    let checkedIn = false;

    switch (state) {
      case "checkIn": {
        checkedIn = true;
        break;
      }
      case "cancel": {
        cancelled = true;
        break;
      }
      default: {
      }
    }
    let registrationToUpdate = this.state.registrations[id];
    registrationToUpdate = {
      ...registrationToUpdate,
      cancelledOnTime,
      cancelled,
      checkedIn
    };
    add_registration_for_user(this.state.event.id, id, registrationToUpdate);
  };

  sortByAlphabet = () => {
    let debaters = this.state.debaters;
    debaters = debaters.sort((d1, d2) =>
      getFullName(d1) < getFullName(d2) ? -1 : 1
    );
    this.setState(debaters);
  };

  sortByScore = () => {
    if (this.state.incognito) return;
    let debaters = this.state.debaters;
    debaters = debaters.sort((d1, d2) =>
      getAvgScore(d1) > getAvgScore(d2) ? -1 : 1
    );
    this.setState(debaters);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <ToastContainer
          position={toast.POSITION.TOP_CENTER}
          hideProgressBar={true}
          autoClose={2000}
        />

        <Dialog
          onClose={this.toggleComment}
          aria-labelledby="simple-dialog-title"
          open={this.state.openComment}
          style={{ padding: 20 }}
        >
          <TextField
            style={commentFieldStyle}
            onChange={e => this.handleChangeComment(e)}
            value={this.state.comment}
            label="Additional Comments"
            margin="normal"
            variant="outlined"
            multiline={true}
            rows="10"
          />
        </Dialog>

        {this.state.openDatabase && (
          <ManuallyRegisterDebatersDialog
            registeredDebaterIds={this.state.previouslyRegistered}
            event={this.state.event}
            onClose={() => this.toggleDatabase()}
            onAddFakeDebaters={debaters => this.addFakeDebaters(debaters)}
          />
        )}

        <Paper style={getPaperStyle()} elevation={1}>
          {this.state.event.date &&
            this.state.event.time &&
            !this.state.loading && (
              <h1 style={{ display: "inline-block", margin: 5 }}>
                Assignment For{" "}
                <b>
                  {formatDateTime(this.state.event.time)} -{" "}
                  {this.state.event.event_type}
                </b>
              </h1>
            )}

          {this.state.failed && <h1 align="center">Error! Event not Found</h1>}

          {!this.state.loading && !this.state.failed && (
            <AssignmentToolbar
              rankFilter={this.state.rankFilter}
              studentFilter={this.state.studentFilter}
              languageFilter={this.state.languageFilter}
              clubFilter={this.state.clubFilter}
              requestFilter={this.state.requestFilter}
              handleChangeRankFilter={this.handleChangeRankFilter}
              handleChangeStudentFilter={this.handleChangeStudentFilter}
              handleChangeLanguageFilter={this.handleChangeLanguageFilter}
              handleChangeClubFilter={this.handleChangeClubFilter}
              handleChangeRequestFilter={this.handleChangeRequestFilter}
              automaticAssignment={this.automaticAssignment}
              saveAsDraft={this.saveAsDraft}
              publishAssignment={this.popupPublishConfirm}
              toggleTrashRoom={this.toggleTrashRoom}
              openTrashListDialog={this.state.openTrashListDialog}
              trashList={this.state.trashList}
              changeDebaterId={this.props.changeDebaterId}
              requestFeedbacks={this.requestFeedbacks}
              incognito={this.state.incognito}
              toggleIncognito={this.popupIncognitoConfirm}
              addRoom={this.addRoom}
              openComment={this.toggleComment}
              openDatabase={this.toggleDatabase}
              feedbackRequested={this.state.feedbackRequested}
              registrations={this.state.registrations}
              admin={isSystemAdmin(this.props.user)}
              event={this.state.event}
              rooms={this.state.rooms}
              updateRegistaration={this.updateRegistaration}
              sortByAlphabet={this.sortByAlphabet}
              sortByScore={this.sortByScore}
            />
          )}

          <div className="assignment-board" style={getBoardStyle()}>
            {this.state.loading && (
              <div align={"center"}>
                <CircularProgress />
              </div>
            )}
            {!this.state.loading && this.generateRoomList()}
          </div>
        </Paper>

        <HorizontalDroppable
          registrations={this.state.registrations}
          incognito={this.state.incognito}
          data={this.state.debaters}
          droppableId="droppable"
          requestFilter={this.state.requestFilter}
          rankFilter={this.state.rankFilter}
          studentFilter={this.state.studentFilter}
          languageFilter={this.state.languageFilter}
          clubFilter={this.state.clubFilter}
          changeDebaterId={this.props.changeDebaterId}
          chair={false}
          updateRegistaration={this.updateRegistaration}
        />
      </DragDropContext>
    );
  }
}
