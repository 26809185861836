/*
 * This Module is responsible for calculating and summarizing the event's current state
 * Referring to registration, check-in, start time, assignment and feedback
 */

import {formatDate, timeLeftString, tsToDate, minDate} from "../Utilities/DateTimeUtils";
import React from "react";
const now = new Date();

/**
 * Compact summary of event life-cycle status in terms of registrations, assignment, check-in and actual event time
 */
export function eventStatusSummary(event, isAdmin=false, isRegistered=false){

    return (
        <span>
            <span>{registrationStatus(event, isAdmin, isRegistered)}</span>
            {/*TODO:
            <div>{assignmentStatus(event)}</div>
            <div>{feedbackStatus(event)}</div>*/}
        </span>
    )
}

export function assignmentStatus(event) {
    return (
        <span>
            Assignment: Published
        </span>);
}


export function feedbackStatus(event) {
    return (
        <span style={{color: event.requestedFeedbacks ? '#999999' : '#EE3333'}}>
            Feedback: {event.requestedFeedbacks ? 'Feedbacks' : 'No Feedbacks'}
        </span>);
}


export function registrationStatus(event, isAdmin, isRegistered) {
    if(!event) return null;

    const timeToRegistration = timeLeftString(tsToDate(event.registration_start));
    const timeToRegistrationEnd = timeLeftString(minDate(tsToDate(event.registration_end),tsToDate(event.time)));
    const timeToCheckIn = timeLeftString(tsToDate(event.check_in_start));
    const timeToEvent = timeLeftString(tsToDate(event.time));

    const tooltip =
        "Registration: " + formatDate(tsToDate(event.registration_start)) + " - " + formatDate(tsToDate(event.registration_end)) + "\n" +
        "Check In: " + formatDate(tsToDate(event.check_in_start));

    if(!registrationStarted(event) && !registrationEnded(event)) {
        return <span title={tooltip} style={{color: '#777777'}}>Registration starts in {timeToRegistration}</span>
    }

    if(registrationOpen(event) && (!isRegistered || isAdmin)) {
        return (
            <span title={tooltip} >

                { // Show registration time only if admin, or regular debater but not already registered
                (isAdmin || !isRegistered) &&
                <span style={{color: '#5577AA'}}>Registration ends in {timeToRegistrationEnd}</span>}

                {checkInStarted(event) &&
                <span style={{color: '#55BB55'}}><br />Check in Started (events start in {timeToEvent})</span>}

            </span>
        );
    }

    if(isAdmin && registrationEnded(event) && !checkInStarted(event)) {
        return (
            <span title={tooltip} style={{color: '#55BB55' }}>Registration Over, check in starts in {timeToCheckIn}</span>
        );
    }

    if(!isAdmin && isRegistered && !checkInStarted(event)) {
        return (
            <span title={tooltip} style={{color: '#55BB55' }}>Check in starts in {timeToCheckIn}</span>
        );
    }

    if(checkInStarted(event) && !eventStarted(event)) {
        return (
            <span title={tooltip} style={{color: '#55BB55' }}>Check in Started (events start in {timeToEvent})</span>
        );
    }

    if(eventLive(event)){
        return <span title={tooltip} style={{color: '#55BB55'}}><b>Events are Live!</b></span>
    }

    // Otherwise event is over
    return null;//<span title={tooltip} style={{color: '#AAAAAA'}}>Event Over</span>
}

export function registrationStarted(event) {
    return now.getTime() >= tsToDate(event.registration_start).getTime();
}

export function registrationEnded(event) {
    return now.getTime() >= tsToDate(event.registration_end).getTime();
}

export function checkInStarted(event) {
    return now.getTime() >= tsToDate(event.check_in_start).getTime();
}

export function eventStarted(event) {
    return now.getTime() >= tsToDate(event.time).getTime();
}

export function eventEnded(event) {
    const eventEnd = new Date(tsToDate(event.time).valueOf());
    eventEnd.setHours(eventEnd.getHours() + 6);
    return now.getTime() >= eventEnd.getTime();
}

export function registrationOpen(event) {
    return registrationStarted(event) && !registrationEnded(event);
}

export function checkInOpen(event) {
    return checkInStarted(event) && !eventEnded(event);
}

export function eventLive(event) {
    return eventStarted(event) && !eventEnded(event);
}
