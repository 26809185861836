import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import SignOutLinks from "./Links/SignOutLinks";
import DebaterLinks from "./Links/DebaterLinks";
import AdminLinks from "./Links/AdminLinks";
import ManagerLinks from "./Links/ManagerLinks";
import "./SideDrawer.css";

function getSudentNavButtons(props) {
  if (props.permissions === "Login") {
    return (
      <div>
        <div className="spacer" />
        <SignOutLinks {...props} />
      </div>
    );
  } else if (props.permissions === "Student") {
    return <DebaterLinks {...props} />;
  }
}

function getAdminButtons(props) {
  if (props.permissions === "Admin") {
    return <AdminLinks {...props} />;
  }
}

function getManagerButtons(props) {
  if (props.permissions === "Manager") {
    return <ManagerLinks {...props} />;
  }
}

class sideDrawer extends Component {
  handleClickOutside = event => {
    if (this.props.show) this.props.close();
  };

  render() {
    let drawerClasses = "side-drawer";
    if (this.props.show) {
      drawerClasses = "side-drawer open";
    }
    return (
      <nav className={drawerClasses}>
        <div style={{ marginTop: 20 }}>{getAdminButtons(this.props)}</div>
        <div style={{ marginTop: 20 }}>{getManagerButtons(this.props)}</div>
        <div style={{ marginTop: 20 }}>{getSudentNavButtons(this.props)}</div>
      </nav>
    );
  }
}

export default onClickOutside(sideDrawer);
