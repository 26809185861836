import React, { Component } from "react"
import {tsToDate, addDays, truncateDate} from "../../Utilities/DateTimeUtils";
import {getDefaultEvents, getEmptyEvent} from "./NewEventsTemplates";
import {CancelButton, SubmitButton} from "../../Common/Buttons";
import {NewEventsFormHeader} from "./NewEventsFormHeader";
import {NewEventsLoadTemplate} from "./NewEventsLoadTemplate";
import {SingleEventItem} from "./SingleEventItem";

/**
 * Main component for creating, editing and deleting events
 * Can be used for creating new events on an available date, or editing/deleting events of a given date
 * Manages both the events' unified schedule (date, registration, check-in)
 * and specific attributes per event (location, time, etc.)
 */
export class NewEventForm extends Component {
    constructor(props) {
        super(props);

        if (this.props.eventsArr){
            const eventsArr = [];

            // Deep Clone array from props to detect changes
            for(const event of this.props.eventsArr) {
                event.delete_me = false;
                const copy = {};
                for(const key in event){
                    copy[key] = event[key];
                }
                copy['time'] = tsToDate(copy['time']);
                eventsArr.push(copy);
            }

            this.state = {
                showTemplateButtons: false,
                date: tsToDate(eventsArr[0].date),
                registration_start: tsToDate(eventsArr[0].registration_start),
                registration_end: tsToDate(eventsArr[0].registration_end),
                check_in_start: tsToDate(eventsArr[0].check_in_start),
                events: eventsArr,
                showDeleteConfirmation: {},
                expandedEvents: {}
            }
        } else {
            this.state = {
                showTemplateButtons: true,
                date: null,
                registration_start: null,
                registration_end: null,
                check_in_start: null,
                events: []
            };
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleEventFieldChange = this.handleEventFieldChange.bind(this);
        this.addEmptyEvent = this.addEmptyEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.restoreEvent = this.restoreEvent.bind(this);
        this.eventsWereChanged = this.eventsWereChanged.bind(this);
        this.eventsWereDeleted = this.eventsWereDeleted.bind(this);
    }

    handleClose(){
        this.props.onClose();
    }

    handleSubmit() {
        const eventsArr = [];
        for(const event of this.state.events){
            const hours = event.time.getHours();
            const minutes = event.time.getMinutes();
            event.date = new Date(this.state.date.valueOf());
            event.time = new Date(this.state.date.valueOf());
            event.time.setHours(hours);
            event.time.setMinutes(minutes);
            event.registration_start = new Date(this.state.registration_start.valueOf());
            event.registration_end = new Date(this.state.registration_end.valueOf());
            event.check_in_start = new Date(this.state.check_in_start.valueOf());
            eventsArr.push(event);
        }
        this.props.onSubmit(eventsArr);
    }

    uploadTemplate(templateName){
        const template = getDefaultEvents(templateName, this.props.allEvents);
        this.setState({
            date: template.date,
            registration_start: template.registration_start,
            registration_end: template.registration_end,
            check_in_start: template.check_in_start,
            events: template.events,
            showTemplateButtons: false});
    }

    deleteEvent(index){
        const newEvents = this.state.events;
        newEvents[index].delete_me = true;
        this.setState({events: newEvents, showDeleteConfirmation: {}});
    }

    restoreEvent(index){
        const newEvents = this.state.events;
        newEvents[index].delete_me = false;
        this.setState({events: newEvents});
    }

    handleEventFieldChange(index, field, value) {
        const newEvents = this.state.events;
        newEvents[index][field] = value;
        if(field==='event_type_option') newEvents[index]['event_type'] = value === "Other" ? "":value;
        this.setState({events: newEvents}, ()=>this.forceUpdate());
    }

    toggleFilter(index, filter){
        const newEvents = this.state.events;
        newEvents[index][filter] = !newEvents[index][filter];
        this.setState({events: newEvents});
    }

    // Compare changes between the events in state and in props
    eventsWereChanged(){
        const nonDeletedEvents = this.state.events.filter(event => !event.delete_me);
        if(!this.props.eventsArr){
            return nonDeletedEvents.length !== 0
        }
        if(this.props.eventsArr.length !== nonDeletedEvents.length) return true;
        if(this.state.date.getTime() !== tsToDate(this.state.events[0].date).getTime()) return true;
        if(this.state.registration_start.getTime() !== tsToDate(this.state.events[0].registration_start).getTime()) return true;
        if(this.state.registration_end.getTime() !== tsToDate(this.state.events[0].registration_end).getTime()) return true;
        if(this.state.check_in_start.getTime() !== tsToDate(this.state.events[0].check_in_start).getTime()) return true;
        for(const event of this.props.eventsArr){
            for(const event2 of this.state.events){
                if(event.id === event2.id){
                    for(const key in event){
                        if(key !== 'time' && event[key] !== event2[key]) {
                            return true;
                        }
                        if(key === 'time' && tsToDate(event[key]).getTime() !== event2[key].getTime()) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    eventsWereDeleted(){
        for(const event of this.state.events) {
            if(event.delete_me && event.id) return true;
        }
        return false;
    }

    addEmptyEvent(){
        const events = this.state.events;
        events.push(getEmptyEvent(this.state.date));
        this.setState({events: events});
    }

    render() {
        const eventsWereChanged = this.eventsWereChanged();
        const eventsWereDeleted = this.eventsWereDeleted();
        return (
            <div style={{paddingTop: 20, paddingBottom: 20}}>
                {this.state.showTemplateButtons &&
                    <NewEventsLoadTemplate allEvents={this.props.allEvents}
                                           onExit={()=>this.handleClose()}
                                           onSelect={(template)=>this.uploadTemplate(template)}/>}

                {!this.state.showTemplateButtons &&
                <NewEventsFormHeader
                    date={this.state.date}
                    allEvents={this.props.allEvents}
                    check_in_start={this.state.check_in_start}
                    registration_start={this.state.registration_start}
                    registration_end={this.state.registration_end}
                    onDateChange={(dt)=>this.setState({date: truncateDate(dt),
                                                            registration_end: addDays(dt, -1),
                                                            check_in_start: addDays(dt, -1)})}
                    onCheckInChange={(dt)=>this.setState({check_in_start: dt})}
                    onRegStartChange={(dt)=>this.setState({registration_start: dt})}
                    onRegEndChange={(dt)=>this.setState({registration_end: dt})}
                    onExit={()=>this.handleClose()}
                    onAdd={()=>this.addEmptyEvent()}
                />}

                {!this.state.showTemplateButtons &&
                this.state.events.map((event, index) => {
                    return(
                        <SingleEventItem key={index}
                                         index={index}
                                         eventsArr={this.props.eventsArrf}
                                         onFieldChange={(value, field)=>this.handleEventFieldChange(index, field, value)}
                                         onFilterChange={(filter)=>this.toggleFilter(index, filter)}
                                         onRestoreEvent={()=>this.restoreEvent(index)}
                                         onDeleteEvent={()=>this.deleteEvent(index)}
                                         event={event}/>
                    )
                })}

                {!this.state.showTemplateButtons &&
                <div align="center" style={{marginTop:'10px'}}>
                    {eventsWereDeleted && <label style={{color:"#CC0000"}}><br/>Warning! Deleting Events is Irreversible<br/><br/></label>}
                    <CancelButton
                        onClick={()=>this.handleClose()}
                        label={"Close"}/>

                    <SubmitButton
                        disabled={!eventsWereChanged && !eventsWereDeleted}
                        onClick={()=>this.handleSubmit()}
                        label={"Submit"}/>
                </div>}
            </div>
        );
    }
}
