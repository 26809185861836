import React, { Component } from "react"
import {formatDate, formatTime, tsToDate} from "../Utilities/DateTimeUtils";
import DialogTitle from "@material-ui/core/DialogTitle";
import {CloseButton, SubmitButton} from "../Common/Buttons";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import {toast} from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';

/**
 * A dialog for creating an invitation link to some/all events in a given date
 */
export class ShareEventsInvitations extends Component {
  constructor(props) {
    super(props);
    const filter = {};
    for(const event of props.events) {
      filter[event.id] = true;
    }
    this.state = {
      filter: filter,
      link: "",
      filteredCount: this.props.events.length

    };
    this.toggleEvent = this.toggleEvent.bind(this);
    this.getLink = this.getLink.bind(this);
  }

  componentDidMount(): void {
    this.setState({link: this.getLink(this.state.filter)})
  }

  getLink(filter) {
    const currentAddressArr = window.location.href.split('/');
    currentAddressArr.pop();
    const domain = currentAddressArr.join('/');
    const base = domain + "/home/addReg=";
    let link = base;
    for(const event of this.props.events) {
      if(filter[event.id]) link += event.id + ','
    }
    if (link === base) return "";
    return link.substr(0, link.length - 1);
  }

  toggleEvent(eventId) {
    let filteredCount = this.state.filteredCount;
    const filter = this.state.filter;
    if(filter[eventId]) filteredCount += -1;
    else filteredCount++;
    filter[eventId] = !filter[eventId];
    const link = this.getLink(filter);
    this.setState({link: link, filter: filter, filteredCount: filteredCount});
  }

  render(){
    return (
      <Dialog
        open={true}
        maxWidth="sm"
        onClose={this.props.onClose}>
        <DialogTitle>
                  <span style={{marginRight: 10}}>
                      Share invitation link for {formatDate(this.props.date)}
                  </span>
          <CloseButton onClick={this.props.onClose} />
        </DialogTitle>
        <DialogContent style={{padding: 10, margin: 0}}>
          {this.props.events.map(event => {
            return (
              <div style={{padding: 0, margin: 0}}>
                <Checkbox
                  checked={this.state.filter[event.id]}
                  onChange={()=>this.toggleEvent(event.id)}/>
                <label style={{marginLeft: 5}}>Event: </label>
                <span style={{marginRight: 15}}>{event.event_type}</span>
                <label>Time: </label>
                <span>{formatTime(tsToDate(event.time))}</span>
              </div>
            );
          })}
          <br/>
          <div align={"center"}>
            <CopyToClipboard text={this.state.link}
                             onCopy={(text) => toast.info('Link copied to clipboard')}>
              <SubmitButton
                disabled={this.state.filteredCount === 0}
                label={"Copy Link"}/>
            </CopyToClipboard>

          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

