import React from "react";
import { NavLink } from "react-router-dom";
import { auth } from "../../../Backend/fire";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";

const DebaterLinks = props => (
  <List component="nav-list" aria-label="nav-list">
    <NavLink to="/">
      <ListItem
        button
        onClick={() => {
          if (props.show) props.close();
        }}
      >
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </NavLink>

    <NavLink
      to={{
        pathname: "/profile",
        state: { id: "1" }
      }}
    >
      <ListItem
        button
        onClick={() => {
          if (props.show) props.close();
        }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary="My Profile" />
      </ListItem>
    </NavLink>

    <NavLink to="/AboutUs">
      <ListItem
        button
        onClick={() => {
          if (props.show) props.close();
        }}
      >
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="About" />
      </ListItem>
    </NavLink>

    <NavLink
      to="/login"
      onClick={() => {
        auth.signOut().then(() => {
          window.location.reload();
        });
      }}
    >
      <ListItem button>
        <ListItemText primary="Sign Out" />
      </ListItem>
    </NavLink>
  </List>
);

export default DebaterLinks;
