import firebase from "./fire";
import { db, auth } from "./fire";

/* this page contains all backend functions who reads or modify users details 
user details are saved at two palces at the firebase DB under the Debaters collection:
1. as seperate documents for every user.
2. as a filed in the document All_Debaters.
the seperation is there for information security reasons and so we can use less readings from the DB.
the All_Debater documents has only the user's detail and not the registration and feedback data

all the functions return a promise
*/

/* this function is called when the user enters the system in order to get his permissions and personal details 
    when the user first logs in the function will create a new user document form the login details (his gmail accunt for example) and the details he entered
*/
export function authenticateUser() {
  let p = new Promise((resolve, reject) => {
    auth.onAuthStateChanged(function(authUser) {
      // when user is signed in check if user in Debaters tabel
      if (authUser) {
        // get user document form "Debaters"
        var dbUser = db
          .collection("Debaters")
          .doc(authUser.uid)
          .get();

        dbUser.then(doc => {
          //check his name languge
          let lang = "full_name_eng";
          if (authUser.displayName) {
            if (authUser.displayName.match(/[\u0590-\u05FF]/)) {
              lang = "full_name_heb";
            }
          }
          // if the user is not registered send backBasic details whith permission "Registration"
          if (!doc.exists) {
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              phone_number: authUser.phoneNumber,
              picture: authUser.photoURL,
              [lang]: authUser.displayName,
              permissions: "Registration"
            };
          } else {
            //if user exists in Debaters merge authUser details and db user details
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              phone_number: authUser.phoneNumber,
              picture: authUser.photoURL,
              [lang]: authUser.displayName,
              ...doc.data()
            };
          }
          //return user details
          resolve(authUser);
        });
        //if got error
        dbUser.catch(error => {
          reject(error);
        });
      } else {
        //if logged out
        resolve({ permissions: "Login" });
      }
    });
  });
  return p;
}

/* update firebase DB with new user data
user details are saved as a separate doc and under All_Debaters
*/
export function update_user_details(user) {
  let p = new Promise((resolve, reject) => {
    var docRef = db.collection("Debaters").doc(user.uid);
    docRef
      .get()
      .then(thisDoc => {
        // add debater to All_Debters doc
        db.collection("Debaters")
          .doc("All_Debaters")
          .update({ [user.uid]: user });
        if (thisDoc.exists) {
          //user allready in DB, only update
          resolve(docRef.update(user));
        } else {
          //if the new user in not in the DB
          db.collection("Debaters")
            .doc(user.uid)
            .set(user)
            .catch(error => {
              reject(error);
            });
          // create user data
          resolve(docRef.set(user));
        }
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* this function deletes the user document from Debaters collection and All_Debaters
    it should only be used to delete users in the waiting to approval stage(!!) otherwise the system will not work properly
    it does not delete users registrations, assignments and feedbacks
*/
export function delete_user(userID) {
  const batch = db.batch();
  let docRef = db.collection("Debaters").doc(userID);
  batch.delete(docRef);
  let allDebatersRef = db.collection("Debaters").doc("All_Debaters");
  batch.update(allDebatersRef, {
    [userID]: firebase.firestore.FieldValue.delete()
  });
  return batch.commit();
}

// returns all debaters in Debaters/All_Debaters
export function get_all_debaters() {
  let p = new Promise((resolve, reject) => {
    db.collection("Debaters")
      .doc("All_Debaters")
      .get()
      .then(debaters => {
        resolve(debaters.data());
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/*
turns all the juniors to seniors
*/
// export function turn_all_juniors_to_seniors() {
//   get_all_debaters().then(debaters => {
//     console.log(debaters);
//     for (let debater in debaters) {
//       console.log(debater);
//       if (debaters[debater].rank === "Junior") {
//         debaters[debater].rank = "Senior";
//         console.log("changed");
//         update_user_details(debaters[debater]);
//       }
//     }
//   });
// }

/* after user first login he enters a waiting list and wait for admin aproval
users permissions in this stage are "Pending"
this function returns all the debaters in the waiting list

returns an object with userid as keys
*/
export function get_all_waiting_debaters(page_size, previous_user_id) {
  var eventsRef = db.collection("Debaters");
  //query all pending users
  var query = eventsRef.where("permissions", "==", "Pending");
  query.orderBy("first_name_eng");
  if (page_size) {
    query.limit(page_size);
  }
  if (previous_user_id) {
    query.startAfter(previous_user_id);
  }

  let p = new Promise((resolve, reject) => {
    query
      .get()
      .then(debaters => {
        let finalDebaters = {};
        debaters.forEach(debater => {
          finalDebaters = { [debater.id]: debater.data(), ...finalDebaters };
        });
        resolve(finalDebaters);
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}
