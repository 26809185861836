import emptyRoom from "./emptyRoom";
import validateRoom from './roomValidation';

/*
* This function is a simple algorithm designed to automatically assign debaters to debate rooms
*/
export default function automaticAssignment(debatersToAssign, rooms){
    if(debatersToAssign.length === 0){
        return {debatersToAssign, rooms}
    }
    let debaterToAssign;
    let roomIsFull = false;
    while(debatersToAssign.length > 0){
        for (let room in rooms){
            roomIsFull = false;

            if(rooms[room]["Chair"].length === 0 ){
                debaterToAssign = debatersToAssign.pop();
                rooms[room]["Chair"].push(debaterToAssign)
                rooms[room]=validateRoom(rooms[room])
                break;
            }
            if(rooms[room]["Opening Government"].length < 2){
                debaterToAssign = debatersToAssign.pop();
                rooms[room]["Opening Government"].push(debaterToAssign)
                rooms[room]=validateRoom(rooms[room])
                break;
            }
            if(rooms[room]["Opening Opposition"].length < 2){
                debaterToAssign = debatersToAssign.pop();
                rooms[room]["Opening Opposition"].push(debaterToAssign)
                rooms[room]=validateRoom(rooms[room])
                break;
            }
            if(rooms[room]["Closing Government"].length < 2){
                debaterToAssign = debatersToAssign.pop();
                rooms[room]["Closing Government"].push(debaterToAssign)
                rooms[room]=validateRoom(rooms[room])
                break;
            }
            if(rooms[room]["Closing Opposition"].length < 2){
                debaterToAssign = debatersToAssign.pop();
                rooms[room]["Closing Opposition"].push(debaterToAssign)
                rooms[room]=validateRoom(rooms[room])
                break;
            }
            roomIsFull= true;
            
        }
        if(roomIsFull && debatersToAssign.length >=9 ){
            let newEmptyRoom = JSON.parse(JSON.stringify(emptyRoom));
            newEmptyRoom = validateRoom(newEmptyRoom);
            rooms.push(newEmptyRoom);
            continue;
        }
        else if(debatersToAssign.length <=8){
            break;
        }
    }
    return {debatersToAssign, rooms}
}