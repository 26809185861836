import React, { Component } from "react";
import AllPost from "../components/Announcements/AllPost";
import EditComponent from "../components/Announcements/EditComponent";
import {
  get_all_posts,
  delete_post,
  post_post,
  sendEmailsToDebaterGroup
} from "../Backend/BackendFunctions";
import { handleError, pageLoaded } from "../components/Utilities/OtherUtils";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlertBody } from "../components/Common/ConfirmAlert";

//import "./Announcements.css";

// this is the main Announcements components
// it allows Student users to read the society's posts
// and Admin users can create edit or delete posts

// // all the Announcements components uses redux Store.
// the store  allows all components under <Provider store={store}> to accsess props.store.
// store can hold different functions stored at the combineReducers
// the blog reducer is saved at postReducer

// main Announcements component
class Announcements extends Component {
  constructor(props) {
    super(props);
    // downloading posts from server
    this.state = { posts: {} };
    this.getpostForm = this.getpostForm.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.delete_a_post = this.delete_a_post.bind(this);
    this.delete_a_post_internal = this.delete_a_post_internal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (pageLoaded("Announcements")) this.props.onLoad();
    get_all_posts()
      .then(allposts => {
        if (allposts) {
          this.setState({ posts: allposts });
        }
      })
      .catch(error => {
        alert(error);
      });
  }

  // the function return the PostForm component for admin users
  getpostForm(permissions) {
    if (permissions === "Admin" || permissions === "Manager") {
      return (
        <EditComponent handleSubmit={this.handleSubmit} texts={this.state} />
      );
    }
  }

  handleSubmit(post) {
    let settings = post.settings;
    sendEmailsToDebaterGroup(
      settings.forJuniors,
      settings.forSeniors,
      settings.forOthers,
      settings.forNonStudents,
      "TAU Debate Announcement",
      post.text
    );
    post_post(post)
      .then(() => {
        this.setState({ posts: { ...this.state.posts, [post.id]: post } });
      })
      .catch(error => {
        console.log(error);
      });
  }

  delete_a_post = postID => {
    const message = "Are you sure you want to delete this announcement?";
    confirmAlert({
      onClickOutside: () => {},
      customUI: ({ onClose }) => (
        <ConfirmAlertBody
          onClose={onClose}
          variant={"warning"}
          message={message}
          onSubmit={() => this.delete_a_post_internal(postID)}
        />
      )
    });
  };

  delete_a_post_internal(postID) {
    delete_post(postID)
      .then(() => {
        delete this.state.posts[postID];
        this.setState(this.state.posts);
      })
      .catch(e => handleError(e));
  }

  render() {
    return (
      <div className="App">
        {this.getpostForm(this.props.permissions)}
        <AllPost
          permissions={this.props.permissions}
          posts={this.state.posts}
          delete_a_post={this.delete_a_post}
        />{" "}
        ,
      </div>
    );
  }
}
export default Announcements;
