import React from "react";
import "./Toolbar.css";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import "./Toolbar.css";
import SignOutLinks from "./Links/SignOutLinks";
import DebaterLinks from "./Links/DebaterLinks";
import AdminLinks from "./Links/AdminLinks";
import ManagerLinks from "./Links/ManagerLinks";
import { NavLink } from "react-router-dom";

/**this is the main toolbar Component .
 it creates the toolbar at the top of the screen,
 the navigation buttons
 and call side drawer if screen is small

 the navigations buttons are different for Admin users and Studnet Users
 */

//  this function return the navigtion bar bottons acording to the corrent user Permissions
function getNavButtons(props) {
    if (props.permissions === "Admin") {
        return (
            <ul>
                <AdminLinks refreshToolbar={props.refreshToolbar}
                            currentLocation={props.currentLocation}/>
                <DebaterLinks {...props} currentLocation={props.currentLocation}/>
            </ul>
        );
    } else if (props.permissions === "Manager") {
        return (
            <ul>
                <ManagerLinks refreshToolbar={props.refreshToolbar}
                              currentLocation={props.currentLocation}/>
                <DebaterLinks {...props} currentLocation={props.currentLocation}/>
            </ul>
        );
    } else if (
        props.permissions === "Student" ||
        props.permissions === "Registration" ||
        props.permissions === "Pending"
    ) {
        return <DebaterLinks {...props} />;
    } else if (props.permissions === "Login") {
        //permissions === Login
        return <SignOutLinks />;
    }
}

// main toolbar Component
const toolbar = props => (
    <header className="toolbar">
        <nav className="toolbar__navigation">
            <div className="toolbar__toggle-button">
                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
            <div className="spacer__befor__logo" />
            <div className="toolbar__logo">
                <NavLink to="/home">
                    <img
                        alt="nav"
                        src={require("../../images/navbarlogo.png")}
                        style={{ marginTop: 10 }}
                    />
                </NavLink>
            </div>
            <div className="spacer" />
            <div className="toolbar_navigation-items">{getNavButtons(props)}</div>
        </nav>
    </header>
);

export default toolbar;
