import React, { Component } from "react";
import Post from "./Post";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { formatDateTime } from "../Utilities/DateTimeUtils";
import Divider from "@material-ui/core/Divider";

const getListStyle = () => ({
  maxHeight: "100%",
  position: "relative"
});

const getDividerTitleStyle = () => ({
  margin: `3px 0 5px 10px`
});
let is_first = true;
function get_divider(permissions) {
  if (permissions === "Admin" && !is_first) {
    return (
      <Divider component="li" style={{ marginTop: 25, marginBottom: 25 }} />
    );
  }
  is_first = false;
}

// generate posts stored in props.posts, and the Post.js component
class AllPost extends Component {
  render() {
    let postNum = 0;
    return (
      <div>
        <List style={getListStyle()}>
          {Object.values(this.props.posts).map(post => {
            postNum = postNum + 1;
            return (
              <div key={postNum}>
                {get_divider(this.props.permissions)}
                <Typography
                  style={getDividerTitleStyle()}
                  color="textSecondary"
                  display="block"
                  variant="subtitle2"
                  align="center"
                >
                  {formatDateTime(new Date(post.id))}
                </Typography>
                <Post
                  post={post}
                  text={post.text}
                  delete_a_post={this.props.delete_a_post}
                  permissions={this.props.permissions}
                />
              </div>
            );
          })}
        </List>
      </div>
    );
  }
}

export default AllPost;
