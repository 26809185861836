import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import {formatTime, tsToDate} from "../Utilities/DateTimeUtils";
import BlockIcon from '@material-ui/icons/Block';
import {checkInStarted} from "../EventManagementComps/EventTimelineStatus";

/**
 * A small clickable summary of an event in the user's homepage feeds
 * Includes basic information, life-cycle indication (colors, icons, badges)
 * Expands to the relevant action (registration, assignment view, feedback, etc.)
 */
class EventListItem extends Component {

    render() {
        const status = this.props.status;
        const event = this.props.event;
        const selected = this.props.selected;
        return (
            <ListItem
                key={event.id + (new Date().valueOf().toString) /*to make it unique...*/}
                button={status !== 'cancelled'}
                onClick={status !== 'cancelled' ? this.props.onSelectEvent:()=>{}}
                style={{
                    background: getEventItemBGColor(event, status),
                    border: selected ? '1px solid #aaa' : '1px solid #ccc',
                    borderRadius: 5,
                    boxShadow: selected ?'0px 3px 3px rgba(0, 0, 0.4, 0.4)' : null,
                    marginBottom: 10,
                    marginTop: 5,
                    width: '100%'
                }}>
                {status === "cancelled" && getCancelIcon()}
                {status !== "feedback" && status !== "cancelled" && status !== "new" && checkInStarted(event) &&
                <span style={{width: 40}} title={"CheckedIn"}>{getCheckInIcon(null, event.checkedIn)}</span>}
                {event && getEventHeader(event, status)}
                {status === "assigned" && event.event_type !== "Lesson" && getAssignedBadge()}
                {status === "new" && getNewEventBadge()}
                {status === "feedback" && getFeedbackCounter(this.props.feedback)}
            </ListItem>
        );
    }
}
export default EventListItem;

export function getEventItemBGColor(event, status) {
    let color;
    switch(status) {
        case "new":
            color = "#EEF1FF";
            break;
        case "edit":
            color = "#EEFFCA";
            break;
        case "assigned":
            color = "#EEFFCA";
            break;
        case "feedback":
            color ="#FFE39E";
            break;
        case "cancelled":
            color ="#FF7766";
            break;
        default:
            color = "default";
            break;
    }
    return color;
}

export function getCancelIcon() {
    return(
        <span style={{width: 40}} title={"Cancelled"}>
            <ListItemIcon><BlockIcon/></ListItemIcon>
        </span>
    );
}

export function getCheckInIcon(size=null, checkedIn=true) {
    return(
        <SvgIcon style={size ? {fontSize: size}:{}}>
            <path fill={checkedIn ? "#00BB00": "#C1E1C1"}
                  d="M12,2C15.86,2 19,5.14 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9C5,5.14 8.14,2 12,2M10.47,14L17,7.41L15.6,6L10.47,11.18L8.4,9.09L7,10.5L10.47,14Z" />
        </SvgIcon>
    );
}

export function getFeedbackCounter(feedback) {
    if(feedback && feedback.debaters) {
        const feedbacksLeftCount = Object.keys(feedback.debaters).length;
        return <span style={{color: "#666666", width: 50}}>({feedbacksLeftCount})</span>;
    }
    return null;
}

export function getEventHeader(event, status){
    let eventName = event.event_type;
    if(event.event_subject && event.event_subject.length > 0)
        eventName += (' ('+event.event_subject+')');
    return (
        <div style={{width:'100%', paddingTop: 10}}>
            <span style={{
                fontWeight: status==="new"?"bold":"normal",
                color: status==="cancelled"?"#994441":"000000"
            }}>
                  {`${formatTime(tsToDate(event.time))} - ${eventName}`}
            </span>
            <ListItemText secondary={
                status==="cancelled" ? "CANCELLED" : (event.location ? "Gathering at " + event.location : "No gathering location")
            }/>
        </div>
    );
}

const badgeStyle = {
    borderRadius: 5,
    padding: 5,
    fontSize: 12,
    backgroundColor: '#3733B3',
    color: "#FFFFFF",
    boxShadow:'0px 1px 2px rgba(0, 0, 0.4, 0.4)'
};

export function getAssignedBadge(){
    return <span style={badgeStyle}>ASSIGNED</span>
}

export function getNewEventBadge(){
    return <span style={badgeStyle}>NEW</span>
}
