import React from 'react';
import AddPeople from "@material-ui/icons/GroupAdd";
import Badge from "@material-ui/core/Badge";
import {get_all_waiting_debaters} from "../../../Backend/BackendFunctions";

export class WaitingUsersIcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            waitingUsersCount: 0
        };
        this.refresh = this.refresh.bind(this);
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.refresh();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh(){
        get_all_waiting_debaters(100, null)
            .then(res => this.setState({waitingUsersCount: Object.keys(res).length}))
            .catch(error => console.log(error));
    }

    render(){
        return(
            <Badge badgeContent={this.state.waitingUsersCount}
                   max={99}
                   color="secondary">
                <AddPeople style={this.props.style}/>
            </Badge>
        );
    }
}
