import React, { Component } from 'react';
import {get_event_by_id,load_assignment} from "../../Backend/BackendFunctions";
import Paper from "@material-ui/core/Paper";
import {tsToDate,formatDateTime} from "../Utilities/DateTimeUtils";
import {getCurrentLocation} from "../Utilities/OtherUtils";
import ProjectionRoom from '../projection/projectionRoom';



const getPaperStyle = () => ({
    padding: 20,
    paddingBottom: 160,
    width: "95%",
    marginTop: 40,

    margin: "0 auto",
    overflow: "hidden"
});

/**
 * For live projection of an assignment during the event
 */
class ProjectionPage extends Component {
    state = {
                rooms: [],
                eventId : undefined,
                event : {},
            };

    componentDidMount = () =>{
        this.props.onLoad();
        const eventId = getCurrentLocation();
        get_event_by_id(eventId)
            .then(event => {
                event = {...event, id: eventId};
                event.date = tsToDate(event.date);
                event.time = tsToDate(event.time);
                load_assignment(event.id)
                    .then(assignment => {
                        let rooms=[];
                        if (!(Object.keys(assignment).length === 0)){
                            rooms = assignment.rooms;
                        }
                        this.setState({rooms, eventId, event})
                    })
            })
    };

    generateRoomList=()=>{
        const rooms = this.state.rooms;
        const numOfRooms = rooms.length;
        let i = 0;
        let result = [];
        while(i<numOfRooms){
            let item;
            if(i+1 === numOfRooms){
                item = (<div style={{display : 'flex', flexDirection:'row'}}>
                            <ProjectionRoom room={rooms[i]}/>
                        </div>)

            } else {
            item =(
                <div style={{display : 'flex', flexDirection:'row'}}>
                    <ProjectionRoom room={rooms[i]}/>
                    <ProjectionRoom room={rooms[i+1]}/>
                </div>
            )

            }
            result.push(item)
            i= i+2;
        }
        return result;
    };
    render() {
        return (
            <Paper style={getPaperStyle()} elevation={1}>
                {this.state.event.date &&
                this.state.event.time && (
                <h1 style={{ display: "inline-block", margin: 5 }}>
                    Assignment For{" "}
                    <b>
                    {formatDateTime(this.state.event.time)} -{" "}
                    {this.state.event.event_type}
                    </b>
                </h1>
                )}

                {this.generateRoomList()}
            </Paper>
         );
    }
}

export default ProjectionPage;
