import React, { Component } from "react"
import FeedbackPopper from "../FeedbackComps/FeedbackPopper";
import onClickOutside from "react-onclickoutside";

/**
 * Compact popper for displaying many feedbacks in user management page
 */
class FeedbackWithPopper extends Component {

  constructor(props) {
      super(props);
      this.state = {
          openPopper: false,
          anchorEl: null
      };
      this.toggleFeedbackPopper = this.toggleFeedbackPopper.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleFeedbackPopper(event) {
      const { currentTarget } = event;
      const isOpen = this.state.openPopper;
      this.setState({
          anchorEl: currentTarget,
          openPopper: !isOpen
      });
  }

  handleClickOutside(event){
      this.setState({openPopper: false});
  }

  render(){
      return (
          <div>
              <span style={{
                        color: '#6688EE',
                        cursor: 'pointer',
                        padding: 3,
                        fontWeight: this.state.openPopper ? 'bold': 'normal'
                    }}
                    title="Open Feedback"
                    onClick={(e)=>this.toggleFeedbackPopper(e)}>
                  {this.props.chairName}
              </span>
              {this.state.openPopper &&
              <FeedbackPopper
                  feedback={this.props.feedback}
                  anchorEl={this.state.anchorEl}
              />}
          </div>
      );
  }
}


export default (onClickOutside(FeedbackWithPopper));
