import {toast} from "react-toastify";

/**
 * @param error
 * @param message
 */
export function handleError(error, message="An Error Occurred"){
    console.log(error);
    toast.error(message);
}

/**
 * @returns {boolean}
 */
export function isMobile() {
    return window.innerWidth < 768;
}

/**
 * @returns {string}
 */
export function getCurrentLocation(){
    const urlArr = window.location.href.split("/");
    return urlArr[urlArr.length - 1].split("#")[0];
}

/**
 * @param pageRoute
 * @returns {boolean}
 */
export function pageLoaded(pageRoute){
    return getCurrentLocation() === pageRoute;
}

export function removeDuplicates(arr) {
    const unique = arr
        .map(e => e.valueOf())
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);
    return unique;
}
