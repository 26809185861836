import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { DebateHistory } from "../components/UserComponents/DebateHistory";
import ApprovalPage from "./ApprovalPage";
import {
    getAvatar,
    getAvatarEditable,
    getFullName,
    getInitialFullName,
    isApprovedUser, isManager,
    isPendingApproval,
    isSystemAdmin
} from "../components/Utilities/DebaterUtilities";
import { UserDetailsForm } from "../components/UserComponents/UserDetailsPageComponents/UserDetailsForm";
import {handleError} from "../components/Utilities/OtherUtils";
import {confirmAlert} from "react-confirm-alert";
import {ConfirmAlertBody} from "../components/Common/ConfirmAlert";
import {ProfilePictureEditDialog} from "../components/UserComponents/UserDetailsPageComponents/ProfilePictureEditDialog";
import {delete_user} from "../Backend/userBackendFunctions";
import CircularProgress from "@material-ui/core/CircularProgress";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

/**
 * User's profile page - to be used in three different contexts:
 * 1) First sign-up (semi-empty form only in edit_mode)
 * 2) "My Profile" page in the toolbar, allowing users to view and edit their own details
 * 3) Other user's profile - for admins to view and edit other users' information.
 *    In this context another tab of "Debate History" will be displayed
 */
export class UserDetails extends Component {
    systemUser;
    debater;

    constructor(props) {
        super(props);
        this.systemUser = props.user;
        if (props.debater) this.debater = props.debater;
        else if (localStorage.debater && isManager(props.user)) this.debater = JSON.parse(localStorage.debater);
        else this.debater = props.user;

        this.state = {
            tabValue: 0,
            edit_mode: !this.systemUser || !this.debater || this.systemUser.uid === this.debater.uid || props.editMode,
            editProfilePicture: false,
            showApprovalWaitingPage: isPendingApproval(this.systemUser),
            confirmDelete: false,
            wereChanges: false,
            deleted: false,
            loading: false
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.closeProfilePictureEditor = this.closeProfilePictureEditor.bind(this);
        this.profilePictureError = this.profilePictureError.bind(this);
        this.setEditMode = this.setEditMode.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        if(this.props.onLoad) this.props.onLoad();
    }

    handleTabChange() {
        const currentTab = this.state.tabValue;
        if (currentTab === 0 && this.state.wereChanges) {
            toast.error("Please submit or cancel your changes first");
        } else {
            this.setState({tabValue: 1 - currentTab});
        }
    }

    closeProfilePictureEditor() {
        this.setState({editProfilePicture: false});
    }

    profilePictureError() {
        this.closeProfilePictureEditor();
    }

    updateProfilePicture(picture) {
        this.debater.picture = picture;
        this.setState({editProfilePicture: false});
    }

    setEditMode(bool) {
        this.setState({edit_mode: bool});
    }

    deleteConfirmation = (onClose) =>
        <ConfirmAlertBody
            variant={"warning"}
            onClose={onClose}
            style={this.props.isDialog ? {textAlign: 'left', boxShadow: 'none'} : null}
            message={<span>Are you sure you want to <b>permanently</b> delete {getFullName(this.debater) + '?'}</span>}
            onSubmit={()=>this.handleDelete()}/>;

    confirmDelete() {
        if(this.props.isDialog) {
            this.setState({confirmDelete: true});
            return;
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return this.deleteConfirmation(onClose);
            }
        });
    }

    handleDelete(){
        this.setState({loading: true});
        delete_user(this.debater.uid)
            .then(()=> {
                toast.info("Deleted User Successfully");
                if(this.props.onDeleteUser) this.props.onDeleteUser();
                else if(this.props.isDialog) this.props.onClose();
                this.setState({deleted: true, edit_mode: false, loading: false});
            }).catch(e=>handleError(e));
    }

    render() {
        if (this.state.showApprovalWaitingPage) {
            return <ApprovalPage user={this.systemUser} />;
        }
        return (
            <div className="app-container">
                <ProfilePictureEditDialog
                    open={this.state.editProfilePicture}
                    picture={this.debater.picture}
                    onClose={this.closeProfilePictureEditor}
                    onChange={picture => this.updateProfilePicture(picture)}
                    onError={() => this.profilePictureError()}
                    userId={this.debater.uid}/>

                <div>
                    <Paper
                        style={{
                            textAlign: "center",
                            width: "95%",
                            alignItems: "center",
                            justifyContent: "center",
                            overflowX: 'none',
                            overflowY: this.props.isDialog || this.state.tabValue === 0 ? 'none' : 'scroll',
                            margin: this.props.isDialog ? "1%" : "2%",
                            paddingTop: "0%"
                        }}
                        elevation={this.props.isDialog ? 0 : 10}
                    >
                        {isSystemAdmin(this.systemUser) && isApprovedUser(this.debater) && (
                            <AppBar
                                position="static"
                                color="inherit"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    boxShadow: this.props.isDialog ? "none" : "auto",
                                    borderBottom: this.props.isDialog
                                        ? "1px solid #ccc"
                                        : "none"
                                }}>
                                <Tabs
                                    value={this.state.tabValue}
                                    onChange={() => this.handleTabChange()}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered>
                                    <Tab label="Profile" />
                                    <Tab label="Debate History" />
                                </Tabs>
                            </AppBar>
                        )}
                        {!isSystemAdmin(this.systemUser) && <br />}

                        <div style={{ marginTop: "5%", padding: 0, marginBottom: 0}}>
                            {this.state.edit_mode && this.state.tabValue === 0
                                ? getAvatarEditable(this.debater, 75, this)
                                : getAvatar(this.debater, 75)}

                            <h2 align="left"
                                style={{display: "inline-block", marginLeft: 15}}>
                                {isApprovedUser(this.debater) && getFullName(this.debater)}
                                {!isApprovedUser(this.debater) &&
                                getInitialFullName(this.debater)}
                            </h2>
                        </div>

                        {this.state.tabValue === 0 && (
                            <TabContainer style={{margin: 0, padding: 0}}>
                                {!(this.state.confirmDelete && this.props.isDialog) &&
                                (this.state.loading ? <div align={"center"}><CircularProgress/></div> :
                                <UserDetailsForm
                                    debater={this.debater}
                                    systemUser={this.systemUser}
                                    onClose={this.props.onClose}
                                    editMode={this.state.edit_mode}
                                    onChanges={(wereChanges)=>this.setState({wereChanges: wereChanges})}
                                    onDelete={()=>this.confirmDelete()}
                                    onToggleEditMode={(edit_mode)=>this.setEditMode(edit_mode)}
                                />)}
                                {// To avoid dialog over dialog - if we're in dialog just show the warning here
                                    this.state.confirmDelete && this.props.isDialog &&
                                    <p><br/>
                                        <hr/>
                                        {this.deleteConfirmation(()=>this.setState({confirmDelete: false}))}
                                        <hr/>
                                    </p>
                                }
                            </TabContainer>
                        )}
                        {this.state.tabValue === 1 && (
                            <TabContainer>
                                <DebateHistory debater={this.debater}>
                                    Debate History
                                </DebateHistory>
                            </TabContainer>
                        )}
                    </Paper>
                </div>
            </div>
        );
    }
}
