import React, { Component } from "react";
import { CloseButton } from "./Buttons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { EmailComposer } from "./EmailComposer";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Dialog version of EmailComposer component
 */
export class EmailComposerDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.emailDebater !== null}
                fullWidth={true}
                maxWidth="sm"
                onClose={this.props.onClose}>
                <DialogTitle>
                    <CloseButton onClick={this.props.onClose} />
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    {this.props.loading ?
                        <div align={"center"}><CircularProgress/></div> :
                        <EmailComposer
                            debaters={this.props.debaters}
                            onClose={this.props.onClose}
                            onSubmit={() => {
                                toast.info("Email sent successfully");
                                this.props.onClose();
                            }}
                            debater={this.props.emailDebater}
                        />}
                </DialogContent>
            </Dialog>
        );
    }
}
