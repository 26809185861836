import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Nadav from "../images/Nadav.jpg";
import Inbar from "../images/Inbar.jpg";
import Rinat from "../images/Rinat.jpg";
import Amichay from "../images/Amichay.jpg";

/**
 * General information about the system and it's authors
 */
export default class AboutUs extends Component {
  //export default function AboutUs() {
  componentDidMount = () => {
    this.props.onLoad();
  };
  render() {
    return (
      <div>
        <div
          className="marginTop"
          style={{
            marginTop: 70,
            marginRight: "auto",
            marginLeft: "auto",
            maxWidth: 700,
            align: "center"
          }}
        >
          <div className="container">
            <Paper
              id="content"
              style={{
                background: "#f9f9f9"
              }}
            >
              <div style={{ align: "center", textAlign: "center" }}>
                <br />
                <Typography variant="h4">
                  This website was developed in 2019 by:
                </Typography>
              </div>

              <form>
                <div className="App__Form" style={{ padding: "5%" }}>
                  <div className="image-cropper">
                    <img src={Inbar} alt="Inbar" className="profile-pic" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <Typography variant="h5">
                      Inbar "React Wizard" Assaf
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      component="p"
                    >
                      Visit Inbar's{" "}
                      <a href="https://www.linkedin.com/in/inbar-assaf-00bb54175/">
                        LinkedIn
                      </a>
                    </Typography>
                  </div>
                </div>
              </form>

              <form>
                <div className="App__Form" style={{ padding: "5%" }}>
                  <div className="image-cropper">
                    <img src={Nadav} alt="Nadav" className="profile-pic" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <Typography variant="h5">
                      Nadav "Code Machine" Benyamini
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      component="p"
                    >
                      Visit Nadav's{" "}
                      <a href="https://www.linkedin.com/in/nadav-benyamini-990941118/">
                        LinkedIn
                      </a>
                    </Typography>
                  </div>
                </div>
              </form>

              <form>
                <div className="App__Form" style={{ padding: "5%" }}>
                  <div className="image-cropper">
                    <img src={Amichay} alt="Amichay" className="profile-pic" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <Typography variant="h5">
                      Amichay "Debate" Even-Chen{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      component="p"
                    >
                      Visit Amichay's{" "}
                      <a href="https://www.linkedin.com/in/amichayevenchen/">
                        LinkedIn
                      </a>
                    </Typography>
                  </div>
                </div>
              </form>

              <form>
                <div className="App__Form" style={{ padding: "5%" }}>
                  <div className="image-cropper">
                    <img src={Rinat} alt="Rinat" className="profile-pic" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <Typography variant="h5">
                      Rinat "The Doctor" Sikunda
                    </Typography>
                  </div>
                </div>
              </form>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}
