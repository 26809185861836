import React, { Component } from "react"
import {get_registrations_for_event} from "../../Backend/BackendFunctions";
import {formatDate, tsToDate} from "../Utilities/DateTimeUtils";
import {getFullName} from "../Utilities/DebaterUtilities";
import {handleError} from "../Utilities/OtherUtils";
import {EmailComposerDialog} from "../Common/EmailComposerDialog";
import {ToggleButton} from "../Common/ToggleButton";

/**
 * A dialog for sending emails to all, or some, of the debaters registered to events on a givent date
 */
export class EmailRegisteredDebaters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            registeredDebaters: {},
            debaters: {},
            studentsFilter: 'both',
            rankFilter: 'both'
        };
        this.getRecipientsRec = this.getRecipientsRec.bind(this);
        this.filterDebaters = this.filterDebaters.bind(this);
        this.toggleRank = this.toggleRank.bind(this);
        this.toggleStudents = this.toggleStudents.bind(this);
    }

    componentDidMount() {
        const recipients = {};
        this.getRecipientsRec(recipients, 0);
    }

    getRecipientsRec(recipients, index){
        if(index >= this.props.eventsArr.length) {
            this.setState({
                loading: false,
                debaters: recipients,
                registeredDebaters: recipients
            });
        }
        else {
            get_registrations_for_event(this.props.eventsArr[index].id)
                .then(registrations => {
                    for (const id in registrations) {
                        if(!this.props.allDebaters[id] || !getFullName(this.props.allDebaters[id])) continue; // For fake registrations;
                        if(!registrations[id]['cancelled'] && !registrations[id]['cancelledOnTime']) {
                            recipients[id] = this.props.allDebaters[id];
                        }
                    }
                    this.getRecipientsRec(recipients, index + 1);
                }).catch(e => handleError(e))
        }
    }

    filterDebaters() {
        const debaters = {};
        for(const id in this.state.registeredDebaters) {
            const debater = this.state.registeredDebaters[id];
            if(!debater || !debater.rank) continue;
            if(this.state.rankFilter !== "both" && debater.rank.toLowerCase() !== this.state.rankFilter.toLowerCase()) continue;
            if(this.state.studentsFilter !== "both" &&
               debater.student !== (this.state.studentsFilter.toLowerCase() === "students")) continue;
            debaters[id] = debater;
        }
        this.setState({debaters: debaters});
    }

    toggleRank(rankFilter) {
        this.setState({rankFilter: rankFilter}, ()=> this.filterDebaters());
    }

    toggleStudents(studentsFilter) {
        this.setState({studentsFilter: studentsFilter}, ()=> this.filterDebaters());
    }

    render(){
        const title =
            <div>
                <h3>
                    Email Debaters Registered to {formatDate(tsToDate(this.props.eventsArr[0].date))}
                </h3>
                <span style={{width: '45%', marginRight: '2.5%'}}>
                <ToggleButton values={['junior', 'senior', 'both']}
                              labels={['Junior', 'Senior', 'Both']}
                              default={this.state.rankFilter}
                              onChange={(value)=>this.toggleRank(value)}/>
                </span>
                <span style={{width: '45%', marginLeft: '2.5%'}}>
                <ToggleButton values={['students', 'non_students', 'both']}
                              labels={['Students', 'Non Students', 'Both']}
                              default={this.state.studentsFilter}
                              onChange={(value)=>this.toggleStudents(value)}/>
                </span>
          </div>;
        return (
            <EmailComposerDialog
                loading={this.state.loading}
                debaters={this.state.debaters}
                title={this.state.loading ? 'Loading...' : title}
                onClose={this.props.onClose}
            />
        );
    }
}

