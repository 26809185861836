import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {formatTime} from "../../Utilities/DateTimeUtils";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import IconButton from "@material-ui/core/IconButton";
import React, {Component} from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import ConfirmIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Clear";
import {SingleEventForm} from "./SingleEventForm";

/**
 * Allows editing and *carefully* deleting a specific event
 */
export class SingleEventItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleted: false,
            expanded: false,
            showDeleteConfirmation: false
        };

        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    toggleExpanded() {
        const expanded = this.state.expanded;
        this.setState({expanded: !expanded})
    }

    render() {
        if(this.state.deleted) return <span></span>;
        const event = this.props.event;
        return (
            <table style={{borderSpacing: 0, width: "100%", margin: 0}}>
                <tbody>
                <tr>
                    <td style={{borderBottom: '1pt solid #ccc', padding: 0, margin: 0}}>
                        <ExpansionPanel disabled={event.delete_me}
                                        expanded={this.state.expanded}
                                        onChange={() => this.toggleExpanded()}
                                        style={{margin: 0, width: '100%', display: 'inline', padding: 0}}>
                            <ExpansionPanelSummary
                                expandIcon={!event.delete_me && <ExpandMoreIcon/>}
                                style={{
                                    textDecoration: event.delete_me ? 'line-through' : '',
                                    width: 480, margin: 0, padding: 0
                                }}>
                                <label style={{marginRight: 10, marginLeft: 0, fontSize: 14}}>
                                    {formatTime(event.time)}
                                </label>
                                <span>{event.event_type}</span>
                                {(!event.id && this.props.eventsArr) &&
                                <span style={{marginLeft: 5, color: '#FF0000', fontSize: 10}}>*NEW*</span>
                                }
                            </ExpansionPanelSummary>
                            {!event.delete_me && !this.state.showDeleteConfirmation &&
                            <ExpansionPanelDetails style={{margin: 0, width: '100%', display: 'inline', padding: 0}}>
                                <SingleEventForm  event={event}
                                                  onFilterChange={(filter)=>this.props.onFilterChange(filter)}
                                                  onFieldChange={(value, field) => this.props.onFieldChange(value, field)}/>
                            </ExpansionPanelDetails>
                            }
                        </ExpansionPanel>
                    </td>
                    <td style={{borderBottom: '1pt solid #ccc', verticalAlign: 'top'}}>
                        {!event.delete_me &&
                        <IconButton
                            disabled={this.state.showDeleteConfirmation}
                            title="Delete Event"
                            onClick={() => {
                                // New event, just remove from array. Database event - show confirmation
                                if (!event.id) {
                                    this.setState({deleted: true}, ()=>this.props.onDeleteEvent());
                                } else {
                                    this.setState({showDeleteConfirmation: true, expanded: false})
                                }
                            }}>
                            <DeleteIcon/>
                        </IconButton>}

                        {event.delete_me &&
                        <IconButton
                            title="Restore Event"
                            onClick={() => this.setState({showDeleteConfirmation: false},()=>this.props.onRestoreEvent())}>
                            <UndoIcon/>
                        </IconButton>}

                    </td>
                </tr>
                {this.state.showDeleteConfirmation &&
                <tr>
                    <td colSpan="2" style={{textAlign: "right"}}>
                        <label>Are you sure you want to delete this event?</label>
                        <IconButton
                            title="Cancel"
                            onClick={() => this.setState({showDeleteConfirmation: false})}>
                            <CancelIcon style={{color: "#AA0000"}}/>
                        </IconButton>
                        <IconButton
                            title="Delete"
                            onClick={() =>  this.setState({showDeleteConfirmation: false},()=> this.props.onDeleteEvent())}>
                            <ConfirmIcon style={{color: "#00AA00"}}/>
                        </IconButton>
                        <br/>
                    </td>
                </tr>}
                </tbody>
            </table>
        );
    }
}
