import {tsToDate, truncateDate} from "../components/Utilities/DateTimeUtils";

export function is_user_checked_in(event) {
    return event.registration.checkedIn || false; // Must be boolean, not undefined
}

export function get_event_user_status(event) {
    const eventTime = new Date(tsToDate(event.time));
    const deadline = new Date(tsToDate(event.registration_end));

    const userHasRegistered = Object.keys(event.registration).length > 0 && !event.registration.cancelledOnTime;
    const userHasCancelled = Object.keys(event.registration).length > 0 && event.registration.cancelled;
    const registrationDeadlineHasPassed = (new Date()).getTime() > deadline.getTime();

    /* user is ineligible testing */
    if (registrationDeadlineHasPassed && !userHasRegistered) return null;

    if (eventTime.getTime() < truncateDate(new Date()).getTime()) return null;
    if (!userHasRegistered) return "new";
    if (userHasCancelled) return "cancelled";
    if (!event.assignment) return "edit";
    return "assigned";
}

export function get_relevant_events_for_user(events) {
    const relevantEvents = {};
    for (const eventId in events) {
        const event = events[eventId];
        event.status = get_event_user_status(event);
        event.checkedIn = is_user_checked_in(event);
        if(event.status) relevantEvents[eventId] = event;
    }
    return relevantEvents;
}
