import React, { Component } from "react"
import {FeedbackDetails, getScoreBubble} from "../FeedbackComps/FeedbackDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import {addDays, formatDateTime, tsToDate} from "../Utilities/DateTimeUtils";
import {handleError} from "../Utilities/OtherUtils";
import {get_events} from "../../Backend/BackendFunctions";
import {eventStarted} from "../EventManagementComps/EventTimelineStatus";
import {get_debate_history} from "../../Backend/debateHistoryBackendFunctions";

/**
 * Show's the debater's average score and total events count along
 * with a list of events with feedbacks or cancellation indication
 */
export class DebateHistory extends Component {

  constructor(props) {
      super(props);

      this.state = {
          history: null,
          allEvents: null,
          loading: false,
          refreshHistory: false
      };
  }

  componentDidMount(){
      this.setState({loading: true});
      const firstDate = addDays(new Date(), -60);
      const lastDate = addDays(new Date(), 30);
      get_events(firstDate, lastDate, 1000, null)
          .then(events => {
              get_debate_history()
                  .then(fullHistory => {
                          if(!fullHistory[this.props.debater.uid]) {
                              this.setState({history: {}, loading: false, allEvents: events});
                          } else {
                              this.setState({history: fullHistory[this.props.debater.uid], loading: false, allEvents: events});
                          }
                  }).catch(error => handleError(error));
          }).catch(error => handleError(error));
  }

  render(){
      if (!this.props.debater) {
          return null;
      }

      if (this.state.loading || !this.state.allEvents) {
          return <div align="center"><CircularProgress /></div>;
      }

      const sortedEventIds = Object.keys(this.state.allEvents);
      sortedEventIds.sort((id1, id2) => this.state.allEvents[id1].time >= this.state.allEvents[id2].time ? -1:1);

      return (
          <div style={{marginTop: 0, fontSize: 14, lineHeight: 2, marginBottom: 0,
                        maxHeight: this.props.maxHeight ? this.props.maxHeight: 1000,
                        overflowY:this.props.maxHeight?'scroll':'none'}}>
              <div align={"left"} style={{fontSize: 16, marginTop: 0, padding: 0}}>
                  <label>Total Events: </label><span>{this.state.history.event_count}</span>
                  {this.props.debater.rank==="Junior" &&
                  <span><label style={{marginLeft: 8}}>Average Score: </label>{getScoreBubble(this.state.history.average_score)}</span>}
                  <hr/>
              </div>
              {sortedEventIds.map(eventId => {
                  if(!this.state.history['events']) return null;
                  const eventData = this.state.history['events'][eventId];
                  const event = this.state.allEvents[eventId];
                  const feedback = {};
                  let status = "";
                  if(!eventData || !eventStarted(event) || event.event_type === 'Lesson') return null;
                  else if(eventData === 'cancelled') status = "CANCELLED";
                  else if(!eventData['position']) return null; //status = "Wasn't Assigned";
                  else if(!eventData['score'] || eventData['score'] === 0) status = "No Feedback";
                  else {
                      feedback['score'] = eventData['score'];
                      feedback['positive_feedback'] = eventData['positive_feedback'];
                      feedback['negative_feedback'] = eventData['negative_feedback'];
                      feedback['notes'] = eventData['notes'];
                      feedback['chairName'] = eventData['chair_name'];
                      status = "";
                  }

                  const light={color: '#777777', marginBottom: 10};
                  return (
                      <div align="left" key={eventId}>

                          <h4 style={{textAlign: 'left', marginTop: 0, marginBottom: 5, marginRight: 10}}>
                              {formatDateTime(tsToDate(event.time)) +", "+ event.event_type}
                          <span style={{
                              display:'inline', margin: 0, marginLeft: 10,
                              color: status==="CANCELLED" ? "#CC0000": "#999999",
                              fontWeight: status==="CANCELLED" ? "bold": "normal",
                              textAlign: 'left'}}>{status}</span>
                          </h4>

                          {status === ""  && <FeedbackDetails feedback={feedback} />}

                          {status === "No Feedback" &&
                                <div style={{display:'inline', margin: 0}}>
                                    <div>Chair: <span style={light}>{eventData['chair_name']}</span></div>
                                    <div>Position: <span style={light}>{eventData['position']}</span></div>
                                </div>
                          }

                          <hr />
                      </div>)
              })}
          </div>
          )
      }
}
