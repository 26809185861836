import React from "react";
import {upload_img} from "../../../Backend/BackendFunctions";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CancelButton, SubmitButton} from "../../Common/Buttons";

/**
 * Simple component prompting for profile picture input with some basic validations
 */
export class ProfilePictureEdit extends React.Component {

    maxImageSize = 1024 * 1024; // 1 MB

    constructor(props){
        super(props);
        this.state = {
            pictureURL: props.picture,
            pictureFile: null,
            loading: false
        };
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleUpload(e) {
        const file = e.target.files[0];
        if(file.size > this.maxImageSize) {
            toast.error("Invalid Image: Max image size is " + (this.maxImageSize/1024/1024).toString() + 'MB')
        } else {
            this.setState({pictureURL: URL.createObjectURL(file), pictureFile: file});
        }
    }

    handleSubmit() {
        this.setState({loading: true});
        const file = this.state.pictureFile;
        upload_img(file, this.props.picture, this.props.userId)
            .then(url => {
                toast.info("Successfully Updated Profile Picture");
                this.props.onChange(url);
                this.setState({loading: false});
            })
            .catch(error => {
                toast.error("An error occurred while updating profile picture, please try again later.");
                console.log(error);
                this.props.onError()
            });
    }

    handleClose() {
        this.props.onClose();
    }

    render(){
        return (
            <div align="center">

                <input type="file"
                       disabled={this.state.loading}
                       onChange={e => this.handleUpload(e)} />
                <br /><br />

                <img src={this.state.pictureURL}
                     alt={''}
                     style={{maxWidth: 300, maxHeight: 300}}/>
                <br /><br />

                <CancelButton onClick={()=>this.handleClose()} label={"Close"} disabled={this.state.loading}/>
                <SubmitButton onClick={()=>this.handleSubmit()} label={"Submit"} disabled={this.state.loading}/>
                {this.state.loading && <div><CircularProgress/></div>}
            </div>
        );
    }
}
