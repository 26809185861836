import firebase from "./fire";
import { db } from "./fire";

/* this page contains all backend functions regarding announcements
announcements are saved under "Announcements/All_Announcements"
every announcements has it's permissions so only part of the users will see it in thier homepage
admins see all the announcements at the announcements page and can create new ones
*/

/* this function returns all the post a user should see acording to the announcement permission
 */
export function get_all_posts(filter, user) {
  let p = new Promise((resolve, reject) => {
    db.collection("Announcements")
      .doc("All_Announcements")
      .get()
      .then(posts => {
        posts = posts.data();
        if (filter) {
          posts = return_relevant_posts(posts, user);
        }
        resolve(posts);
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* returns all the posts rellevent to currnet user */
function return_relevant_posts(posts, user) {
  let result = {};
  for (let key in posts) {
    if (!user.student) {
      if (!posts[key].settings.forNonStudents) {
        continue;
      }
    }
    if (user.club !== "TAU") {
      if (!posts[key].settings.forOthers) {
        continue;
      }
    }
    if (user.rank === "Junior") {
      if (posts[key].settings.forJuniors) {
        result = { ...result, [key]: posts[key] };
        continue;
      }
    }
    if (user.rank === "Senior") {
      if (posts[key].settings.forSeniors) {
        result = { ...result, key: posts[key] };
        continue;
      }
    }
  }
  return result;
}

/* add a post to Announcements/All_Announcements */
export function post_post(post) {
  let p = new Promise((resolve, reject) => {
    db.collection("Announcements")
      .doc("All_Announcements")
      .update({ [post.id]: { ...post, id: post.id } })
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* delete a post from Announcements/All_Announcements */
export function delete_post(postID) {
  let p = new Promise((resolve, reject) => {
    db.collection("Announcements")
      .doc("All_Announcements")
      .update({ [postID]: firebase.firestore.FieldValue.delete() })
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}
