import React, { Component } from "react";
import { titleCase } from "../../Utilities/StringUtils";
import Button from "@material-ui/core/Button";
import { CloseButton } from "../../Common/Buttons";

/**
 * Responsible for loading hardcoded event templates
 */
export class NewEventsLoadTemplate extends Component {

    template_names = [
        'tuesday_with_lesson',
        'tuesday_no_lesson',
        'friday',
        'custom'
    ];

    render() {
        const buttonStyle = { width: 240, margin: 5 };
        return (
            <div style={{ margin: 0, padding: 0, display: 'inline' }}>
                <CloseButton onClick={() => this.props.onExit()} />
                <h2 style={{ display: 'inline', margin: 0, padding: 0 }}>Choose Events Template</h2>
                <br /><br />


                <div align="center">
                    {this.template_names.map(template => {
                        return (
                            <Button variant="contained"
                                key={template}
                                color={template === "custom" ? "default" : "primary"}
                                style={buttonStyle}
                                onClick={() => this.props.onSelect(template)}>
                                {titleCase(template)}
                            </Button>);
                    })}
                </div>
            </div>
        );
    }


}
