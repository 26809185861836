import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import EventList from "./EventList";
import EventForm from "./EventForm";
import {FormGroup} from "@material-ui/core";
import {FeedbackFormMenu} from "./FeedbackFormMenu";
import AllPost from "../Announcements/AllPost";
import HomepageTabs from "./HomepageTabs";


export class HomePageBody extends Component {
    constructor(props) {
        super(props);
        this.state = {tab: "events"};
        this.getTabBodyStyle = this.getTabBodyStyle.bind(this);
        this.newEventsCount = this.newEventsCount.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    handleChangeTab(event, newValue) {
        this.setState({tab: newValue});
    }

    getTabBodyStyle() {
        if(this.props.mobile) return {
            width: "95%",
            margin: "0px auto",
            position: "relative"
        };
        return {width: "48%"}
    }

    newEventsCount() {
        let counter = 0;
        for(const eventId in this.props.events) {
            if(this.props.events[eventId].status === 'new') counter++;
        }
        return counter;
    }

    render() {
        return (
            <div align="center">
                <div align="left">
                    <HomepageTabs
                        mobile={this.props.mobile}
                        tab={this.state.tab}
                        handleChangeTab={this.handleChangeTab}
                        feedbackCount={this.props.feedbacks ? Object.keys(this.props.feedbacks).length : 0}
                        newEventsCount={this.newEventsCount()}
                    />
                </div>

                <Paper style={{padding: "2%", borderTopLeftRadius:'0%'}}>
                    {this.state.tab === "events" &&
                        (Object.keys(this.props.events).length > 0 ?
                        <FormGroup row>
                            <div style={this.getTabBodyStyle()}>
                                <EventList {...this.props} tab={"event"}/>
                            </div>
                            {!this.props.mobile &&
                            <div style={{width: "48%", marginLeft: '3%', display: this.props.event ? 'block':'none'}}>
                                <br/><EventForm {...this.props}/>
                            </div>}
                        </FormGroup>
                        :
                        <h3>No upcoming events</h3>)
                    }

                    {this.state.tab === "feedbacks" &&
                        (this.props.feedbacks && Object.keys(this.props.feedbacks).length > 0 ?
                        <FormGroup row>
                            <div style={this.getTabBodyStyle()}>
                                <EventList {...this.props} tab={"feedback"}/>
                            </div>
                            {!this.props.mobile &&
                            <div style={{width: "48%", marginLeft: '3%', display: this.props.feedback ? 'block':'none'}}>
                                <br/><FeedbackFormMenu {...this.props}/>
                            </div>}
                        </FormGroup>
                            :
                        <div>
                            <h2 align={"left"}>Good Job!</h2>
                            <h3 align={"left"}>You finished filling all your feedbacks</h3>
                        </div>)
                    }
                    {this.state.tab === "announcements" && (
                        <AllPost permissions={"Student"}
                                 posts={this.props.posts} />
                    )}
                </Paper>
                <br/><br/><br/>
            </div>
        );
    }
}
