import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import ReactQuill from "react-quill";
import Checkbox from "@material-ui/core/Checkbox";
import {DeleteButton} from "../Common/Buttons";

const fieldNameStyle = {
  fontWeight: "bold",
  fontSize: 14
};

function getControlButtons(permissions, props) {
  if (permissions === "Admin") {
    return (
      <div align={"left"}>
        <br/>
        <label style={{marginRight: 10}}>Seen by:</label>
        <Checkbox checked={props.post.settings.forJuniors} disabled={true} />
        <label style={fieldNameStyle}>Juniors </label>

        <Checkbox checked={props.post.settings.forSeniors} disabled={true} />
        <label style={fieldNameStyle}>Seniors</label>

        <Checkbox checked={props.post.settings.forOthers} disabled={true} />
        <label style={fieldNameStyle}>Other Clubs </label>

        <Checkbox checked={props.post.settings.forNonStudents} disabled={true}/>
        <label style={fieldNameStyle}>Non Students </label>

        <div align={"center"}>
            <br/>
            <DeleteButton onClick={() => props.delete_a_post(props.post.id)}/>
        </div>
      </div>
    );
  }
}

class Post extends Component {
  render() {
    return (
      <div align="center">
        <Paper style={{ margin: "2% 4%", padding: "2%", maxWidth: 1000}}>
          <ReactQuill
            readOnly={true}
            value={this.props.text}
            modules={{ toolbar: false }}
            bounds={".paperwrap"}/>
          {getControlButtons(this.props.permissions, this.props)}
        </Paper>
      </div>
    );
  }
}
export default Post;
