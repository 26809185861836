import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {TimePicker} from "material-ui-pickers";
import Checkbox from "@material-ui/core/Checkbox";
import React, {Component} from "react";

const fieldNameStyle = {
    marginLeft: 2,
    marginRight: 5,
    fontWeight: 'bold',
    fontSize: 14
};

/**
 * Manages event specific params (location, time, event_type etc.)
 * Doesn't include calendar params such as date, registration etc.
 */
export class SingleEventForm extends Component {

    render() {
        const event = this.props.event;
        return(
            <div style={{marginBottom: 20}}>
                <FormControl>
                    <InputLabel htmlFor="type">Type</InputLabel>
                    <Select
                        inputProps={{id: 'type'}}
                        label="Type"
                        style={{width: 100}}
                        value={event.event_type_option}
                        onChange={e => this.props.onFieldChange(e.target.value, 'event_type_option')}
                    >
                        {['Lesson', 'Round 1', 'Round 2', 'Other'].map(val => (
                            <MenuItem style={{width: 100}} key={val} value={val}>{val}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    label="Event Name"
                    style={{
                        marginLeft: 20,
                        display: event.event_type_option !== 'Other' ? 'none' : 'inline'
                    }}
                    value={event.event_type}
                    onChange={e => this.props.onFieldChange(e.target.value, 'event_type')}
                />
                <TextField
                    label="Lesson Subject"
                    style={{
                        marginLeft: 20,
                        display: event.event_type_option !== 'Lesson' ? 'none' : 'inline'
                    }}
                    value={event.event_subject}
                    onChange={e => this.props.onFieldChange(e.target.value, 'event_subject')}
                />
                <br/><br/>

                <TimePicker
                    style={{width: 100}}
                    label="Time"
                    ampm={false}
                    value={event.time}
                    onChange={dt => this.props.onFieldChange(dt, 'time')}
                />

                <TextField
                    label="Location"
                    style={{marginLeft: 20}}
                    onChange={e => this.props.onFieldChange(e.target.value, 'location')}
                    value={event.location}/>
                <br/><br/>

                <Checkbox style={{margin: 0, padding: 0}}
                          checked={event.open_to_jr}
                          onChange={() => this.props.onFilterChange('open_to_jr')}/>
                <label style={fieldNameStyle}>Juniors </label>

                <Checkbox style={{margin: 0, padding: 0}}
                          checked={event.open_to_sr}
                          onChange={() => this.props.onFilterChange('open_to_sr')}/>
                <label style={fieldNameStyle}>Seniors </label>

                <Checkbox style={{margin: 0, padding: 0}}
                          checked={event.open_to_other_clubs}
                          onChange={() => this.props.onFilterChange('open_to_other_clubs')}/>
                <label style={fieldNameStyle}>Other Clubs </label>

                <Checkbox style={{margin: 0, padding: 0}}
                          checked={event.open_to_non_students}
                          onChange={() => this.props.onFilterChange('open_to_non_students')}/>
                <label style={fieldNameStyle}>Non Students</label>

                <br/>
            </div>
        );
    }
}
