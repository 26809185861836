import React, {Component} from "react";
import {formatDateDayTime, truncateDate, tsToDate} from "../../Utilities/DateTimeUtils";
import {DatePicker, DateTimePicker} from "material-ui-pickers";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import {getAllEventDates} from "./NewEventsTemplates";
import {CloseButton} from "../../Common/Buttons";

/**
 * Responsible for choosing events' schedule params - date, registration and check-in start
 */
export class NewEventsFormHeader extends Component {

    // Return false if the date is the original date, or some other available date
    isDateTaken = date => {
        if(this.props.date && truncateDate(date).getTime() === truncateDate(this.props.date).getTime()) return false;
        const allEventDates = getAllEventDates(this.props.allEvents);
        for(const date2 of allEventDates) {
            if (truncateDate(tsToDate(date2)).getTime() === truncateDate(date).getTime()) return true;
        }
        return false;
    };

    render(){
        return (
            <div>
                <h1 style={{marginTop: 5, padding: 0, display: 'inline'}}>
                    <CloseButton onClick={()=>this.props.onExit()}/>
                    {formatDateDayTime(this.props.date)}
                </h1>
                <br/><br/>
                <DatePicker
                    showTodayButton={true}
                    style={{width: 90, margin: 8, marginLeft: 0}}
                    label="Date"
                    value={this.props.date}
                    minDate={new Date()}
                    shouldDisableDate={this.isDateTaken}
                    onChange={(dt)=>this.props.onDateChange(dt)}/>

                <DateTimePicker
                    showTodayButton={true}
                    style={{width: 135, margin: 8}}
                    label="Registration Start"
                    value={this.props.registration_start}
                    ampm={false}
                    maxDate={this.props.registration_end ? this.props.registration_end : new Date()}
                    onChange={(dt)=>this.props.onRegStartChange(dt)}/>

                <DateTimePicker
                    showTodayButton={true}
                    style={{width: 135, margin: 8}}
                    label="Registration End"
                    value={this.props.registration_end}
                    ampm={false}
                    minDate={this.props.registration_start ? this.props.registration_start : new Date()}
                    maxDate={this.props.date ? this.props.date : new Date()}
                    onChange={(dt)=>this.props.onRegEndChange(dt)}/>

                <DateTimePicker
                    showTodayButton={true}
                    style={{width: 130, margin: 8}}
                    label="Check-In Start"
                    value={this.props.check_in_start}
                    ampm={false}
                    minDate={this.props.registration_start ? this.props.registration_start : new Date()}
                    maxDate={this.props.date ? this.props.date : new Date()}
                    onChange={(dt)=>this.props.onCheckInChange(dt)}/>

                <div align={"left"}>
                    <br/>
                    <h2 style={{marginBottom:'0px', display: 'inline'}}> Events </h2>
                    <Fab
                        title={"Add Event"}
                        onClick={() => this.props.onAdd()}
                        size="small"
                        color="primary">
                        <Add>Add</Add>
                    </Fab>
                    <br/><br/>
                </div>
            </div>
        );
    }
}
