import React from "react";
import {DebaterCardHeader} from "../UserComponents/DebaterCardHeader";
import {formatDateShort, sortEvents, tsToDate, inRange} from "../Utilities/DateTimeUtils";
import {getScoreColor} from "../Utilities/DebaterUtilities";

const lefted = {textAlign: 'left', padding: 2, marginLeft: 2};
const centered = {textAlign: 'center', padding: 2};

/**
 * Creates an array of columns for user management page (according to material-table component's format)
 * Includes personal information columns and event columns for each event (excluding lessons)
 */
export function getDebaterColumns(debaters, onUserClick){
    return [
        { title: 'Debater', render: rowData => {
                if(rowData['full_name_eng']!==null){
                    const debater = debaters[rowData.id];
                    return <DebaterCardHeader debater={debater}
                                              tooltip="Open Profile"
                                              onClick={()=>onUserClick(debater)}/>
                }}, sorting: false, removable: false, headerStyle: {textAlign: 'left', paddingLeft: 10}, cellStyle: lefted},

        // Extra Personal Information:
        { title: 'Full Name (Hebrew)', field: 'full_name_heb', headerStyle: lefted, cellStyle: lefted, hidden: true},
        { title: 'Email', field: 'email', headerStyle: lefted, cellStyle: lefted, hidden: true},
        { title: 'Phone Number', field: 'phone_number', headerStyle: lefted, cellStyle: lefted, hidden: true},
        { title: 'Official Judgement Level', field: 'official_judgement_level', hidden: true, headerStyle: lefted, cellStyle: lefted},
        { title: 'Club', field: 'club',  headerStyle: lefted, cellStyle: lefted, hidden: true},
        { title: 'Gender', field: 'gender', headerStyle: lefted, cellStyle: lefted, hidden: true},
        { title: 'Start Year', field: 'start_year', type: 'numeric', headerStyle: lefted, cellStyle: lefted, hidden: true},

        // Events info:
        { title: 'Average Score', field: 'average_score', removable: false, headerStyle: centered,
            cellStyle: {textAlign: 'center', padding: 0, margin:0, fontWeight: 'bold'}},
        { title: '#Events ', field: 'event_count', type: 'numeric',
            headerStyle: {borderRight: 'none', padding: 10, margin: 2, textAlign: 'left'},
            cellStyle: {borderRight: 'none', padding: 2, margin: 2, textAlign: 'center'}},

        { title: '', searchable: false, sorting: false, removable: false, isDateColumn: true,
            headerStyle: {borderRight: '1px solid #ccc', borderLeft: '2px solid #bbb', padding: 0, margin: 0, textAlign: 'center'},
            cellStyle: {borderRight: '1px solid #ccc', borderLeft: '2px solid #bbb', padding: 0, margin: 0, textAlign: 'center', fontWeight: 'bold'},
            render: rowData => {
                if(rowData.parentId || rowData.tableData.isTreeExpanded)
                    return <span style={{padding: 5}}>{rowData['event_attribute_type']}</span>;
                return '';
            }
        }
    ]
}

export function getEventColumns(debaters, events, firstDate, lastDate){
    const columns = [];

    // Sorting events by date and time, and filtering out lessons
    const eventsArr = [];
    for(const eventId in events) {
        if(events[eventId].event_type==='Lesson') continue;
        eventsArr.push(events[eventId]);
    }
    sortEvents(eventsArr);

    // Marking the first and last event of each date, and the first future event
    let foundFirstFutureEvent = false;
    for(let i = 1; i < eventsArr.length-1; i++) {
        if(tsToDate(eventsArr[i].date).getTime() !== tsToDate(eventsArr[i+1].date).getTime())
            eventsArr[i].last_event_of_date = true;
        if(tsToDate(eventsArr[i].date).getTime() !== tsToDate(eventsArr[i-1].date).getTime())
            eventsArr[i].first_event_of_date = true;

        // Traversing in reverse order to find the first future event
        if(!foundFirstFutureEvent && tsToDate(eventsArr[eventsArr.length-i].date).getTime() >= (new Date()).getTime()) {
            eventsArr[eventsArr.length-i].first_future_event = true;
            foundFirstFutureEvent = true;
        }
    }

    // Adding a column for each event
    for(const event of eventsArr) {
        if(!inRange(tsToDate(event.date), firstDate, lastDate)) continue;

        const eventPassed = tsToDate(event.time).getTime() < (new Date()).getTime();
        const title =
            <span style={{whiteSpace: 'nowrap'}}>
                <b>{formatDateShort(tsToDate(event.time))}</b><br/>
                {event.event_type.replace(" ","-")}
                {!eventPassed && <span><br/>*Registration*</span>}
            </span>;

        columns.push({
            title: title,
            sorting: false,
            filtering: false,
            searchable: false,
            field: event.id,
            isDateColumn: true,
            type: eventPassed ? 'string': 'boolean',
            headerStyle: {
                padding: 0,
                textAlign: 'center',
                color: eventPassed ? 'auto' : '#559955',
                borderLeft: event.first_event_of_date ? '1px solid #ccc' : '',
                borderRight: event.first_future_event ? '2px solid #bbb' : (event.last_event_of_date ? '1px solid #ccc' : ''),
            },
            cellStyle: data => {
                let style = {
                    padding: 2,
                    margin: 5,
                    minWidth: 90,
                    borderLeft: event.first_event_of_date ? '1px solid #ccc' : '',
                    borderRight: event.first_future_event ? '2px solid #bbb' : (event.last_event_of_date ? '1px solid #ccc' : ''),
                    textAlign: 'center'
                };
                if (data === parseInt(data)) {
                    style['backgroundColor'] = getScoreColor(data);
                    style['fontWeight'] = 'bold';
                } else if (data === '-') {
                    style['backgroundColor'] = '#F5F5F5';
                } else if (data === 'CANCELLED'){
                    style['color'] = '#CC0000';
                } else if (data === 'No Feedback') {
                    style['color'] = '#777777';
                }
                return style;
            }});
    }
    return columns;
}
