import React, { Component } from "react"
import Button from "@material-ui/core/Button";

/**
 * Custom component for an array of toggle buttons
 */
export class ToggleButton extends Component {
  constructor(props) {
      super(props);
      this.state = {
          value: props.default ? props.default : props.values[0]
      };
      this.toggle = this.toggle.bind(this);
      this.getStyle = this.getStyle.bind(this);
  }

  toggle(value) {
      this.props.onChange(value);
      this.setState({value: value});
  }

  getStyle(value, index){
      const baseStyle = {
          margin: 0,
          borderRadius: '0%',
          border: '1px solid #dddd',
      };
      if(index === 0) {
          baseStyle['borderTopLeftRadius'] = '10%';
          baseStyle['borderBottomLeftRadius'] = '10%';
      }
      if(index === this.props.values.length - 1){
          baseStyle['borderTopRightRadius'] = '10%';
          baseStyle['borderBottomRightRadius'] = '10%';
      }
      if(this.state.value === value) {
          baseStyle['backgroundColor']="#3F51B5";
          baseStyle['color']="#FFFFFF";
      }
      return baseStyle;
  }

  render(){
      if(!this.props.values) return null;
      return (
          <div style={{display: 'inline-block', boxShadow: '0px 1px 2px 0px #DDDDDD', borderRadius: '10%'}}>
              {this.props.values.map((value, index) => {
                  const style = this.getStyle(value, index);
                  return (
                      <Button onClick={() => this.toggle(value)}
                              style={style}
                              key={index}>
                          {this.props.labels ? this.props.labels[index] : this.props.values[index]}
                      </Button>
                  );
              })}
          </div>
      );
  }
}
