import {getEventColumns} from "./UsersTableColumns";
import {formatDateShort, sortEvents, truncateDate, tsToDate} from "../Utilities/DateTimeUtils";
import {titleCase, deepClone} from "../Utilities/StringUtils";
import {getFullName, getFullNameHeb, isApprovedUser} from "../Utilities/DebaterUtilities";
import React from "react";
import FeedbackWithPopper from "./FeedbackWithPopper";


/**
 * @param that (UserManagement class)
 * Preparing JSON for the table - One row per user and event attribute (score, position, chair)
 */
export function createTableData(that) {
    const columns = getEventColumns(that.state.allDebaters, that.state.events, that.state.firstDate, that.state.lastDate);

    if(!that.state.fullHistory || !that.state.events || !that.state.allDebaters) {
        that.setState({eventColumns: columns, loading: false});
        return;
    }
    const tableData = [];
    const event_attributes = ['score','position','chair_clean','chair'];
    for (const debaterId in that.state.allDebaters) {
        const debater = deepClone(that.state.allDebaters[debaterId]);
        if(!isApprovedUser(debater)) continue;
        debater['id'] = debaterId;
        debater['full_name_heb'] = getFullNameHeb(debater);
        debater['full_name_eng'] = getFullName(debater);
        debater['full_name_search'] = getFullName(debater);

        for (const attribute of event_attributes) {
            const row = {};
            for(const key in debater) row[key] = debater[key]; // Clone

            row['event_attribute_type'] = titleCase(attribute);
            row['average_score'] = that.state.fullHistory[debaterId] ?
                (Math.round(that.state.fullHistory[debaterId]['average_score'] * 100))/100 : 0;
            row['event_count'] = that.state.fullHistory[debaterId] ?
                that.state.fullHistory[debaterId]['event_count'] : 0;
            row['scored_event_count'] = that.state.fullHistory[debaterId] ?
                that.state.fullHistory[debaterId]['scored_event_count'] : 0;

            for (const eventId in that.state.events) {
                let eventData = null;
                if(that.state.fullHistory[debaterId] && that.state.fullHistory[debaterId]['events'][eventId])
                    eventData = that.state.fullHistory[debaterId]['events'][eventId];

                const eventPassed = tsToDate(that.state.events[eventId].date).getTime() < truncateDate((new Date())).getTime();

                // For future events render a boolean field, true IFF debater registered
                if(!eventPassed) {
                    row[eventId] = eventData && eventData['registration'];
                    continue;
                }

                if (eventData === 'cancelled') row[eventId] = 'CANCELLED';
                else if (!eventData || !eventData['position']) row[eventId] = '-';
                else {
                    const isFeedback = (eventData['score'] && eventData['score'] > 0);
                    switch (attribute) {
                        case 'score':
                            if(eventData['chair_id'] && eventData['chair_id'] === debaterId) row[eventId] = 'Chair';
                            else row[eventId] = isFeedback ? eventData['score'] : 'No Feedback';
                            break;
                        case 'position': row[eventId] = eventData['position'];
                            break;
                        case 'chair':
                            const chairName = eventData['chair_name'];
                            if (!isFeedback) {
                                row[eventId] = <span>{chairName}</span>;
                            } else {
                                const feedback = {};
                                feedback['score'] = eventData['score'];
                                feedback['positive_feedback'] = eventData['positive_feedback'];
                                feedback['negative_feedback'] = eventData['negative_feedback'];
                                feedback['notes'] = eventData['notes'];
                                feedback['debaterName'] = getFullName(debater);
                                feedback['chairName'] = eventData['chair_name'];
                                row[eventId] = <FeedbackWithPopper chairName={chairName} feedback={feedback}/>
                            }
                            break;
                        case 'chair_clean':
                            row[eventId] = eventData['chair_name'];
                            break;
                        default:
                            break;
                    }
                }
            }
            row['parentId'] = row['id'];
            if (attribute === 'score') {
                row['parentId'] = null;
            } else {
                row['full_name_eng'] = null;
                row['email'] = null;
                row['gender'] = null;
                row['phone_number'] = null;
                row['full_name_heb'] = null;
                row['email'] = null;
                row['club'] = null;
                row['start_year'] = null;
                row['official_judgement_level'] = null;
                row['id'] = row['parentId'] + 'CHILD';
            }
            tableData.push(row);
        }
    }

    // Sorting by average score, after making sure each row stays with it's parent/children (id===parentId)
    tableData.sort(sortDebaters);

    const exportData = getExportData(tableData, that.state.events);
    const finalData = tableData.filter(row => row['event_attribute_type'] !== 'Chair Clean'); // Chair Clean is only for Export data

    that.setState({tableData: finalData, exportData: exportData, eventColumns: columns, loading: false},
        () => that.updateFilteredTableData());
}


// Sort by average score, than event count
export const sortDebaters = (a, b) => {
    if(a.id===b.parentId || a.parentId===b.id) return 0; // To stick tree data together
    if(a.average_score !== b.average_score) {
        return a.average_score < b.average_score ? 1:-1;
    } else {
        return a.scored_event_count < b.scored_event_count ? 1:-1;
    }
};

/**
 * @param tableData
 * @param events
 * @returns {*[]}
 *
 * Prepare data for <CSVExporter/> format
 */
function getExportData(tableData, events) {

    const cleanCell = (data) => data ? data.toString()
        .replace(',',';')
        .replace('Chair Clean','Chair')
        : '';

    // Sorting events by date and time, and filtering out lessons
    const eventsArr = [];
    for(const eventId in events) {
        if(events[eventId].event_type === 'Lesson') continue;
        eventsArr.push(events[eventId]);
    }
    sortEvents(eventsArr);

    const headerRow = ['Full Name','שם מלא','Email','Phone Number','Gender','Club','Student','Start Year','Official Judgement Level','Rank',
        'Event Count','Average Score'];
    const fields = ['full_name_eng','full_name_heb','email','phone_number','gender','club','student','start_year','official_judgement_level','rank',
        'event_count','average_score'];

    for(const event of eventsArr) {
        if (event.event_type === 'Lesson') continue;
        if (tsToDate(event.time).getTime() > (new Date()).getTime()) {
            headerRow.push(formatDateShort(tsToDate(event.time)) + " " + event.event_type + " - Registration");
        } else {
            headerRow.push(formatDateShort(tsToDate(event.time)) + " " + event.event_type + " - Score");
            headerRow.push(formatDateShort(tsToDate(event.time)) + " " + event.event_type + " - Chair");
            headerRow.push(formatDateShort(tsToDate(event.time)) + " " + event.event_type + " - Position");
        }
    }
    const rows = [headerRow];

    for(let i = 0; i < tableData.length; i++) {
        const row = tableData[i];
        if(row['event_attribute_type'] !== 'Score') continue; // Flat CSV - only one row per debater
        const row2 = [];

        // Personal details columns
        for(const field of fields) {
            row2.push(cleanCell(row[field]));
        }

        // Events columns
        for(const event of eventsArr) {
            if (tsToDate(event.time).getTime() > (new Date()).getTime()) {
                row2.push(cleanCell(row[event.id]));
            } else {
                const score = row[event.id];
                const position = tableData[i + 2][event.id];
                const chair = tableData[i + 3][event.id];
                row2.push(cleanCell(score));
                row2.push(cleanCell(position));
                row2.push(cleanCell(chair));
            }
        }
        rows.push(row2);
    }
    return rows;
}
