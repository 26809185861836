import React, { Component } from "react";
import { formatDate } from "../Utilities/DateTimeUtils";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import ExportIcon from "@material-ui/icons/SaveAlt";
import Tooltip from "@material-ui/core/Tooltip";

/**
 * CSV Export Module
 * Data should be an array of arrays, each in the same length
 */
export class CSVExporter extends Component {
  fileName;

  constructor(props) {
    super(props);
    this.state = {
      rows: props.data,
    };
    this.rows = props.data;
    this.fileName = "TAU Debate Report - " + formatDate(new Date()) + ".csv"; // Default
    if (this.props.fileName) {
      this.fileName =
        this.props.fileName.split(".")[1] === ".csv"
          ? this.props.fileName
          : this.props.fileName + ".csv";
    }

    this.exportCSV = this.exportCSV.bind(this);
    this.invalidData = this.invalidData.bind(this);
  }

  invalidData() {
    if (!this.state.rows) return "Missing Data";
    if (this.state.rows.length === 0) return "0 Rows in Data";
    if (!Array.isArray(this.state.rows)) return "Data is not Array";
    let i = 0;
    for (const row of this.state.rows) {
      i++;
      if (!Array.isArray(row)) return "Row " + i + " is not Array";
      if (row.length !== this.rows[0].length)
        return "Row " + i + " is of invalid length";
    }
  }

  exportCSV() {
    const error = this.invalidData(this.state.rows);
    if (this.invalidData(this.state.rows)) {
      toast.error("Invalid Data for Export: " + error);
      return;
    }

    const csvContent =
      "data:text/csv;charset=utf-8," +
      this.state.rows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", this.fileName);
    document.body.appendChild(link);
    link.click();
  }

  render() {
    const buttonIcon = this.props.buttonIcon ? (
      this.props.buttonIcon
    ) : (
      <ExportIcon />
    );
    return (
      <IconButton
        onClick={() => this.exportCSV()}
        style={{ margin: 0 }}
        title={this.props.title}
      >
        <Tooltip title={this.props.title} placement="top">
          {buttonIcon}
        </Tooltip>
      </IconButton>
    );
  }
}
