import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FormGroup } from '@material-ui/core';
import MyDroppable from './myDroppable';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from "@material-ui/core/MenuItem";
import HelpIcon from '@material-ui/icons/NotificationImportant';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
      width: '100%',
      maxWidth: 2000,
      backgroundColor: '#E4DEE4',
      padding:1,
    },
    border:{
      border: '1px solid #9FB3C3',
      borderRadius: '5px',
      padding : 5
    },
    item:{
        backgroundColor: '#ffffff',
        height: 140 ,
        maxWidth: 160,
        width: '40%',
        
    },
    form :{
        width:'100%',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '90%',
    },
    dense: {
        marginTop: 4,
    },
    resize:{
        fontSize: 14
    }
  });

/*
* A component containing and displaying a single room's details and contents
*/
class Room extends Component {
    state = {  }
    getBorderColor=(room)=>{
        switch(room.errorStatus){
            case 'error':{
                return '#FA8072';
            }
            case 'warning':{
                return '#F1C40F';
            }
            case '':{
                return '#87E672';
            }
            default :{
                return '#9FB3C3';
            }
        }
    }

    getBorder=(room)=>({
        border: '1.5px solid ',
        borderColor : this.getBorderColor(room),
        borderRadius: '5px',
        padding : 5
    })
    
    render() { 
        const { classes } = this.props;
        const errorStatus = this.props.data.errorStatus === '' ? null :  this.props.data.errorStatus.charAt(0).toUpperCase() + this.props.data.errorStatus.slice(1)
        return ( 
        <div style={this.getBorder(this.props.data)}>
            <FormGroup row className={classes.form}>
            <ListItem key={this.props.data.name} className={classes.item} >
                <ListItemText 
                primary={
                  <div style={{display : 'flex', flexDirection: 'row'}}>
                    <h5 style={{margin :0}}>Room {parseInt(this.props.id,10) +1 }</h5>
                    <div style={{display : 'flex', flexDirection: 'row'}}>
                        {errorStatus && <IconButton style={{padding : 0, marginLeft : 20}}>
                                <Tooltip
                                    placement="left"
                                    interactive={true}                           
                                    title={
                                <React.Fragment >
                                    <Typography color="inherit">{`${errorStatus}:`}</Typography>
                                        <ul>
                                        {this.props.data.errorMessage.map(message =>
                                            {return(<li key={message}>{message}</li>)})}
                                        </ul>                              
                                </React.Fragment>}
                            >
                            <HelpIcon style={{ fontSize: 17 }} />
                            </Tooltip>
                        </IconButton>}
                        <IconButton onClick={()=>this.props.deleteRoom(this.props.id)} style={{padding : 0, marginLeft : 20}}>
                            <Tooltip title="Delete Room" placement="top">
                            <DeleteIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                        </IconButton>
                        
                        </div>
                    </div>} 
                            
                secondary={
                    <div>
                    <TextField
                    id="standard-dense"
                    label="Location"
                    value={this.props.data["Name"]}
                    onChange = {(event)=> this.props.changeRoomName(this.props.id, event)}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    InputProps={{
                        classes: {
                          input: classes.resize,
                        },
                      }}
                    />
                <TextField
                    id="standard-dense"
                    label="Language"
                    value={this.props.data["Language"]}
                    onChange = {(event)=> this.props.changeRoomLanguage(this.props.id, event)}
                    className={classNames(classes.textField, classes.dense)}
                    
                    select
                    InputProps={{
                        classes: {
                          input: classes.resize,
                        },
                      }}
                    >
                    {['Hebrew', 'English'].map(val =>(
                        <MenuItem value={val} key={val}>{val}</MenuItem>
                    ))}
                    </TextField>
                    </div>} />
            </ListItem>
            <div style={{ display : 'flex', flexDirection : 'column'}}>
            <h5 style={{margin :0,marginLeft:10, color:'#919191'}}>Opening Gov</h5>
            <MyDroppable 
                data={this.props.data["Opening Government"]} 
                droppableId={`${this.props.id}.opGov`} 
                changeDebaterId={this.props.changeDebaterId} 
                chair={false} 
                incognito={this.props.incognito}
                registrations={this.props.registrations}
                updateRegistaration={this.props.updateRegistaration}/>
            </div>

            <div style={{display : 'flex', flexDirection : 'column'}}>
            <h5 style={{margin :0, marginLeft:10, color:'#919191'}}>Opening Op</h5>
            <MyDroppable 
                data={this.props.data["Opening Opposition"]} 
                droppableId={`${this.props.id}.opOp`} 
                changeDebaterId={this.props.changeDebaterId} 
                chair={false} 
                incognito={this.props.incognito}
                registrations={this.props.registrations}
                updateRegistaration={this.props.updateRegistaration}/>
           </div>

           <div style={{display : 'flex', flexDirection : 'column'}}>
            <h5 style={{margin :0, marginLeft:10, color:'#919191'}}>Closing Gov</h5>
            <MyDroppable 
                data={this.props.data["Closing Government"]} 
                droppableId={`${this.props.id}.cloGov`} 
                changeDebaterId={this.props.changeDebaterId} 
                chair={false} 
                incognito={this.props.incognito}
                registrations={this.props.registrations}
                updateRegistaration={this.props.updateRegistaration}/>
            </div>

            <div style={{display : 'flex', flexDirection : 'column'}}>
            <h5 style={{margin :0, marginLeft:10, color:'#919191'}}>Closing Op</h5>
            <MyDroppable 
                data={this.props.data["Closing Opposition"]} 
                droppableId={`${this.props.id}.cloOp`} 
                changeDebaterId={this.props.changeDebaterId} 
                chair={false} 
                incognito={this.props.incognito}
                registrations={this.props.registrations}
                updateRegistaration={this.props.updateRegistaration}/>
           </div>

            <div style={{display : 'flex', flexDirection : 'column', maxHeight:50}}>
            <h5 style={{margin :0, marginLeft:10, color:'#919191'}}>Chair</h5>
            <MyDroppable 
                data={this.props.data["Chair"]} 
                droppableId={`${this.props.id}.chair`} 
                changeDebaterId={this.props.changeDebaterId} 
                chair={true} 
                incognito={this.props.incognito}
                registrations={this.props.registrations}
                updateRegistaration={this.props.updateRegistaration}/>
            </div>

            <div style={{display : 'flex', flexDirection : 'column'}}>
            <h5 style={{margin :0, marginLeft:10, color:'#919191'}}>Panel</h5>
            <MyDroppable 
                data={this.props.data["Panel"]} 
                droppableId={`${this.props.id}.panel`} 
                changeDebaterId={this.props.changeDebaterId} 
                chair={false} 
                incognito={this.props.incognito}
                registrations={this.props.registrations}
                updateRegistaration={this.props.updateRegistaration}/>
            </div>
            </FormGroup>
        </div>
             );
    }
}
 
export default withStyles(styles)(Room);