import React, { Component } from "react"
import {handleError} from "../Utilities/OtherUtils";
import {get_all_debaters} from "../../Backend/BackendFunctions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import {DebaterCardHeader} from "../UserComponents/DebaterCardHeader";
import {getFullName, getFullNameHeb} from "../Utilities/DebaterUtilities";
import {toast} from "react-toastify";
import {CancelButton} from "../Common/Buttons";
import {SubmitButton} from "../Common/Buttons";
import {TextSearch} from "../Common/TextSearch";

/**
 * A dialog for searching and selecting multiple users from the database
 */
export class SelectDebatersFromDatabase extends Component {

  constructor(props) {
      super(props);
      this.state={
          filteredDebaterIds: [],
          debaters: {},
          nameSearch: '',
          loading: true
      };
      this.toggleDebater = this.toggleDebater.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.searchName = this.searchName.bind(this);
  }

  componentDidMount(){
      const filteredDebaterIds = [];
      get_all_debaters(1000, null)
          .then(debaters => {
              for(const id in debaters) {
                  if(this.props.filter(debaters[id])) filteredDebaterIds.push(id);
              }
              // Sorting Alphabetically
              filteredDebaterIds.sort((id1, id2) =>
                  getFullName(debaters[id1]).toLowerCase() < getFullName(debaters[id2]).toLowerCase() ? -1:1);
              this.setState({debaters: debaters, filteredDebaterIds: filteredDebaterIds,  loading: false});
          }).catch(e => handleError(e))
  }

  searchName(name) {
      let filteredDebaterIds = [];

      if(!name || name.length <= 1) {
          filteredDebaterIds = Object.keys(this.state.debaters);
          filteredDebaterIds.sort((id1, id2) => getFullName(this.state.debaters[id1]) < getFullName(this.state.debaters[id2]) ? -1:1);
          this.setState({filteredDebaterIds: filteredDebaterIds, nameSearch: name});
          return;
      }

      for(const id in this.state.debaters) {
          if(getFullName(this.state.debaters[id]).toLowerCase().includes(name.toLowerCase()) ||
             getFullNameHeb(this.state.debaters[id]).toLowerCase().includes(name.toLowerCase()))
              filteredDebaterIds.push(id);
      }

      filteredDebaterIds.sort((id1, id2) => getFullName(this.state.debaters[id1]) < getFullName(this.state.debaters[id2]) ? -1:1);
      this.setState({filteredDebaterIds: filteredDebaterIds, nameSearch: name});
  }

  handleClose(){
      this.props.onClose();
  }

  handleSubmit(){
      const selectedDebaters = [];
      for(const id in this.state.debaters) {
          if(this.state.debaters[id].selected) selectedDebaters.push(this.state.debaters[id]);
      }
      if(selectedDebaters.length === 0) {
          toast.error("Please select debaters to register");
      } else {
          this.props.onSubmit(selectedDebaters);
      }
  }

  toggleDebater(id) {
      const debaters = this.state.debaters;
      debaters[id].selected = !debaters[id].selected;
      this.setState({debaters: debaters});
  }

  render() {
      return (
          <div>
              {this.state.loading && <div align={"center"}><CircularProgress/></div>}
              {!this.state.loading &&
                  <div>
                      <TextSearch value={this.state.nameSearch}
                                  label={"Search Debater"}
                                  disabled={this.props.loading}
                                  delay={0}
                                  open={true}
                                  onChange={(value)=>this.searchName(value)}/>
                                  <br/><br/>
                      <table><tbody>
                      {this.state.filteredDebaterIds.map((debaterId, index) => {
                          if(index % 2 === 1) return null; // Showing two users at a time
                          const debater = this.state.debaters[debaterId];
                          const nextId = index < this.state.filteredDebaterIds.length ?
                              this.state.filteredDebaterIds[index + 1] : null;
                          const nextDebater = nextId ? this.state.debaters[nextId] : null;
                          const output =
                              <tr key={debaterId}>
                                  <td>
                                      <Checkbox checked={debater.selected}
                                                disabled={this.props.loading}
                                                onChange={()=>this.toggleDebater(debaterId)}/>
                                  </td>
                                  <td>
                                      <DebaterCardHeader debater={debater}/>
                                  </td>
                                  {nextDebater &&
                                  <td>
                                      <Checkbox checked={nextDebater.selected}
                                                disabled={this.props.loading}
                                                onChange={()=>this.toggleDebater(nextId)}/>
                                  </td>}
                                  {nextDebater &&
                                  <td>
                                      <DebaterCardHeader debater={nextDebater}/>
                                  </td>}
                              </tr>;
                          return output;
                      })}
                      </tbody></table>
                      <div align="center" style={{width:'100%'}}>
                          <CancelButton onClick={this.handleClose} disabled={this.props.loading}/>
                          <SubmitButton onClick={this.handleSubmit} disabled={this.props.loading}/>
                      </div>
                      {this.props.loading && <div align={"center"}><CircularProgress/></div>}
                  </div>
              }
          </div>
      )
  }
}
