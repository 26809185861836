import firebase from "./fire";
import { db, auth } from "./fire";
import {addDays} from "../components/Utilities/DateTimeUtils";

/* this page contains all backend functions regarding user feedback
this functions saves data in two places:
1. after publishing feedbacks forms a list(object with userids as keys) of debaters that should get feedback is saved under the chair at:
"Debaters/chairid/FeedbacksToFill/eventid"
every chair
after the chair fills the feedback the user id is deleted from the object

2. when a chair fills a feedback the data is saved under "Feedbacks/eventid"
this doc contains an object with all the feedbacks from the event with userid (whos the feedback on) as key
*/

/* this function puclish feedbacks. it itirate on every room,
 if a room has juniors to fill feedback on it adds them to the list of debaters the chair should fill feedback on
  */
export function publish_feedbacks(assignment, event) {
  let batch = db.batch();
  //create feedback doc for event
  let allFeedbackRef = db.collection("Feedbacks").doc(event.id);
  batch.set(allFeedbackRef, {});

  let rooms = assignment.rooms;
  for (let room in rooms) {
    //update feedback in chair
    if (rooms[room].Chair[0] && !rooms[room].Chair[0].uid.includes("Fake")) {
      let chair = rooms[room].Chair[0];
      let debatersToFeedback = getDebatersToFeedback(rooms[room]);
      if (Object.keys(debatersToFeedback).length !== 0) {
        let chairFeedBackref = db
          .collection("Debaters")
          .doc(chair.uid)
          .collection("FeedbacksToFill")
          .doc(event.id);

        batch.set(chairFeedBackref, {
          event: event,
          debaters: debatersToFeedback
        });
      }
    }
  }
  return batch.commit();
}

/* input: an assinmentroom output
all debaters in the room who should get feedback with their positions as inner field
  */
export function getDebatersToFeedback(room) {
  let debatersToFeedback = {};
  let debater;
  for (let position in room) {
    if (position === "Chair") {
      continue;
    }
    for (debater in room[position]) {
      debater = room[position][debater];
      if (shouldGetFeedback(debater)) {
        debater = { position, ...debater };
        debatersToFeedback = {
          [debater.uid]: debater,
          ...debatersToFeedback
        };
      }
    }
  }
  return debatersToFeedback;
}

/*  return if debater should get feedback */
function shouldGetFeedback(debater) {
  if (debater.uid && debater.uid.includes("FAKE")) {
    return false;
  }
  if (debater.rank === "Junior") {
    return true;
  }
  return false;
}

/*  returns all the users a chair should fill feedback on */
export function get_chair_feedbacks() {
  let p = new Promise((resolve, reject) => {
    let twoWeeksAgo = addDays(new Date(),-14); // we only want event future events (keep events one day after they happend)

    let result = {};
    db.collection("Debaters")
      .doc(auth.currentUser.uid)
      .collection("FeedbacksToFill")
      .where("event.time", ">=", twoWeeksAgo) // dont show feedbacks older then two weeks
      .get()
      .then(feedbacks => {
        feedbacks.forEach(feedback => {
          result = { [feedback.id]: feedback.data(), ...result };
        });
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* this function saves the feedback at the Feedbacks collection and delete the user form the list of feedbacks left for the chair */
export function add_feedback(event, feedbackData, feedbackOnID, finished) {
  const batch = db.batch();

  //update feedbackdata
  feedbackData = {
    event: event,
    feedbackBy: auth.currentUser.uid,
    feedbackOn: feedbackOnID,
    ...feedbackData
  };

  let p = new Promise((resolve, reject) => {
    //update feedback at Feedbacks/eventid
    let feedbackRef = db.collection("Feedbacks").doc(event.id);
    batch.update(feedbackRef, { [feedbackOnID]: feedbackData });

    // update chair
    let chairFeedBackref = db
      .collection("Debaters")
      .doc(auth.currentUser.uid)
      .collection("FeedbacksToFill")
      .doc(event.id);
    // if chair filled the last feedback delete the event feedback doc
    if (finished) {
      batch.delete(chairFeedBackref);
    } else {
      batch.update(chairFeedBackref, {
        ["debaters." + [feedbackOnID]]: firebase.firestore.FieldValue.delete()
      });
    }
    //commit all update together then clean chair feedback if needed
    batch
      .commit()
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}

/* get event id and retun all the feedbacks that where filled for the event under Feedbacks/eventid */
export function get_all_feedback_for_event(eventId) {
  if (!eventId) return;
  let p = new Promise((resolve, reject) => {
    db.collection("Feedbacks")
      .doc(eventId)
      .get()
      .then(feedbacks => {
        resolve(feedbacks.data());
      })
      .catch(error => {
        reject(error);
      });
  });
  return p;
}
