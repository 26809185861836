import React, { Component } from "react";
import {
  add_registration_for_user,
  get_registrations_for_event
} from "../../Backend/BackendFunctions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  eventJuniorsFilter,
  eventOtherFilter,
  eventSeniorsFilter
} from "./EventsTable/EventStats";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";
import UserDetailsPopper from "../assignmentComps/userDetailsPopper";
import { DebaterHeader } from "../UserComponents/DebaterHeader";
import { UserDetailsDialog } from "../UserComponents/UserDetailsPageComponents/UserDetailsDialog";
import PersonAdd from "@material-ui/icons/PersonAdd";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import { ManuallyRegisterDebatersDialog } from "./ManuallyRegisterDebatersDialog";
import { getFullName, isSystemAdmin } from "../Utilities/DebaterUtilities";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { handleError } from "../Utilities/OtherUtils";
import { ConfirmAlertBody } from "../Common/ConfirmAlert";

/**
 * Display the list of debaters registered to a given event
 * Also contains a toolbar for manually adding/deleting registrations
 */
export default class EventRegisteredDebaters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_mode: false,
      debaters: null,
      debaterIds: {},
      registrations: {},
      showDebaterPopper: null,
      showDebaterDialog: null,
      showAddDebatersDialog: false,
      selectedForDeletion: {},
      loadingDeletion: false
    };

    this.getDebaterCard = this.getDebaterCard.bind(this);
    this.countDebaters = this.countDebaters.bind(this);
    this.openAddDebatersDialog = this.openAddDebatersDialog.bind(this);
    this.closeAddDebatersDialog = this.closeAddDebatersDialog.bind(this);
    this.openDebaterCard = this.openDebaterCard.bind(this);
    this.openDebaterDialog = this.openDebaterDialog.bind(this);
    this.closeDebaterCard = this.closeDebaterCard.bind(this);
    this.filterDebaters = this.filterDebaters.bind(this);
    this.toggleDeletionCheckbox = this.toggleDeletionCheckbox.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.deleteRegistrations = this.deleteRegistrations.bind(this);
    this.deleteRegistrationsRec = this.deleteRegistrationsRec.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({ loadingDeletion: false });
    const debaters = [];
    const debaterIds = {};
    const selectedForDeletion = {};
    get_registrations_for_event(this.props.event.id)
      .then(registrations => {
        Object.keys(registrations).map(debaterId => {
          if (!registrations[debaterId]["cancelledOnTime"]) {
            debaters.push(this.props.allDebaters[debaterId]);
            debaterIds[debaterId] = true;
            selectedForDeletion[debaterId] = false;
          }
          return null;
        });

        // Sorting Alphabetically
        debaters.sort((d1, d2) => (getFullName(d1) < getFullName(d2) ? -1 : 1));
        this.setState({
          debaters: debaters,
          registrations: registrations,
          debaterIds: debaterIds,
          selectedForDeletion: selectedForDeletion
        });
      })
      .catch(error => console.log(error));
  }

  countDebaters(filter) {
    let cnt = 0;
    for (const debater of this.state.debaters) {
      if (filter(debater)) cnt++;
    }
    return cnt;
  }

  openAddDebatersDialog() {
    this.setState({ showAddDebatersDialog: true });
  }

  closeAddDebatersDialog() {
    this.refresh();
    this.setState({ showAddDebatersDialog: false });
  }

  closeDebaterCard() {
    this.setState({ showDebaterPopper: null });
  }

  openDebaterDialog(debater) {
    this.setState({ showDebaterDialog: debater, showDebaterPopper: null });
  }

  openDebaterCard(debaterId, event) {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      showDebaterPopper: debaterId,
      showDebaterDialog: null
    }));
  }

  getDebaterCard(debater) {
    const iconStyle = {
      float: "right",
      padding: 0,
      margin: 0
    };

    return (
      <div
        key={debater.id}
        style={{ width: 180, backgroundColor: "#eeeeee", margin: 5 }}
      >
        <IconButton
          title={"info"}
          style={iconStyle}
          onClick={e => this.openDebaterCard(debater.id, e)}
        >
          <Info style={{ fontSize: 15 }} />
        </IconButton>

        {this.state.edit_mode && (
          <Checkbox
            style={{ float: "left" }}
            disabled={this.state.loadingDeletion}
            checked={this.state.selectedForDeletion[debater.uid]}
            onChange={() => this.toggleDeletionCheckbox(debater.uid)}
          />
        )}

        <DebaterHeader debater={debater} tooltip={"Open User Profile"} />
        <UserDetailsPopper
          id={debater.id}
          debater={debater}
          showUserId={this.state.showDebaterPopper === debater.id}
          registration={this.state.registrations[debater.id]}
          anchorEl={this.state.anchorEl}
          changeDebaterId={debater.id}
          draggableId={debater.id}
          incognito={!isSystemAdmin(this.props.user)}
          close={e => this.closeDebaterCard(e)}
        />
      </div>
    );
  }

  // Create an array representing a column of debaters under a given filter
  filterDebaters(filter, columnNumber, totalColumns) {
    const res = [];
    const filteredDebaters = [];
    for (let i = 0; i < this.state.debaters.length; i++) {
      if (filter(this.state.debaters[i]))
        filteredDebaters.push(this.state.debaters[i]);
    }
    for (let i = 0; i < filteredDebaters.length; i++) {
      if (i % totalColumns === columnNumber) res.push(filteredDebaters[i]);
    }
    return res;
  }

  toggleDeletionCheckbox(debaterId) {
    const selectedForDeletion = this.state.selectedForDeletion;
    selectedForDeletion[debaterId] = !selectedForDeletion[debaterId];
    this.setState({ selectedForDeletion: selectedForDeletion });
  }

  setEditMode(bool) {
    const selectedForDeletion = {};
    for (const debaterId in this.state.debaterIds)
      selectedForDeletion[debaterId] = false;
    this.setState({
      selectedForDeletion: selectedForDeletion,
      edit_mode: bool
    });
  }

  deleteRegistrations() {
    const selectedForDeletionArr = [];
    for (const id in this.state.selectedForDeletion) {
      if (this.state.selectedForDeletion[id]) selectedForDeletionArr.push(id);
    }
    if (selectedForDeletionArr.length === 0) {
      toast.error("Please select debaters");
      return;
    }
    const message = (
      <span>
        {"Are you sure you want to delete the registration of " +
          selectedForDeletionArr.length +
          (selectedForDeletionArr.length > 1 ? " users?" : " user?")}
      </span>
    );
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlertBody
            onClose={onClose}
            message={message}
            variant={"warning"}
            onSubmit={() => {
              this.setState({ loadingDeletion: true }, () =>
                this.deleteRegistrationsRec(selectedForDeletionArr, 0)
              );
            }}
          />
        );
      }
    });
  }

  deleteRegistrationsRec(selectedForDeletionArr, index) {
    if (index >= selectedForDeletionArr.length) {
      this.setEditMode(false);
      this.setState({ loadingDeletion: false }, () => {
        this.refresh();
        toast.info("Successfully Deleted Registrations");
      });
      return;
    }

    const debaterId = selectedForDeletionArr[index];
    const newRegistration = { cancelledOnTime: true };
    add_registration_for_user(this.props.event.id, debaterId, newRegistration)
      .then(() =>
        this.deleteRegistrationsRec(selectedForDeletionArr, index + 1)
      )
      .catch(e => -handleError(e, "Failed Deleting Registration"));
  }

  render() {
    if (!this.state.debaters) {
      return (
        <div align="center">
          <CircularProgress />
        </div>
      );
    }

    const tdStyle = {
      border: "2px solid #ccc",
      padding: 3,
      verticalAlign: "top"
    };
    const tdLeftStyle = {
      borderLeft: "2px solid #ccc",
      padding: 3,
      verticalAlign: "top"
    };
    const tdRightStyle = {
      borderRight: "2px solid #ccc",
      padding: 3,
      verticalAlign: "top"
    };
    const tdMiddleStyle = { border: "none", padding: 3, verticalAlign: "top" };

    const totalColumns = 5;
    const totalDebaters = this.state.debaters.length;
    const columnsJrCount =
      this.countDebaters(eventJuniorsFilter) === 0
        ? 0
        : Math.max(
            1,
            Math.floor(
              totalColumns *
                (this.countDebaters(eventJuniorsFilter) / totalDebaters)
            )
          );
    const columnsSrCount =
      this.countDebaters(eventSeniorsFilter) === 0
        ? 0
        : Math.max(
            1,
            Math.floor(
              totalColumns *
                (this.countDebaters(eventSeniorsFilter) / totalDebaters)
            )
          );
    const columnsOtherCount =
      this.countDebaters(eventOtherFilter) === 0
        ? 0
        : Math.max(
            1,
            Math.floor(
              totalColumns *
                (this.countDebaters(eventOtherFilter) / totalDebaters)
            )
          );

    const columnsJr = [];
    const columnsSr = [];
    const columnsOther = [];
    for (let i = 0; i < columnsJrCount; i++) columnsJr.push(i);
    for (let i = 0; i < columnsSrCount; i++) columnsSr.push(i);
    for (let i = 0; i < columnsOtherCount; i++) columnsOther.push(i);

    return (
      <div
        align="center"
        style={{
          margin: 5,
          maxWidth: this.props.maxWidth ? this.props.maxWidth : 1000,
          maxHeight: 600,
          overflowY: this.state.debaters.length > 10 ? "scroll" : "none"
        }}
      >
        {this.state.showDebaterDialog && (
          <UserDetailsDialog
            user={this.props.user}
            debater={this.state.showDebaterDialog}
            onClose={() => this.setState({ showDebaterDialog: null })}
          />
        )}

        {this.state.showAddDebatersDialog && (
          <ManuallyRegisterDebatersDialog
            registeredDebaterIds={this.state.debaterIds}
            event={this.props.event}
            onClose={() => this.closeAddDebatersDialog()}
          />
        )}

        {this.state.loadingDeletion && (
          <div align="center">
            <CircularProgress />
          </div>
        )}

        {!this.state.edit_mode && !this.state.loadingDeletion && (
          <IconButton
            style={{ marginTop: 0, marginBottom: 10 }}
            title={"Manually Register Users"}
            onClick={() => this.openAddDebatersDialog()}
          >
            <PersonAdd style={{ margin: 0 }} />
          </IconButton>
        )}

        {!this.state.edit_mode && !this.state.loadingDeletion && (
          <IconButton
            style={{ marginTop: 0, marginBottom: 10 }}
            title={"Delete Registrations"}
            onClick={() => this.setEditMode(true)}
          >
            <DeleteIcon style={{ margin: 0 }} />
          </IconButton>
        )}

        {this.state.edit_mode && !this.state.loadingDeletion && (
          <IconButton
            style={{ marginTop: 0, marginBottom: 10 }}
            title={"Undo"}
            onClick={() => this.setEditMode(false)}
          >
            <ClearIcon style={{ color: "#AA0000", margin: 0 }} />
          </IconButton>
        )}

        {this.state.edit_mode && !this.state.loadingDeletion && (
          <IconButton
            style={{ marginTop: 0, marginBottom: 10 }}
            title={"Delete Registrations"}
            onClick={() => this.deleteRegistrations()}
          >
            <DoneIcon style={{ color: "#00AA00", margin: 0 }} />
          </IconButton>
        )}

        {this.state.debaters.length === 0 && !this.state.loadingDeletion && (
          <h3 align="center">No Registrations Found</h3>
        )}

        {this.state.debaters.length > 0 && (
          <table style={{ borderCollapse: "collapse", borderStyle: "hidden" }}>
            <tbody>
              <tr>
                {this.countDebaters(eventSeniorsFilter) > 0 && (
                  <td
                    style={Object.assign({}, tdStyle, {
                      color: this.props.event.open_to_sr ? "#000000" : "#CC0000"
                    })}
                    colSpan={columnsSr.length}
                    align="center"
                  >
                    <h3 style={{ margin: 3 }}>Seniors</h3>
                    <span>{this.countDebaters(eventSeniorsFilter)}</span>
                  </td>
                )}

                {this.countDebaters(eventJuniorsFilter) > 0 && (
                  <td
                    style={Object.assign({}, tdStyle, {
                      color: this.props.event.open_to_jr ? "#000000" : "#CC0000"
                    })}
                    colSpan={columnsJr.length}
                    align="center"
                  >
                    <h3 style={{ margin: 3 }}>Juniors</h3>
                    <span>{this.countDebaters(eventJuniorsFilter)}</span>
                  </td>
                )}

                {this.countDebaters(eventOtherFilter) > 0 && (
                  <td
                    style={Object.assign({}, tdStyle, {
                      color: this.props.event.open_to_other_clubs
                        ? "#000000"
                        : "#CC0000"
                    })}
                    colSpan={columnsOther.length}
                    align="center"
                  >
                    <h3 style={{ margin: 3 }}>Other</h3>
                    <span>{this.countDebaters(eventOtherFilter)}</span>
                  </td>
                )}
              </tr>
              <tr>
                {this.countDebaters(eventSeniorsFilter) > 0 &&
                  columnsSr.map(i => (
                    <td
                      key={i}
                      style={
                        i === 0
                          ? tdLeftStyle
                          : i === columnsSr.length - 1
                          ? tdRightStyle
                          : tdMiddleStyle
                      }
                    >
                      <div style={{ padding: 0, margin: 0 }}>
                        {this.filterDebaters(
                          eventSeniorsFilter,
                          i,
                          columnsSr.length
                        ).map(debater => this.getDebaterCard(debater))}
                      </div>
                    </td>
                  ))}

                {this.countDebaters(eventJuniorsFilter) > 0 &&
                  columnsJr.map(i => (
                    <td
                      key={i}
                      style={
                        i === 0
                          ? tdLeftStyle
                          : i === columnsJr.length - 1
                          ? tdRightStyle
                          : tdMiddleStyle
                      }
                    >
                      <div style={{ padding: 0, margin: 0 }}>
                        {this.filterDebaters(
                          eventJuniorsFilter,
                          i,
                          columnsJr.length
                        ).map(debater => this.getDebaterCard(debater))}
                      </div>
                    </td>
                  ))}

                {this.countDebaters(eventOtherFilter) > 0 &&
                  columnsOther.map(i => (
                    <td
                      key={i}
                      style={
                        i === 0
                          ? tdLeftStyle
                          : i === columnsOther.length - 1
                          ? tdRightStyle
                          : tdMiddleStyle
                      }
                    >
                      <div style={{ padding: 0, margin: 0 }}>
                        {this.filterDebaters(
                          eventOtherFilter,
                          i,
                          columnsOther.length
                        ).map(debater => this.getDebaterCard(debater))}
                      </div>
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
