import { db } from "./fire";
import {get_all_debaters} from "./userBackendFunctions";
import {getFullName, isApprovedUser} from "../components/Utilities/DebaterUtilities";
import {get_events} from "./eventsBackendFunctions";
import {get_registrations_for_event} from "./registratinoBackendFunctions";
import {getDebatersInRoom, getPositionInRoom, load_assignment} from "./assignmentBackendFunctions";
import {get_all_feedback_for_event} from "./feedbackBackendFunctions";
import {isEmptyFeedback} from "../components/EventManagementComps/EventFeedbacks";
import {defaultFirstDate, defaultLastDate} from "../components/Utilities/DateTimeUtils";

/* this page contains all backend functions regarding debate history
debate history is a file containing all the user's registration, assignment and feedbacks in one compressed (minimalistic not actually compressed) file
it is constructed at the users page
we use the debate history in order to show users average score at assignment and debate history in his profile
only admin can access this file
the file is stored at "Debate_History/All_Debate_History"
*/

/* get all debate history */
export function get_debate_history() {
  let p = new Promise((resolve, reject) => {
    db.collection("Debate_History")
      .doc("All_Debate_History")
      .get()
      .then(debateHistory => {
        resolve(debateHistory.data());
      })
      .catch(error => reject(error));
  });
  return p;
}

/* save new debate history */
export function set_debate_history(debateHistory) {
  let p = new Promise((resolve, reject) => {
    db.collection("Debate_History")
      .doc("All_Debate_History")
      .set(debateHistory)
      .then(() => {
        resolve();
      })
      .catch(error => reject(error));
  });
  return p;
}

/* calculate and updates all debate history */
export function update_debate_history() {
  let p = new Promise((resolve, reject) => {
    get_all_debaters(1000, null)
      .then(debaters => {
        const result = {};
        for(const id in debaters) {
          if(!isApprovedUser(debaters[id])) continue;
          result[id] = {};
          result[id]['events'] = {};
          result[id]['average_score'] = 0;
          result[id]['event_count'] = 0;
          result[id]['scored_event_count'] = 0;
          result[id]['total_score'] = 0;
        }
        get_events(defaultFirstDate, defaultLastDate, 1000, null) // get all events in the date range
          .then(events => {
            const eventIds = Object.keys(events);
            eventIds.sort((id1, id2) => events[id1].time < events[id2].time ? 1:-1);
            get_full_history_rec(eventIds, result, 0)
                .then(history => {
                  set_debate_history(history)
                      .then(()=>resolve())
                      .catch(e => reject(e));
                }).catch(e => reject(e));
      }).catch(e => reject(e));
    });
  });
  return p;
}

// Recursive function so that everything is asynchronous
function get_full_history_rec(eventIds, result, index) {
  return new Promise((resolve, reject) => {
    const eventId = eventIds[index];

    // End condition is when all eventIds have been processed
    if(!eventId) resolve(cleanup(result));

    const next = () => resolve(get_full_history_rec(eventIds, result, index + 1));

    get_registrations_for_event(eventId)
      .then(registrations => {
        for (const id in result) {
          result[id]['events'][eventId] = {};
          if (!registrations[id]) result[id]['events'][eventId]['delete_me'] = true;
          else result[id]['events'][eventId]['registration'] = true;
        }
        load_assignment(eventId)
          .then(assignment => {

            // Updating legit rooms
            assignment['TRASH'] = false;
            if(assignment && assignment.rooms) {
              for (const room of assignment.rooms) {
                for (const debater of getDebatersInRoom(room)) {
                  const chair = room['Chair'][0];
                  if(!result[debater.uid]) continue;
                  result[debater.uid]['event_count']++;
                  result[debater.uid]['events'][eventId]['chair_id'] = chair ? room['Chair'][0].uid : '';
                  result[debater.uid]['events'][eventId]['chair_name'] = chair ? getFullName(chair) : '';
                  result[debater.uid]['events'][eventId]['position'] = getPositionInRoom(room, debater);
                }
              }

              // Updating trash list
              const trashListIds = new Set();
              for (const debater of assignment.trashList) trashListIds.add(debater.uid);
              for (const id in result) {
                if (trashListIds.has(id)) {
                  result[id]['events'][eventId] = 'cancelled';
                }
              }
            }

            // Updating feedback
            get_all_feedback_for_event(eventId)
              .then(feedbacks => {
                if (feedbacks) {
                  for (const id in result) {
                    if (!isEmptyFeedback(feedbacks[id]) && typeof result[id]['events'][eventId]==="object"){
                      const score = parseInt(feedbacks[id].score, 10);
                      result[id]['events'][eventId]['score'] = score;
                      result[id]['events'][eventId]['positive_feedback'] = feedbacks[id].positive_feedback;
                      result[id]['events'][eventId]['negative_feedback'] = feedbacks[id].negative_feedback;
                      result[id]['events'][eventId]['notes'] = feedbacks[id].notes;
                      result[id]['scored_event_count']++;
                      result[id]['total_score'] += score;
                      result[id]['average_score'] = result[id]['total_score'] / result[id]['scored_event_count'];
                    }
                    if (!result[id]['average_score']) result[id]['average_score'] = 0;
                  }
                }
                next();
              }).catch(e => reject(e));
          }).catch(e => reject(e));
      }).catch(e => reject(e));
    });
}

function cleanup(result) {
  for(const debaterId in result) {
    for(const eventId in result[debaterId]['events']) {
      if(!result[debaterId]['events'][eventId] || result[debaterId]['events'][eventId]['delete_me']) {
        delete result[debaterId]['events'][eventId];
      }
    }
  }
  return result;
}


