import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ProjectionPositionList from './projectionPositionList'


const styles={
    border:{
        border: '1px solid #9FB3C3',
        borderRadius: '5px',
        padding : '5px',
        margin:4,
        display: 'flex',
        flexDirection :'row',
        height:120,
        width: '50%',

    },
    item:{
        backgroundColor: '#ffffff',
        padding : '10px 5px',
        width: '15%'
        
    },
}
class ProjectionRoom extends Component {
    
    render() { 
        let name = this.props.room.Name;
        if(!name || name === ""){
            name = ''
        }
        return ( 
            <div style={styles.border}>
                <ListItem key={name} style={styles.item} >
                <ListItemText 
                primary={`Location: ${name} `}
                            
                secondary={`Language: ${this.props.room.Language}`}
                    />
                </ListItem>

                <ProjectionPositionList data={this.props.room["Opening Government"]} title='Opening Government'/>
                <ProjectionPositionList data={this.props.room["Opening Opposition"]} title='Opening Opposition'/>
                <ProjectionPositionList data={this.props.room["Closing Government"]} title='Closing Government'/>
                <ProjectionPositionList data={this.props.room["Closing Opposition"]} title='Closing Opposition'/>
                <ProjectionPositionList data={this.props.room["Chair"]} title='Chair'/>
                <ProjectionPositionList data={this.props.room["Panel"]} title='Panel'/>
                </div>
         );
    }
}
 
export default ProjectionRoom;