import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ProjectionListItem from './projectionListItem';

class ProjectionPositionList extends Component {
    state = {  }
    render() { 
        
        return ( 
            <div style={{ 
                            display : 'flex', 
                            flexDirection : 'column', 
                            margin:'0 8px',
                            justifyContent:'center', 
                            textAlign:'center',}}>
            <h5 style={{margin :'0 5px', color:'#919191'}}>{this.props.title}</h5>
            <List >
            {this.props.data.map(listItem=>{
                return <ProjectionListItem item={listItem}/>
            })}
            </List>
            </div>
         );
    }
}
 
export default ProjectionPositionList;