import React, { Component } from "react"
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import {getFullName} from "../Utilities/DebaterUtilities";

/**
 * Profile picture / initials with the option of passing a clickable function
 * Can be clickable if an onClick prop is given
 */
export class DebaterAvatar extends Component {

  render(){
      if(!this.props.debater) return null;
      const size = this.props.size ? this.props.size : 40;
      let avatar;
      const avatarStyle = {
          width: size,
          height: size,
          display: "inline-block",
          color:"#000000",
          textAlign:"center",
          verticalAlign: "middle",
          marginBottom: 0};
      if(this.props.onClick) avatarStyle['cursor'] = 'pointer';

      const onClick = () => {
          if (this.props.onClick) return this.props.onClick(this.props.debater);
          else return ;
      };

      if (this.props.debater.picture) {
          avatar = <Avatar style={avatarStyle}
                           src={this.props.debater.picture}
                           onClick={() => onClick(this.props.debater)}
                           title={this.props.tooltip}/>;
      } else if (!this.props.debater.full_name_eng) {
          avatar = <Avatar style={avatarStyle}
                           onClick={() => onClick(this.props.debater)}
                           title={this.props.tooltip}><PersonIcon style={{fontSize: size/2, marginTop: size/4}}/></Avatar>;
      } else {
          const initials = (
              getFullName(this.props.debater).split(' ')[0].toUpperCase().charAt(0) +
              getFullName(this.props.debater).split(' ')[1].toUpperCase().charAt(0));
          avatar =
              <Avatar style={avatarStyle}
                           onClick={() => onClick(this.props.debater)}
                           title={this.props.tooltip}>
                  <div style={{marginTop: size/4}}>
                            {initials}
                  </div>
              </Avatar>;
      }

      return (
          <div style={{ display: "inline-block", verticalAlign: "middle" }}>
              {avatar}
          </div>
      );
  }
}
