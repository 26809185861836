import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import red from '@material-ui/core/colors/red';
import {DebateHistory} from "../DebateHistory";
import onClickOutside from 'react-onclickoutside'
import {DebaterCardHeader} from "../DebaterCardHeader";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {RegistrationDetails} from "./RegistrationDetails";
import {BasicUserDetails} from "./BasicUserDetails";

const styles = theme => ({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

/**
 * Popper card with a debater's information for a single event
 * Includes registration form, personal details, and debate history
 */
class UserDetailsCard extends React.Component {
    debater;
    subheader;
    manualRegistration;

    constructor(props) {
        super(props);
        this.debater = props.debater;
        this.registration = props.registration;
        this.manualRegistration = props.registration.additionalComments === 'Manually registered by admins';
        this.state = {
            expandedPanel: 1
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openUserDetails = this.openUserDetails.bind(this);

        this.subheader =
            (this.debater.rank) +' | '+
            (this.debater.student ? 'Student':'Non Student') + ' | ' +
            (this.debater.gender !== 'Male' && this.debater.gender !== 'Female' ? 'Gender Unknown' : this.debater.gender);
    }

    handleChange(panelNumber) {
        this.setState({expandedPanel: panelNumber});
    }

    handleClickOutside(event) {
        this.props.close();
    }

    openUserDetails(debater) {
        localStorage.setItem("debater", JSON.stringify(debater));
        window.open("/user", "_blank");
    }

    render() {
        if(!this.debater || !this.registration){
            return(null);
        }

        const panelStyle = {margin: 0, paddingLeft: 10,  paddingRight: 10, width:'100%', borderBottom: '1px solid #ccc'};
        const panelSummaryStyle = {margin: 0, padding: 0, fontWeight: 'bold'};
        const panelContentStyle = {margin: 0, padding: 0, paddingBottom: 10, fontSize: 14, maxWidth: 320};
        return (
            <div style={{
                height: this.state.expandedPanel===3 ? 800 : 500,
                width: 325
            }}>
            <Card>
                <br/>
                <DebaterCardHeader debater={this.debater}
                                   tooltip={"Go to user profile"}
                                   onClick={()=>this.openUserDetails(this.debater)}/>
                <CardContent style={{margin: 0, padding: 0}}>
                    <ExpansionPanel
                        style={panelStyle}
                        square={true}
                        expanded={this.state.expandedPanel===1}
                        onChange={()=>this.handleChange(1)}>
                        <ExpansionPanelSummary style={panelSummaryStyle}>
                            Registration Form
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={panelContentStyle}>
                            <RegistrationDetails registration={this.registration}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel
                        style={panelStyle}
                        expanded={this.state.expandedPanel===2}
                        onChange={()=>this.handleChange(2)}>
                        <ExpansionPanelSummary style={panelSummaryStyle}>
                            Personal Details
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={panelContentStyle}>
                            <BasicUserDetails debater={this.debater}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    {!(this.props.incognito) &&
                    <ExpansionPanel
                        style={panelStyle}
                        expanded={this.state.expandedPanel===3}
                        onChange={()=>this.handleChange(3)}>
                        <ExpansionPanelSummary style={panelSummaryStyle}>
                            Debater History
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={panelContentStyle}>
                            <DebateHistory debater={this.debater} maxHeight={500}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>}

                </CardContent>
            </Card>
            </div>
        );
    }
}

UserDetailsCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(onClickOutside(UserDetailsCard));

