import React, { Component } from "react"
import Paper from "@material-ui/core/Paper";
import {CancelButton, DeleteButton, SubmitButton} from "./Buttons";

/**
 * Custom alert dialog for the "custom-ui" prop of react-confirm-alert component
 * Used for confirmation across the whole app
 */
export class ConfirmAlertBody extends Component {

    render(){
        const defaultTitle = this.props.variant && this.props.variant === 'warning' ? 'Warning' : 'Confirmation';
        let containerStyle = {padding: 15, maxWidth: 300};
        if(this.props.style) {
            containerStyle = Object.assign({},containerStyle,this.props.style);
        }
        return (
            <Paper style={containerStyle}>
                <h1>{this.props.title ? this.props.title : defaultTitle}</h1>
                <p style={{color: '#666666'}}>{this.props.message}</p>
                <div align={"right"}>
                    <CancelButton
                        label={"No"}
                        onClick={this.props.onClose}/>

                    {this.props.variant==="warning" &&
                    <DeleteButton
                        label={"Yes"}
                        onClick={() => {
                            this.props.onSubmit();
                            this.props.onClose();
                        }}/>}

                    {(!this.props.variant || this.props.variant!=="warning") &&
                    <SubmitButton
                        label={"Yes"}
                        onClick={() => {
                            this.props.onSubmit();
                            this.props.onClose();
                        }}/>}
                </div>
            </Paper>
        );
    }
}
