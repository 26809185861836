import React, { Component } from "react";
import {getScoreColor} from "../Utilities/DebaterUtilities";

export function getScoreBubble(score) {
    if(!score || score === 0) return " - ";
    const scoreStyle = {
        borderRadius: '50%',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        width: 8,
        height: 20,
        verticalAlign: 'top',
        align: 'center',
        display: 'inline',
        color: '#000000',
        fontWeight:'bold',
        backgroundColor: getScoreColor(score)
    };
    return <div style={scoreStyle}>{score.toString().substr(0, 3)}</div>
}

/**
 * Textual representation of one feedback
 */
export class FeedbackDetails extends Component {

  render() {

      const light={color: '#777777', lineHeight: 2};
      return(
          <div style={{fontSize: 14, width: '100%', display: 'inline'}}>
              Score: {getScoreBubble(this.props.feedback.score)}

              <div>Chair: <span style={light}>{this.props.feedback.chairName}</span></div>
              {this.props.feedback.position &&
              <div>Position: <span style={light}>{this.props.feedback.position}</span></div>}

              <div>Positive Feedback: <span style={light}>{this.props.feedback.positive_feedback}</span></div>
              <div>Negative Feedback: <span style={light}>{this.props.feedback.negative_feedback}</span></div>
              <div>Notes: <span style={light}>{this.props.feedback.notes}</span></div>
          </div>
      )
    }
}
