import React, { Component } from 'react';
import { Droppable} from 'react-beautiful-dnd';
import MyDraggable from './myDraggable';

const grid = 4;

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#ECECEC',
    padding: grid,
    marginLeft: 10,

    width : 170,
    minHeight: 108,
    maxHeight : 500,
});

const getChairListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#ECECEC',
    padding: grid,
    marginLeft: 10,
    width : 170,
    height: '100%',
    minHeight : 72
});

/*
* A droppable component containing a list of debaters (see react-beautiful-dnd for further documentation and use)
 */
class MyDroppable extends Component {
    state = {  }
    render() {
        return (


            <Droppable droppableId={this.props.droppableId}>

                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={this.props.chair ? getChairListStyle(snapshot.isDraggingOver) : getListStyle(snapshot.isDraggingOver)}>
                            {this.props.data.map((item, index) => {

                                if(this.props.droppableId === "droppable"){
                                    if(item.rank === this.props.filter || this.props.filter === "All"){
                                        return(<MyDraggable
                                            incognito={this.props.incognito}
                                            item ={item}
                                            key={item.uid}
                                            draggableId={item.uid}
                                            index={index}
                                            changeDebaterId={this.props.changeDebaterId}
                                            registrations={this.props.registrations}
                                            updateRegistaration={this.props.updateRegistaration}/>)
                                    }
                                    else{
                                        return null;
                                    }
                                } else{

                                return(
                                <MyDraggable
                                    incognito={this.props.incognito}
                                    item ={item}
                                    key={item.uid}
                                    draggableId={item.uid}
                                    index={index}
                                    changeDebaterId={this.props.changeDebaterId}
                                    registrations={this.props.registrations}
                                    updateRegistaration={this.props.updateRegistaration}/>

                            )}})}

                        </div>
                    )}
                </Droppable>

         );
    }
}

export default MyDroppable;
