import React, { Component } from "react"
import {getFullName} from "../Utilities/DebaterUtilities";
import {DebaterAvatar} from "./DebaterAvatar";

/**
 * Compact display of profile picture, and full name only
 * Can be clickable if an onClick prop is given
 */
export class DebaterHeader extends Component {

  render(){
      if(!this.props.debater) return null;
      const style = { padding: 3, align: "center", verticalAlign: "middle" };
      const size = this.props.size ? this.props.size : 40;
      return (
          <table><tbody><tr>
              <td style={style}>
                  <DebaterAvatar
                        debater={this.props.debater}
                        size={size}
                        onClick={this.props.onClick}
                        tooltip={this.props.tooltip}/>
              </td>
              <td style={style}>
                  {this.props.onClick &&
                  <span onClick={this.props.onClick}
                        style={{color:"#3377AA", cursor: 'pointer'}}>
                        {getFullName(this.props.debater)}
                  </span>}
                  {!this.props.onClick && getFullName(this.props.debater)}
              </td>
          </tr></tbody></table>
      );
  }
}
