import React, { Component } from "react";
import RegistrationPractice from "./RegistrationPractice";
import { FeedbackFormMenu } from "./FeedbackFormMenu";
import MyAssignment from "./MyAssignment";
import RegistrationLesson from "./RegistrationLesson";

/**
 * Given an event and it's life-cycle status of the user -
 * displays the relevant component (registration, assignment, feedback, etc.)
 */
class EventForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if(!this.props.event) return null;
        const status = this.props.event.status;
        if (this.props.event.event_type === "Lesson" &&
            status !== "cancelled") {
            return (
                <RegistrationLesson
                    event={this.props.event}
                    checkedIn={this.props.event.checkedIn}
                    onSubmit={this.props.onSubmitRegistration}
                    onCancelOnTime={this.props.onCancelOnTime}
                    onCancel={this.props.onCancel}
                    onCheckIn={this.props.onCheckIn}
                    registered={this.props.event.status !== "new"}
                />
            );
        }
        if(status === "new" || status === "edit") {
            return (
                <RegistrationPractice
                    new={status === "new"}
                    debateJudge="No preference"
                    language="No preference"
                    additionalComments=""
                    checkedIn={this.props.event.registration.checkedIn}
                    event={this.props.event}
                    onSubmit={this.props.onSubmitRegistration}
                    onCancelOnTime={this.props.onCancelOnTime}
                    onCancel={this.props.onCancel}
                    onCheckIn={this.props.onCheckIn}
                />
            );
        }

        if(status === "assigned") {
            return (
                <MyAssignment
                    user={this.props.user}
                    checkedIn={this.props.event.checkedIn}
                    onCancelOnTime={this.props.onCancelOnTime}
                    onCancel={this.props.onCancel}
                    onCheckIn={this.props.onCheckIn}
                    event={this.props.event}
                />
            );
        }

        if(status === "feedback") {
            return (
                <FeedbackFormMenu
                    feedback={this.props.feedback}
                    onFeedbackSave={this.props.onFeedbackSave}/>
            );
        }
        return null;
    }
}

export default EventForm;
