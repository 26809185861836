import React, { Component } from "react";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import { formatDateTime, tsToDate } from "../Utilities/DateTimeUtils";
import { handleError, isMobile } from "../Utilities/OtherUtils";
import DoneIcon from "@material-ui/icons/CheckCircle";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlertBody } from "../Common/ConfirmAlert";
import {
  add_registration,
  cancel_registration,
  check_in,
} from "../../Backend/registratinoBackendFunctions";
import {
  CancelAttendanceButton,
  CheckInButton,
  SubmitButton,
} from "../Common/Buttons";
import {
  checkInStarted,
  registrationEnded,
} from "../EventManagementComps/EventTimelineStatus";
import { getCheckInIcon } from "./EventListItem";

/**
 * One component for the whole life-cycle of a lesson (registration, cancel and check-in)
 */
class RegistrationLesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedIn: this.props.checkedIn,
    };

    this.cancelRegistration = this.cancelRegistration.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    this.setState({ checkedIn: nextProps.checkedIn || false });
  }

  register = () => {
    const registrationData = {
      id: this.props.event.id,
      debaterRegistrationDate: Date.now(),
    };

    add_registration(registrationData)
      .then(() => toast.info("Successfully registered to the lesson!"))
      .catch((error) => handleError(error));
    this.props.onSubmit();
  };

  cancelRegistration() {
    this.setState({ edit_mode: true });

    const registrationData = {
      id: this.props.event.id,
      ...this.state,
      debaterRegistrationDate: Date.now(),
    };

    if (registrationEnded(this.props.event)) {
      registrationData.cancelled = true;
      registrationData.checkedIn = false;
      add_registration(registrationData)
        .then(() => {
          this.props.onCancel();
          toast.info("Cancelled your registration");
        })
        .catch((error) => handleError(error));
    } else {
      cancel_registration(registrationData)
        .then(() => {
          this.setState({ checkedIn: false });
          this.props.onCancelOnTime();
          toast.info("Cancelled your registration");
        })
        .catch((error) => handleError(error));
    }
  }

  onCheckIn = () => {
    const registrationData = {
      id: this.props.event.id,
    };
    check_in(registrationData)
      .then(() => {
        this.setState({ checkedIn: true });
        this.props.onCheckIn();
        toast.info("You are checked in!");
      })
      .catch((error) => handleError(error));
  };

  popupCancelConfirm = () => {
    const message = "Are you sure you want to cancel your registration?";
    confirmAlert({
      onClickOutside: () => {},
      customUI: ({ onClose }) => (
        <ConfirmAlertBody
          onClose={onClose}
          message={message}
          onSubmit={() => this.cancelRegistration()}
          variant={"warning"}
        />
      ),
    });
  };

  render() {
    return (
      <div className="app-container-2">
        <div align="left">
          <Paper style={{ padding: isMobile() ? 10 : 20 }}>
            <table style={{ padding: 0, margin: 0, borderSpacing: 0 }}>
              <tbody>
                <tr>
                  <td style={{ padding: 0 }}>
                    {this.props.registered &&
                      (this.state.checkedIn ? (
                        getCheckInIcon(32)
                      ) : (
                        <DoneIcon
                          style={{
                            color: "#00CC00",
                            fontSize: 32,
                            marginRight: 5,
                          }}
                        />
                      ))}
                  </td>
                  <td>
                    <h2>
                      {this.state.checkedIn
                        ? "Checked In"
                        : this.props.registered
                        ? "Registered"
                        : "Register to Lesson"}
                    </h2>
                  </td>
                </tr>
              </tbody>
            </table>

            <h4 style={{ marginTop: "1%" }}>
              Start Time:
              <span style={{ fontWeight: "normal", lineHeight: 2 }}>
                {` ${formatDateTime(tsToDate(this.props.event.time))}`}{" "}
                {this.props.event.name}
              </span>
              <br />
              Subject:{" "}
              <span style={{ fontWeight: "normal" }}>
                {this.props.event.event_subject}
              </span>
              <br />
              Gathering location:{" "}
              <span style={{ fontWeight: "normal", lineHeight: 1 }}>
                {console.log(this.props.event)}
                {this.props.event.location.includes("http") ? (
                  <a
                    href={this.props.event.location}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.event.location}
                  </a>
                ) : (
                  this.props.event.location
                )}
              </span>
            </h4>

            {!this.props.registered && (
              <h4 style={{ color: "#E74C3C" }}>
                Deadline for Registration:
                <span style={{ fontWeight: "normal" }}>{` ${formatDateTime(
                  tsToDate(this.props.event.registration_end)
                )}`}</span>
              </h4>
            )}

            <div align={"center"}>
              {!this.props.registered && (
                <SubmitButton onClick={this.register} label={"Register"} />
              )}

              {this.props.registered && (
                <div>
                  <CancelAttendanceButton
                    onClick={this.popupCancelConfirm}
                    label={"Cancel"}
                  />
                  {checkInStarted(this.props.event) && (
                    <CheckInButton
                      disabled={this.state.checkedIn}
                      onClick={this.onCheckIn}
                    />
                  )}

                  <br />
                  <br />
                </div>
              )}
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default RegistrationLesson;
