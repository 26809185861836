import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import {
  add_registration,
  cancel_registration,
} from "../../Backend/BackendFunctions";
import { formatDateTime, tsToDate } from "../Utilities/DateTimeUtils";
import { handleError, isMobile } from "../Utilities/OtherUtils";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlertBody } from "../Common/ConfirmAlert";
import {
  CancelAttendanceButton,
  CancelButton,
  CheckInButton,
  SubmitButton,
} from "../Common/Buttons";
import DoneIcon from "@material-ui/icons/CheckCircle";
import {
  checkInStarted,
  registrationEnded,
} from "../EventManagementComps/EventTimelineStatus";
import { getCheckInIcon } from "./EventListItem";

/**
 * One component for the whole life-cycle of a practice, except viewing assignment (registration, edit, cancel and check-in)
 */
class RegistrationPractice extends Component {
  languages = ["Hebrew", "English", "No preference"];

  debaterOrJudge = ["Debate", "Judge", "No preference"];

  constructor(props) {
    super(props);
    this.state = {
      checkedIn: this.props.checkedIn || false,
      edit_mode: true,
      view_mode: false,
      id: this.props.event.id,
      debateJudge: this.props.debateJudge,
      language: this.props.language,
      additionalComments: this.props.additionalComments,
      has_error: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.cancelRegistration = this.cancelRegistration.bind(this);
  }

  componentDidMount() {
    this.setState({ edit_mode: this.props.new });
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    this.setState({ checkedIn: nextProps.checkedIn || false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.props.event.id) {
      this.setState({ edit_mode: this.props.new });
      this.updateState();
    }
  }

  updateState = () => {
    this.setState({
      id: this.props.event.id,
      debateJudge: this.props.debateJudge,
      language: this.props.language,
      additionalComments: this.props.additionalComments,
    });
  };

  handleChange(e, field) {
    this.setState(
      {
        [field]: e.target.value,
      },
      function () { }
    );
  }

  clearAll() {
    let noPref = "No preference";
    this.setState({
      debateJudge: noPref,
      language: noPref,
      additionalComments: "",
      edit_mode: this.props.new && !registrationEnded(this.props.event),
      view_mode: false,
    });
  }

  //backend function to cancel registration (with id of registration?),
  //makes the registration status back to new
  cancelRegistration() {
    this.clearAll();
    this.setState({ edit_mode: !registrationEnded(this.props.event) });

    const registrationData = {
      id: this.props.event.id,
      ...this.state,
      debaterRegistrationDate: Date.now(),
    };

    if (registrationEnded(this.props.event)) {
      registrationData.cancelled = true;
      registrationData.checkedIn = false;
      add_registration(registrationData)
        .then(() => {
          this.props.onCancel();
          toast.info("Cancelled your registration");
        })
        .catch((error) => handleError(error));
    } else {
      cancel_registration(registrationData)
        .then(() => {
          this.props.onCancelOnTime();
          toast.info("Cancelled your registration");
        })
        .catch((error) => handleError(error));
    }
  }

  onCheckIn = () => {
    const registrationData = {
      id: this.props.event.id,
      debateJudge: this.state.debateJudge,
      language: this.state.language,
      additionalComments: this.state.additionalComments,
      debaterRegistrationDate: Date.now(),
      checkedIn: true,
    };
    add_registration(registrationData)
      .then(() => {
        this.setState({ checkedIn: true });
        this.props.onCheckIn();
        toast.info("You are checked in!");
      })
      .catch((error) => handleError(error));
  };

  popupCancelConfirm = () => {
    const message = "Are you sure you want to cancel your registration?";
    confirmAlert({
      onClickOutside: () => { },
      customUI: ({ onClose }) => (
        <ConfirmAlertBody
          onClose={onClose}
          message={message}
          onSubmit={() => this.cancelRegistration()}
          variant={"warning"}
        />
      ),
    });
  };

  handleSubmit() {
    this.setState({ edit_mode: false });

    // get registration date from props, user preference form state, and current date for registration time
    const registrationData = {
      id: this.props.event.id,
      ...this.state,
      debaterRegistrationDate: Date.now(),
    };

    add_registration(registrationData)
      .then(() => toast.info("Details Successfully Submitted"))
      .catch((error) => handleError(error));

    this.props.onSubmit();
  }

  render() {
    return (
      <div className="app-container-2">
        <div align={"left"}>
          <Paper style={{ padding: isMobile() ? 10 : 20 }}>
            <div>
              <table style={{ padding: 0, margin: 0, borderSpacing: 0 }}>
                <tbody>
                  <tr>
                    {!this.props.new && !this.state.edit_mode && (
                      <td style={{ padding: 0, width: "5%" }}>
                        {this.state.checkedIn ? (
                          getCheckInIcon(32)
                        ) : (
                          <DoneIcon
                            style={{
                              color: "#00CC00",
                              fontSize: 32,
                              marginRight: 5,
                            }}
                          />
                        )}
                      </td>
                    )}

                    <td style={{ width: "80%" }}>
                      <h2>
                        {this.state.checkedIn && !this.state.edit_mode
                          ? "Checked In"
                          : this.props.new
                            ? "Register to Practice"
                            : this.state.edit_mode
                              ? "Edit your Registration"
                              : "Registered"}
                      </h2>
                    </td>

                    {!this.state.edit_mode &&
                      !this.state.view_mode &&
                      !registrationEnded(this.props.event) && (
                        <td style={{ width: "15%" }}>
                          <CancelButton
                            onClick={() => this.setState({ edit_mode: true })}
                            label={"Edit"}
                          />
                        </td>
                      )}

                    {!this.state.edit_mode &&
                      registrationEnded(this.props.event) && (
                        <td style={{ width: "15%" }}>
                          <CancelButton
                            onClick={() =>
                              this.setState({
                                view_mode: !this.state.view_mode,
                              })
                            }
                            label={!this.state.view_mode ? "View" : "Close"}
                          />
                        </td>
                      )}
                  </tr>
                </tbody>
              </table>

              <h4 style={{ marginTop: "1%" }}>
                Start Time:
                <span style={{ fontWeight: "normal", lineHeight: 2 }}>
                  {` ${formatDateTime(tsToDate(this.props.event.time))}`}
                </span>
                <br />
                Event:{" "}
                <span style={{ fontWeight: "normal", lineHeight: 2 }}>
                  {this.props.event.event_type}
                </span>
                <br />
                Gathering location:{" "}
                <span style={{ fontWeight: "normal", lineHeight: 1 }}>
                  {console.log(this.props.event)}
                  {this.props.event.location && this.props.event.location.includes("http") ? (
                    <a
                      href={this.props.event.location}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.props.event.location}
                    </a>
                  ) : (
                    this.props.event.location
                  )}
                </span>
                <br />
              </h4>

              <p style={{ color: this.props.new ? "#E74C3C" : "#777777" }}>
                {!registrationEnded(this.props.event)
                  ? (this.props.new
                    ? "Registration Deadline: "
                    : "You can edit your registration until ") +
                  formatDateTime(tsToDate(this.props.event.registration_end))
                  : "Registration Over"}
              </p>

              {(this.state.edit_mode || this.state.view_mode) && (
                <div>
                  <TextField
                    style={{ width: isMobile() ? "100%" : "48%", margin: 0 }}
                    id="debateJudge"
                    select
                    label={"Debate / Judge"}
                    value={this.state.debateJudge}
                    disabled={this.state.view_mode}
                    onChange={(e) => this.handleChange(e, "debateJudge")}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.debaterOrJudge.map((val) => (
                      <MenuItem value={val} key={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </TextField>

                  {isMobile() && <p />}
                  {!isMobile() && <span style={{ marginLeft: "4%" }}>ֵ</span>}

                  <TextField
                    style={{ width: isMobile() ? "100%" : "48%", margin: 0 }}
                    id="language"
                    select
                    label={"Preferred Language"}
                    value={this.state.language}
                    disabled={this.state.view_mode}
                    onChange={(e) => this.handleChange(e, "language")}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.languages.map((val) => (
                      <MenuItem value={val} key={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    style={{ width: "100%" }}
                    onChange={(e) => this.handleChange(e, "additionalComments")}
                    value={this.state.additionalComments}
                    disabled={this.state.view_mode}
                    label="Additional Comments"
                    margin="normal"
                    variant="outlined"
                    multiline={true}
                    rows="3"
                  />
                  <br />

                  {this.state.edit_mode && (
                    <div align={"center"}>
                      <CancelButton
                        onClick={this.clearAll}
                        label={this.props.new ? "Clear" : "Cancel"}
                      />
                      <SubmitButton
                        onClick={this.handleSubmit}
                        label={this.props.new ? "Register" : "Save"}
                      />
                    </div>
                  )}
                </div>
              )}

              {!this.state.edit_mode && (
                <div align={"center"}>
                  <CancelAttendanceButton
                    onClick={this.popupCancelConfirm}
                    label={"Cancel"}
                  />
                  {checkInStarted(this.props.event) && (
                    <CheckInButton
                      disabled={this.state.checkedIn}
                      onClick={this.onCheckIn}
                    />
                  )}
                </div>
              )}

              <br />
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default RegistrationPractice;
