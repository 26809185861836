import React, { Component } from "react"
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

/**
 * Custom component for a search text field
 */
export class TextSearch extends Component {

    constructor(props){
        super(props);
        this.textInput = React.createRef();
        this.state = {
            focus: false,
            open: this.props.open,
            value: props.value ? props.value : ''
        };

        this.changeValue = this.changeValue.bind(this);
        this.focus = this.focus.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    focus() {
        this.textInput.current.focus();
    }

    close() {
        this.changeValue('');
        this.setState({open: this.props.open || false});
    }

    open() {
        this.setState({open: true},()=>this.focus());
    }

    changeValue(value) {
        const that = this;
        this.setState({value: value}, () => {
            // Timeout makes the re-rendering of this state happen before the prop update which could be slower
            setTimeout(function() {
                that.props.onChange(value);
            },this.props.delay ? this.props.delay : 1000);
        });
    }

    render(){
        const thin = {margin: 0, padding: 0};
        const inputProps = {
            endAdornment: (
                <InputAdornment style={thin}>
                    {this.state.open &&
                    <IconButton style={thin}
                                onClick={this.close}>
                        <ClearIcon style={thin}/>
                    </IconButton>}
                </InputAdornment>
            ),
            startAdornment: (
                <InputAdornment style={thin}>
                    <IconButton style={thin}
                                onClick={this.focus}>
                        <SearchIcon  style={thin}/>
                    </IconButton>
                </InputAdornment>
            )
        };

        return (
            <div style={{margin: 0, padding: 0, display: 'inline'}}>
                {this.state.open &&
                <TextField label={this.props.label}
                           style={this.props.style}
                           value={this.state.value}
                           disabled={this.props.disabled}
                           inputRef={this.textInput}
                           onChange={(e)=>this.changeValue(e.target.value)}
                           InputProps={inputProps}
                />}

                {!this.state.open &&
                <IconButton onClick={this.open}>
                    <SearchIcon style={thin}/>
                </IconButton>}
            </div>
        );
    }
}
