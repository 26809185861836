import React from "react";
import { Route } from "react-router";
import { Login } from "../pages/Login/Login";
import { UserDetails } from "../pages/UserDetails";
import { Homepage } from "../pages/Homepage";
import { EventsManagement } from "../pages/EventsManagement";
import Announcements from "../pages/Announcements";
import { UserManagement } from "../pages/UserManagement";
import { AssignmentPage } from "../pages/Assignment";
import { ApproveNewUsers } from "../pages/ApproveNewUsers";
import ApprovalPage from "../pages/ApprovalPage";
import AboutUs from "../pages/AboutUs";
import { Unsubscribe } from "../pages/Unsubscribe";

export function routingOptions(user, onUpdate) {
  const permissions = user.permissions;
  if (permissions === "Registration") {
    return <UserDetails user={user} userId={user.uid} onLoad={onUpdate} />;
  } else if (permissions === "Pending") {
    return <ApprovalPage user={user} onLoad={onUpdate} />;
  } else if (permissions === "Login") {
    return <Route path="/" render={props => <Login onLoad={onUpdate} />} />;
  } else if (permissions === "Manager") {
    return (
      <div>
        <Route
          path="/unsubscribe"
          render={props => (
            <Unsubscribe {...props} user={user} onLoad={onUpdate} />
          )}
        />
        <Route
          path="/AboutUs"
          render={props => <AboutUs {...props} user={user} onLoad={onUpdate} />}
        />
        <Route
          exact
          path="/"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/home"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/events"
          render={props => (
            <EventsManagement {...props} user={user} onLoad={onUpdate} />
          )}
        />
        <Route
          path="/assignment"
          render={props => (
            <AssignmentPage {...props} user={user} onLoad={onUpdate} />
          )}
        />
        <Route
          path="/user"
          render={props => (
            <UserDetails
              {...props}
              user={user}
              userId={user.uid}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/profile"
          render={props => (
            <UserDetails
              {...props}
              user={user}
              userId={user.uid}
              debater={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/new_users"
          render={props => (
            <ApproveNewUsers
              {...props}
              user={user}
              onUpdate={onUpdate}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/Announcements"
          render={props => (
            <Announcements
              {...props}
              permissions={permissions}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/login"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
      </div>
    );
  } else if (permissions === "Student") {
    return (
      <div>
        <Route
          path="/unsubscribe"
          render={props => (
            <Unsubscribe {...props} user={user} onLoad={onUpdate} />
          )}
        />
        <Route
          path="/AboutUs"
          render={props => <AboutUs {...props} user={user} onLoad={onUpdate} />}
        />
        <Route
          path="/profile"
          render={props => (
            <UserDetails
              {...props}
              user={user}
              userId={user.uid}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          exact
          path="/"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/home"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/dashboard"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/login"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
      </div>
    );
  } else if (permissions === "Admin") {
    return (
      <div>
        <Route
          path="/unsubscribe"
          render={props => (
            <Unsubscribe {...props} user={user} onLoad={onUpdate} />
          )}
        />
        <Route
          path="/AboutUs"
          render={props => <AboutUs {...props} user={user} onLoad={onUpdate} />}
        />
        {
          <Route
            path="/users"
            render={props => (
              <UserManagement {...props} user={user} onLoad={onUpdate} />
            )}
          />
        }
        {
          <Route
            path="/new_users"
            render={props => (
              <ApproveNewUsers
                {...props}
                user={user}
                onUpdate={onUpdate}
                onLoad={onUpdate}
              />
            )}
          />
        }
        <Route
          path="/events"
          render={props => (
            <EventsManagement {...props} user={user} onLoad={onUpdate} />
          )}
        />
        <Route
          path="/Announcements"
          render={props => (
            <Announcements
              {...props}
              permissions={permissions}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/assignment"
          render={props => (
            <AssignmentPage {...props} user={user} onLoad={onUpdate} />
          )}
        />
        <Route
          path="/user"
          render={props => (
            <UserDetails
              {...props}
              user={user}
              userId={user.uid}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/profile"
          render={props => (
            <UserDetails
              {...props}
              user={user}
              userId={user.uid}
              debater={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          exact
          path="/"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/home"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/dashboard"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
        <Route
          path="/login"
          render={props => (
            <Homepage
              {...props}
              permissions={permissions}
              user={user}
              onLoad={onUpdate}
            />
          )}
        />
      </div>
    );
  }
}
