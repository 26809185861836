import React, { Component } from 'react';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {FeedbackDetails} from "./FeedbackDetails";

/**
 * Compact popper display of a one feedback
 */
export default class FeedbackPopper extends Component {

    render() {
        if(!this.props.feedback) return(null);
        return (
            <Popper
                open={true}
                placement='bottom-start'
                anchorEl={this.props.anchorEl}
                transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div style={{margin: 0, border: '1px solid #aaa', borderRadius: '2%'}}>
                            <Card style={{padding: 5}}>
                                <CardContent>
                                    <h4>Feedback for {this.props.feedback.debaterName}</h4>
                                    <FeedbackDetails feedback={this.props.feedback} />
                                </CardContent>
                            </Card>
                        </div>
                    </Fade>
                )}
            </Popper>
        );
    }
}

