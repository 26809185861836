import { db } from "./fire";

/* this page contains all backend functions regarding assignment to events
the assignments are saved in two places:
1. user assignment in a specific event is saved under "Debater/debateid/MyEvents/eventsid"
the doc contains an object with Assignment field with the users assignment room data (with chair, languege and other participnets..)
2. all the assignment rellevant to the specific event are saved at "Events/eventid/EventDat/Assignment"
Assignment is an object with all event rooms

user query their own room assignment and admin function query all the assignment for the event
*/

/* this function saves the assingment only at "Events/eventid/EventDat/Assignment"
only admins will see the assingment data at the assingment page, while regular users will not see their aassingment
*/
export function save_assignment(assignment, eventId) {
  let assignmentRef = db
    .collection("Events")
    .doc(eventId)
    .collection("EventData")
    .doc("Assignments");
  return assignmentRef.set({ published: false, ...assignment });
}

/* this function loads the assingment from "Events/eventid/EventDat/Assignment"
this is fot  admins use in the assingment page
*/
export function load_assignment(eventId) {
  let p = new Promise((resolve, reject) => {
    let assignmentRef = db
      .collection("Events")
      .doc(eventId)
      .collection("EventData")
      .doc("Assignments");
    assignmentRef
      .get()
      .then(assign => {
        if (assign.exists) {
          resolve(assign.data());
        } else {
          resolve({});
        }
      })
      .catch(error => reject(error));
  });
  return p;
}

/* this function saves the assingment at at "Events/eventid/EventDat/Assignment"
and also at "Debater/debateid/MyEvents/eventsid"
after assignment are published all the userscan see their aassingment at the homepage
*/
export function publish_assignment(assignment, event) {
  let batch = db.batch();

  let assignmentRef = db
    .collection("Events")
    .doc(event.id)
    .collection("EventData")
    .doc("Assignments");

  batch.set(assignmentRef, { ...assignment, published: true });

  let rooms = assignment.rooms;
  for (let room in rooms) {
    //update assignment in all participants
    let debaters = getDebatersInRoom(rooms[room]);
    for (let debater in debaters) {
      if (!debaters[debater].uid.includes("FAKE")) {
        let debaterRef = db
          .collection("Debaters")
          .doc(debaters[debater].uid)
          .collection("MyEvents")
          .doc(event.id);
        batch.update(debaterRef, {
          assignment: rooms[room]
        });
      }
    }
  }

  return batch.commit();
}

/* returns user's position in the room, used in debate history component*/
export function getPositionInRoom(room, debater) {
  if (room.Chair[0] && room.Chair[0].uid === debater.uid) return "Chair";
  for (const debater2 of room.Panel)
    if (debater2.uid === debater.uid) return "Panel";
  for (const debater2 of room["Opening Government"])
    if (debater2.uid === debater.uid) return "Opening Government";
  for (const debater2 of room["Closing Government"])
    if (debater2.uid === debater.uid) return "Closing Government";
  for (const debater2 of room["Opening Opposition"])
    if (debater2.uid === debater.uid) return "Opening Opposition";
  for (const debater2 of room["Closing Opposition"])
    if (debater2.uid === debater.uid) return "Closing Opposition";
  return null;
}

/* returns an array of all the debaters in a room (used in assignment components) */
export function getDebatersInRoom(room) {
  const debaters = [];
  if (room.Chair[0]) {
    debaters.push(room.Chair[0]);
  }
  let debater;
  for (debater in room.Panel) {
    debaters.push(room.Panel[debater]);
  }
  for (debater in room["Opening Government"]) {
    debaters.push(room["Opening Government"][debater]);
  }
  for (debater in room["Opening Opposition"]) {
    debaters.push(room["Opening Opposition"][debater]);
  }
  for (debater in room["Closing Government"]) {
    debaters.push(room["Closing Government"][debater]);
  }
  for (debater in room["Closing Opposition"]) {
    debaters.push(room["Closing Opposition"][debater]);
  }
  return debaters;
}
