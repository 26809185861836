import React, { Component } from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";

/**
 * Tabs for switching between the user's home page "feeds"
 */
class HomepageTabs extends Component {
    constructor(props) {
        super(props);
        this.getAllTabsStyle = this.getAllTabsStyle.bind(this);
        this.getTabStyle = this.getTabStyle.bind(this);

    }

    getAllTabsStyle(feedbackCount){
        if(this.props.mobile) {
            return {
                elevation: 0,
                shadowOpacity: 0,
                borderBottomWidth: 0,
                boxShadow: "0 0 0 0",
                width: "100%",
                position: "relative"
            };
        }
        return {
            borderBottomWidth: 0,
            borderBottom: 'none',
            boxShadow: "0px -1px 2px 1px #DDDDDD",
            borderTopRightRadius: '2%',
            borderTopLeftRadius: '2%',
            marginTop: "1%",
            width: feedbackCount === 0 ? 320 : 480,
            position: "relative"
        };
    }

    getTabStyle(chairFeedbackLength){
        if(this.props.mobile) {
            return {
                width: chairFeedbackLength === 0 ? "50%" : "33%",
                fontSize: chairFeedbackLength === 0 ? 14 : 10,
                fontWeight: 'bold'
            };
        }
        return {
            fontWeight: 'bold'
        };
    }

    render() {
        return (
            <Paper
                square
                style={this.getAllTabsStyle(this.props.feedbackCount)}>
                <Tabs
                    value={this.props.tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.props.handleChangeTab}>
                    <Tab
                        style={this.getTabStyle(this.props.feedbackCount)}
                        value="events"
                        label={
                            <Badge
                                color="secondary"
                                badgeContent={this.props.newEventsCount}>
                                <span style={{marginRight: 8}}>My Events</span>
                            </Badge>
                        }/>
                    <Tab
                        style={this.getTabStyle(this.props.feedbackCount)}
                        value="announcements"
                        label="Announcements"/>
                    {this.props.feedbackCount > 0 && (
                        <Tab
                            style={this.getTabStyle(this.props.feedbackCount)}
                            value="feedbacks"
                            label={
                                <Badge
                                    color="secondary"
                                    badgeContent={this.props.feedbackCount}>
                                    <span style={{marginRight: 8}}>Feedbacks</span>
                                </Badge>
                            }
                        />
                    )}
              </Tabs>
            </Paper>

         );
    }
}

export default HomepageTabs;
