import React, { Component } from "react"

/**
 * Plain textual summary of a debater's important personal details
 */
export class BasicUserDetails extends Component {

  render() {
      if(!this.props.debater) return <h4>Details not Found</h4>;

      const light={color: '#777777', lineHeight: 2};
      return(
          <div style={{fontSize: 14, width: '100%'}}>
              <div>Club: <span style={light}>{this.props.debater.club}</span></div>
              <div>Started: <span style={light}>{this.props.debater.start_year}</span></div>
              <div>Official Judgement Level: <span style={light}>{this.props.debater.official_judgement_level}</span></div>
              <div>Phone Number: <span style={light}>{this.props.debater.phone_number}</span></div>
              <div>Email: <span style={light}>{this.props.debater.email}</span></div>
          </div>
      )
    }
}
