import React from "react";
import { NavLink } from "react-router-dom";

// main toolbar Component
const SignOutLinks = () => (
  <ul>
    <li>
      <NavLink to="/Login">Login</NavLink>
    </li>
  </ul>
);

export default SignOutLinks;
