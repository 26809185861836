import React, { Component } from "react"

/**
 * Plain textual representation of a registration form
 */
export class RegistrationDetails extends Component {

  render() {
      if(!this.props.registration) return <span>Details not Found</span>;
      if(this.props.registration.manualRegistration || this.props.registration.additionalComments==="Manually registered by admins")
          return <span>Manually Registered by Admins</span>;

      const light={color: '#777777', lineHeight: 2};
      return(
          <div style={{fontSize: 14, width: '100%'}}>
              <div>Preferred Role: <span style={light}>{this.props.registration.debateJudge}</span></div>
              <div>Preferred Language: <span style={light}>{this.props.registration.language}</span></div>
              <div>Other Requests: <span style={light}>{this.props.registration.additionalComments}</span></div>
          </div>
      )
    }
}
