import emptyRoom from "./emptyRoom";
import { tsToDate } from "../Utilities/DateTimeUtils";
import validateRoom from "./roomValidation";

/*Refactors the assignment data for easy display on page */
export function configureInitialState(
  eventId,
  event,
  registrations,
  allDebaters,
  prevAssignment,
  debateHistory
) {
  let configuredState = {};
  if (
    !eventId ||
    !event ||
    !registrations ||
    !allDebaters ||
    !prevAssignment ||
    !debateHistory
  ) {
    new Error("One or more parameters is invalid");
  } else {
    // parameters are valid
    /*initializing the parameters */

    event = setEventFields(eventId, event);

    if (!(Object.keys(prevAssignment).length === 0)) {
      // a previous draft assignment exists
      configuredState = updateExisitingAssignment(
        prevAssignment,
        registrations,
        allDebaters,
        debateHistory
      );
    } else {
      // no previous draft exists
      configuredState = creatInitialAssignment(
        registrations,
        allDebaters,
        debateHistory
      );
    }
    for (let room in configuredState.rooms) {
      configuredState.rooms[room] = validateRoom(configuredState.rooms[room]);
    }
    configuredState["event"] = event;
    configuredState["lodaing"] = false;
    configuredState["registrations"] = registrations;
    allDebaters = Object.values(allDebaters);
  }
  configuredState["allDebaters"] = allDebaters;
  configuredState["debaters"] = configuredState["debatersToAssign"];
  configuredState["history"] = debateHistory;
  return configuredState;
}

/*Sets the correct event fields */
function setEventFields(eventId, event) {
  event = { ...event, id: eventId };
  event.date = tsToDate(event.date);
  event.time = tsToDate(event.time);
  return event;
}

/*updates the exisiting assignment draft to include registration if they have updated in the meantime */
function updateExisitingAssignment(
  assignment,
  registrations,
  allDebaters,
  history
) {
  let {
    rooms,
    trashList,
    comment,
    previouslyRegistered,
    feedbackRequested
  } = assignment;
  let debatersToAssign = [];
  let IDWasFound = false;
  for (let id in registrations) {
    // iterating over all the most updated registrations to the event
    let debater;
    if (!(id in allDebaters)) {
      // the id is of a fake user
      debater = createFakeDebaterJson(id, registrations); // creating a fake debater configuration
      //debatersToAssign.push(debater);
      //previouslyRegistered.push(id);
      continue;
    }
    for (let index in assignment.debaters) {
      //updates requests and history of debaters
      if (assignment.debaters[index].uid === id) {
        // updating in horizontal droppable
        IDWasFound = true;
        if (allDebaters[id] && allDebaters[id]["registration"]) {
          allDebaters[id]["registration"] = registrations[id];
        }
        if (
          allDebaters[id] &&
          allDebaters[id]["average_score"] &&
          history[id]
        ) {
          allDebaters[id]["average_score"] = history[id]["average_score"];
        }
        if (id in allDebaters) {
          // id is of a non-mock debator
          debater = allDebaters[id];
        }
        if (!(id in trashList)) {
          debatersToAssign.push(debater);
          if (!(id in previouslyRegistered)) previouslyRegistered.push(id);
        }
      }
    }
    for (let room in assignment.rooms) {
      // updating inside the rooms
      for (let position in assignment.rooms[room]) {
        for (let debater in assignment.rooms[room][position]) {
          if (assignment.rooms[room][position][debater]["uid"] === id) {
            IDWasFound = true;
            if (rooms[room][position][debater]["registration"]) {
              rooms[room][position][debater]["registration"] =
                registrations[id];
            }
            if (history[id]) {
              rooms[room][position][debater]["average_score"] =
                history[id]["average_score"];
            }
          }
        }
      }
    }

    for (let i in trashList) {
      if (trashList[i].id === id) {
        IDWasFound = true;
      }
    }

    if (previouslyRegistered.indexOf(id) === -1 || !IDWasFound) {
      // debater has registered since assignment started
      if (allDebaters[id]) {
        allDebaters[id]["registration"] = registrations[id];
      }

      if (allDebaters[id] && history[id]) {
        allDebaters[id]["average_score"] = history[id]["average_score"];
      }
      if (id in allDebaters) {
        // id is of a non-mock debator
        debater = allDebaters[id];
      }
      if (!(id in trashList)) {
        debatersToAssign.push(debater);
        if (!(id in previouslyRegistered)) previouslyRegistered.push(id);
      }
    }
  }

  let result = {
    allDebaters,
    rooms,
    trashList,
    comment,
    previouslyRegistered,
    feedbackRequested,
    debatersToAssign
  };
  return result;
}

function creatInitialAssignment(registrations, allDebaters, history) {
  let debatersToAssign = [];
  let previouslyRegistered = [];
  let rooms = [JSON.parse(JSON.stringify(emptyRoom))];
  let trashList = [];
  let comment = "";
  let feedbackRequested = false;
  for (let id in registrations) {
    if (allDebaters[id]) {
      allDebaters[id]["registration"] = registrations[id];
    }
    if (history[id]) {
      allDebaters[id]["average_score"] = history[id]["average_score"];
    }
    if (allDebaters[id]) {
      debatersToAssign.push(allDebaters[id]);
      previouslyRegistered.push(id);
    } else {
      // the debater is a fake one added by admin
      let fakeDebater = createFakeDebaterJson(id, registrations);
      debatersToAssign.push(fakeDebater);
      previouslyRegistered.push(id);
    }
  }
  let result = {
    allDebaters,
    rooms,
    trashList,
    comment,
    previouslyRegistered,
    feedbackRequested,
    debatersToAssign
  };
  return result;
}

function createFakeDebaterJson(id, registrations) {
  if (!registrations[id].fake_user_name) {
  }
  let fakeDebater = {
    id: id,
    uid: id,
    full_name_eng: registrations[id].fake_user_name
      ? registrations[id].fake_user_name
      : "Deleted User",
    registration: registrations[id],
    fake: true,
    average_score: 0
  };
  return fakeDebater;
}
