import React, { Component } from "react";
import {
  get_all_feedback_for_event,
  get_registrations_for_event, getDebatersInRoom,
  load_assignment
} from "../../../Backend/BackendFunctions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { isEmptyFeedback } from "../EventFeedbacks";
import { handleError } from "../../Utilities/OtherUtils";

export const juniorsFilter = debater => {
  return debater && debater.rank === "Junior";
};
export const seniorsFilter = debater => {
  return debater && debater.rank === "Senior";
};
export const studentFilter = debater => {
  return debater && debater.student;
};
export const nonStudentFilter = debater => {
  return debater && !debater.student;
};
export const englishFilter = debater => {
  if (!debater.registration) return false;
  return debater && (debater.registration.language === "English");
};
export const hebrewFilter = debater => {
  if (!debater.registration) return false;
  return debater && (debater.registration.language === "Hebrew");
};
export const noPrfLangFilter = debater => {
  if (!debater.registration) return true;
  return debater && (debater.registration.language === "No preference");
};
export const nonClubMemFilter = debater => {
  return debater && !debater.club === "TAU";
};
export const clubMemFilter = debater => {
  return debater && debater.club === "TAU";
};

export const othersFilter = debater => {
  return debater && !(debater.club === "TAU" || !debater.student);
};
export const TAUfilter = debater => {
  return debater && debater.club === "TAU" && debater.student;
};

export const eventSeniorsFilter = debater => {
  return (
    debater &&
    debater.rank === "Senior" &&
    debater.club === "TAU" &&
    debater.student
  );
};
export const eventJuniorsFilter = debater => {
  return (
    debater &&
    debater.rank === "Junior" &&
    debater.club === "TAU" &&
    debater.student
  );
};
export const eventOtherFilter = debater => {
  return (
    debater && (!debater.club === "TAU" || !debater.student || debater.fake)
  );
};

/**
 * Compact summary of event numbers, can be used for every step of the funnel: registrations/assignments/rooms/feedbacks
 */
export class EventStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      assignment: null,
      metric: props.metric,
      roomCount: 0,
      feedbackCount: 0,
      jr: props.event.open_to_jr ? 0 : null,
      sr: props.event.open_to_sr ? 0 : null,
      other: props.event.open_to_other_clubs ? 0 : null
    };
    this.getStatsTable = this.getStatsTable.bind(this);
    this.getDebatersStats = this.getDebatersStats.bind(this);
    this.getAssignmentStats = this.getAssignmentStats.bind(this);
    this.getFeedbackStats = this.getFeedbackStats.bind(this);
    this.calculateStats = this.calculateStats.bind(this);
  }

  componentDidMount() {
    if (
      this.state.metric === "Registration" ||
      this.state.metric === "Participation"
    ) {
      this.getDebatersStats();
      return;
    }

    if (
      this.state.metric === "Rooms" &&
      this.props.event.event_type !== "Lesson"
    ) {
      this.getAssignmentStats();
      return;
    }

    if (
      this.state.metric === "Feedbacks" &&
      this.props.event.event_type !== "Lesson"
    ) {
      this.getFeedbackStats();
      return;
    }
    this.setState({ loading: false });
  }

  getAssignmentStats() {
    load_assignment(this.props.event.id)
      .then(assignment => {
        if (assignment.rooms)
          this.setState({
            roomCount: assignment.rooms.length,
            loading: false,
            assignment: assignment
          });
        else this.setState({ loading: false, assignment: assignment });
      })
      .catch(e => handleError(e));
  }

  getFeedbackStats() {
    get_all_feedback_for_event(this.props.event.id)
      .then(feedbacks => {
        let counter = 0;
        for (const id in feedbacks)
          counter += isEmptyFeedback(feedbacks[id]) ? 0 : 1;
        this.setState({ feedbackCount: counter, loading: false });
      })
      .catch(e => handleError(e));
  }

  getDebatersStats() {
    this.setState({ loading: true });

    if (this.props.metric === "Registration") {
      get_registrations_for_event(this.props.event.id)
        .then(debaters => this.calculateStats(Object.keys(debaters)))
        .catch(e => handleError(e));
    } else {
      load_assignment(this.props.event.id)
        .then(data => {
          if (!data.published || !data.rooms) return this.calculateStats([]);
          const debaterIds = [];
          for (const room of data.rooms) {
            for (const debater of getDebatersInRoom(room)) {
              debaterIds.push(debater.uid);
            }
          }
          this.calculateStats(debaterIds);
        })
        .catch(e => handleError(e));
    }
  }

  calculateStats(debaterIds) {
    let jr = 0;
    let sr = 0;
    let other = 0;
    if (debaterIds) {
      for (const id of debaterIds) {
        const debater = this.props.allDebaters[id];
        if (eventSeniorsFilter(debater)) sr++;
        else if (eventJuniorsFilter(debater)) jr++;
        else if (eventOtherFilter(debater)) other++;
      }
    }
    jr = this.state.jr === null ? null : jr;
    sr = this.state.sr === null ? null : sr;
    other = this.state.other === null ? null : other;
    this.setState({ jr: jr, sr: sr, other: other, loading: false });
  }

  getStatsTable(s, j, o) {
    const ifNull = (a, b) => (a === null ? b : a);
    const all = ifNull(s, 0) + ifNull(j, 0) + ifNull(o, 0);
    const lockedStyle = { color: "#BBBBBB", textDecoration: "line-through" };
    return (
      <table style={{ align: "left", fontSize: 14, borderSpacing: 5 }}>
        <tbody>
          <tr>
            <td style={this.props.event.open_to_sr ? {} : lockedStyle}>S</td>
            <td style={this.props.event.open_to_jr ? {} : lockedStyle}>J</td>
            <td style={this.props.event.open_to_other_clubs ? {} : lockedStyle}>
              O
            </td>
            <td style={{ fontWeight: "bold" }}>Σ</td>
          </tr>
          <tr>
            <td style={this.props.event.open_to_sr ? {} : { color: "#BBBBBB" }}>
              {ifNull(s, "-")}
            </td>
            <td style={this.props.event.open_to_jr ? {} : { color: "#BBBBBB" }}>
              {ifNull(j, "-")}
            </td>
            <td style={this.props.event.open_to_other_clubs ? {} : { color: "#BBBBBB" }}>
              {ifNull(o, "-")}
            </td>
            <td style={{ fontWeight: "bold" }}>{ifNull(all, 0)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    if (this.state.loading) {
      return <LinearProgress style={{ width: "50%" }} />;
    }

    if (
      this.props.metric === "Registration" ||
      this.props.metric === "Participation"
    ) {
      return (
        <div align="left">
          {this.getStatsTable(this.state.sr, this.state.jr, this.state.other)}
        </div>
      );
    }

    if (
      this.props.metric === "Rooms" &&
      this.props.event.event_type !== "Lesson"
    ) {
      if (!this.state.assignment) return null;
      return (
        <div align="center">
          {this.state.roomCount}
          <div style={{ marginTop: 5, color: "#777777" }}>
            {this.state.assignment.published ? "Published" : "Draft"}
          </div>
        </div>
      );
    }

    if (
      this.props.metric === "Feedbacks" &&
      this.props.event.event_type !== "Lesson"
    ) {
      return <div align="center">{this.state.feedbackCount}</div>;
    }

    return null;
  }
}
