export const defaultFirstDate = truncateDate(addDays(new Date(), -180));
export const defaultLastDate = truncateDate(addDays(new Date(), 30));

/**
 * @param date
 * @returns {Date}
 */
export function truncateDate(date) {
  const newDate = new Date(date);
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
}

/**
 * @param date
 * @returns {string}
 */
export function getDayOfWeek(date) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  return days[date.getDay()];
}

/**
 * @param ts
 * @returns {Date}
 */
export const tsToDate = ts => {
  return new Date(1000 * ts.seconds);
};

/**
 * @param date
 * @returns {string}
 */
export const formatDate = date =>
  date
    .getDate()
    .toString()
    .padStart(2, "0") +
  "/" +
  (date.getMonth() + 1).toString().padStart(2, "0") +
  "/" +
  date
    .getFullYear()
    .toString()
    .substr(2, 2);

/**
 * @param date
 * @returns {string}
 */
export const formatDateShort = date =>
  date
    .getDate()
    .toString()
    .padStart(2, "0") +
  "/" +
  (date.getMonth() + 1).toString().padStart(2, "0");

/**
 * @param date
 * @param n
 * @returns {Date}
 */
export function addDays(date, n) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + n);
  return newDate;
}

/**
 * @param date
 * @param n
 * @returns {Date}
 */
export function addHours(date, n) {
  const extraDays = Math.floor((date.getHours() + n) / 24);
  const newDate = addDays(date, extraDays);
  newDate.setHours(date.getHours() + n);
  return newDate;
}

/**
 * @param date
 * @returns {boolean}
 */
export function isToday(date) {
  const now = new Date();
  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}

/**
 * @param date1
 * @param date2
 * @returns {date}
 */
export function minDate(date1, date2) {
  if (date1.valueOf() <= date2.valueOf()) return date1;
  else return date2;
}

/**
 * @param time
 * @returns {string}
 */
export function timeLeftString(time) {
  const now = new Date().getTime();
  const diff = time.getTime() - now;
  if (diff <= 0) return "0 seconds";

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  const daysLeft = days > 0 ? days.toString() + " Days, " : "";
  const hoursLeft = hours > 0 ? hours.toString() + " Hours, " : "";
  const minutesLeft = minutes > 0 ? minutes.toString() + " Minutes" : "";

  if (days > 0 && hours > 0) return daysLeft + hoursLeft.replace(", ", "");
  if (days <= 0) return hoursLeft + minutesLeft;
  return daysLeft + hoursLeft + minutesLeft;
}

/**
 * @param time
 * @returns {string}
 */
export const formatTime = time =>
  time
    .getHours()
    .toString()
    .padStart(2, "0") +
  ":" +
  time
    .getMinutes()
    .toString()
    .padStart(2, "0");

/**
 * @param date
 * @returns {string}
 */
export const formatDateTime = date => formatDate(date) + " " + formatTime(date);

/**
 * @param date
 * @returns {string}
 */
export const formatDateDayTime = date =>
  getDayOfWeek(date).substr(0, 3) + " " + formatDate(date);

/**
 * @param date
 * @param firstDate
 * @param lastDate
 * @returns {boolean}
 */
export function inRange(date, firstDate, lastDate) {
  return (
    firstDate.getTime() <= date.getTime() &&
    date.getTime() <= lastDate.getTime()
  );
}

/**
 * @param date
 * @param eventsDict
 * @returns {Array}
 */
export function getEventsArrayByDate(date, eventsDict) {
  const eventsArr = [];
  for (const id in eventsDict) {
    const event = eventsDict[id];
    if (
      (event.date instanceof Date && event.date.getTime() === date.getTime()) ||
      tsToDate(event.date).getTime() === date.getTime()
    ) {
      eventsArr.push(event);
    }
  }
  return eventsArr;
}

/**
 * @param events
 * In-place sort
 */
export function sortEvents(events) {
  const f = (a, b) =>
    tsToDate(a.time).getTime() > tsToDate(b.time).getTime() ? -1 : 1;
  events.sort(f);
}
