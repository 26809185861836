import ReactQuill from "react-quill";
import React from "react";
import "react-quill/dist/quill.snow.css";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import {CancelButton, SubmitButton} from "../Common/Buttons";
import {ConfirmAlertBody} from "../Common/ConfirmAlert";
import { confirmAlert } from "react-confirm-alert";

export const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [{ direction: "rtl" }, { align: ["", "center", "right", "justify"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image"],
    ["clean"]
  ]
};

export const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "direction"
];

const fieldNameStyle = {
  fontWeight: "bold",
  fontSize: 14
};

export default class EditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Date.now(),
      text: "",
      settings: {
        forJuniors: true,
        forSeniors: true,
        forOthers: true,
        forNonStudents: true
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.handleSubmitInner = this.handleSubmitInner.bind(this);
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  clearAll() {
    this.setState({ text: "" });
  }

  handleSubmitInner() {
    this.props.handleSubmit(this.state);
    this.setState({ text: "", id: Date.now() });
  }

  popupCancelConfirm = () => {
      const message = "Are you sure you want to post this announcement?";
      confirmAlert({
            onClickOutside: () => {},
            customUI: ({ onClose }) => (
                <ConfirmAlertBody
                    onClose={onClose}
                    message={message}
                    onSubmit={() => this.handleSubmitInner()}/>
            )
        });
    };

  render() {
    return (
    <div align="center">
      <Paper style={{ margin: "2% 4%", padding: "2%", maxWidth: 1000}}>
        <div align={"left"}>
        <ReactQuill
          value={this.state.text}
          onChange={this.handleChange}
          modules={quillModules}
          formats={quillFormats}
          placeholder="Announce to the world!"
          theme="snow"
        />
        <br/>
        <label style={{marginRight: 10}}>Show to:</label>
        <Checkbox
          checked={this.state.settings.forJuniors}
          onChange={() =>
            this.setState({
              settings: {
                ...this.state.settings,
                forJuniors: !this.state.settings.forJuniors
              }
            })
          }/>
        <label style={fieldNameStyle}>Juniors</label>

        <Checkbox
          checked={this.state.settings.forSeniors}
          onChange={() =>
            this.setState({
              settings: {
                ...this.state.settings,
                forSeniors: !this.state.settings.forSeniors
              }
            })
          }/>
        <label style={fieldNameStyle}>Seniors</label>

        <Checkbox
          checked={this.state.settings.forOthers}
          onChange={() =>
            this.setState({
              settings: {
                ...this.state.settings,
                forOthers: !this.state.settings.forOthers
              }
            })
          }/>
        <label style={fieldNameStyle}>Other Clubs </label>
        <Checkbox
          checked={this.state.settings.forNonStudents}
          onChange={() =>
            this.setState({
              settings: {
                ...this.state.settings,
                forNonStudents: !this.state.settings.forNonStudents
              }
            })
          }/>
        <label style={fieldNameStyle}>Non Students </label>

        <div align={"center"}>
          <br/>
          <CancelButton onClick={this.clearAll} label={"Clear"}/>
          <SubmitButton onClick={this.popupCancelConfirm} label={"Submit"}/>
        </div>
        </div>
      </Paper>
    </div>
    );
  }
}
