import React from "react";
import { NavLink } from "react-router-dom";
import DateRange from "@material-ui/icons/DateRange";
import Tooltip from "@material-ui/core/Tooltip";
import ForumIcon from "@material-ui/icons/Forum";
import { WaitingUsersIcon } from "./WaitingUsersIcon";

const ManagerLinks = props => {
  const getIconStyle = path => ({
    color: props.currentLocation === path ? "#AADDFF" : "#FFFFFF"
  });
  return (
    <ul>
      <li>
        <NavLink to="/new_users">
          <Tooltip title="Approve New Debaters" placement="bottom">
            <WaitingUsersIcon
              refresh={props.refreshToolbar}
              style={getIconStyle("new_users")}
              currentLocation={props.currentLocation}
            />
          </Tooltip>
        </NavLink>
      </li>
      <li>
        <NavLink to="/events">
          <Tooltip title="Manage Events" placement="bottom">
            <DateRange style={getIconStyle("events")} />
          </Tooltip>
        </NavLink>
      </li>
      <li>
        <NavLink to="/Announcements">
          <Tooltip title="Edit Announcements" placement="bottom">
            <ForumIcon style={getIconStyle("Announcements")} />
          </Tooltip>
        </NavLink>
      </li>
    </ul>
  );
};

export default ManagerLinks;
