import React, { Component } from 'react';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Info from "@material-ui/icons/Info";


/*
* A tooltip containing helpful tips for the assignment page. Located on the toolbar
*/
class LiveHelpTooltip extends Component {
    state = {  }
    render() { 
        return ( 
            <IconButton style={{padding : 0, marginLeft : 20}}>
                                <Tooltip
                                    placement="left"
                                    interactive={true}                           
                                    title={
                                <React.Fragment >
                                    <Typography color="inherit">Helpful Tips:</Typography>
                                    <ul>
                                    <li><Typography color="inherit" variant="caption">Double click a debater to change attendance status</Typography></li>
                                        
                                    </ul>

                                    <Typography color="inherit">Request Color Legend:</Typography>
                                        <ul>
                                            <li >
                                                <div style={{display:'flex', flexDirection:'row'}}>
                                                <div class="color-box" style={{ backgroundColor:'#000000',
                                                                                height:15,
                                                                                width:30,
                                                                                marginRight:10}}></div>
                                                
                                                <Typography color="inherit" variant="caption">- Debate</Typography>
                                                </div>
                                            </li>
                                            <li >
                                                <div style={{display:'flex', flexDirection:'row'}}>
                                                <div class="color-box" style={{ backgroundColor:'#197DF6',
                                                                                height:15,
                                                                                width:30,
                                                                                marginRight:10}}></div>
                                                
                                                <Typography color="inherit" variant="caption">- Judge</Typography>
                                                </div>
                                            </li>
                                            <li >
                                                <div style={{display:'flex', flexDirection:'row', }}>
                                                <IconButton
                                                    style={{color: '#FFD426'}}
                                                >
                                                    <Info style={{ fontSize: 12 }} />
                                                </IconButton>
                                                <Typography color="inherit" variant="caption" style={{marginTop:8}}>- Other</Typography>
                                                </div>
                                            </li>
                                        </ul>                              
                                </React.Fragment>}
                            >
                            <LiveHelpIcon  />
                            </Tooltip>
                        </IconButton>
         );
    }
}
 
export default LiveHelpTooltip;