import React, { Component } from "react"
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {eventStarted, registrationStarted} from "../EventTimelineStatus";
import IconButton from "@material-ui/core/IconButton";
import {isSystemAdmin} from "../../Utilities/DebaterUtilities";
import {formatTime, tsToDate} from "../../Utilities/DateTimeUtils";
import {EventStats} from "./EventStats";
import EventRegisteredDebaters from "../EventRegisteredDebaters";
import EventFeedbacks from "../EventFeedbacks";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PeopleIcon from "@material-ui/icons/People";
import RequestFeedbackIcon from "@material-ui/icons/SmsFailed";

/**
 * A table with all relevant event information + action & extra detail buttons
 */
export class SingleEventTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expansion: null
    };

    this.expandRegistrations = this.expandRegistrations.bind(this);
    this.expandFeedbacks = this.expandFeedbacks.bind(this);
  }

  expandRegistrations(){
    const newExpansion = this.state.expansion === "registrations" ? null:"registrations";
    this.setState({expansion: newExpansion});
  }

  expandFeedbacks(){
    const newExpansion = this.state.expansion === "feedback" ? null:"feedback";
    this.setState({expansion: newExpansion});
  }

  render(){
    const event = this.props.event;
    return (
      /*
       * This is a hack to create an expandable panel for registrations / feedback data
       * We create two table rows, one for the actual information and one for the expansion
       */
      // eslint-disable-next-line
      [0, 1].map(i => {
        const registrationsExpanded = this.state.expansion === "registrations";
        // const registrationsDisabled = event.event_type === "Lesson" || !registrationStarted(event);
        const registrationsDisabled = !registrationStarted(event);
        const feedbacksExpanded = this.state.expansion === "feedback";
        const feedbacksDisabled = !event.requestedFeedbacks || event.event_type === "Lesson" || !eventStarted(event);
        if(i === 0) {
          return (
            <TableRow key={i} style={{backgroundColor: event.event_type === 'Lesson' ? '#F3F3F3':'#FFFFFF'}}>
              <TableCell align="center" style={{width: 225}} padding="none">
                <IconButton
                  title="Registered Debaters"
                  disabled={registrationsDisabled}
                  onClick={this.expandRegistrations}>
                  <PeopleIcon
                    style={{color: registrationsExpanded?'#000000':registrationsDisabled?'#B7B7B7':'#7A7A7A'}}/>
                </IconButton>
                <IconButton
                  title="Go to Assignments"
                  disabled={event.event_type === "Lesson" || !registrationStarted(event)}
                  onClick={() => window.open("/assignment/" + event.id, "_blank")}>
                  <DashboardIcon/>
                </IconButton>
                {isSystemAdmin(this.props.user) &&
                <IconButton
                  title="Request Feedback"
                  disabled={event.requestedFeedbacks || event.event_type === "Lesson" || !eventStarted(event)}
                  onClick={() => this.props.onFeedbackRequest(event)}>
                  <RequestFeedbackIcon/>
                </IconButton>}
                {isSystemAdmin(this.props.user) &&
                <IconButton
                  title="Feedbacks"
                  disabled={feedbacksDisabled}
                  onClick={this.expandFeedbacks}>
                  <AssessmentIcon
                    style={{color: feedbacksExpanded?'#000000':feedbacksDisabled?'#B7B7B7':'#7A7A7A'}}/>
                </IconButton>}
              </TableCell>
              <TableCell align="center" style={{width: 50}} padding="none">{event.event_type}</TableCell>
              <TableCell align="center" style={{width: 50}} padding="none">{formatTime(tsToDate(event.time))}</TableCell>
              <TableCell align="center" style={{width: 50}} padding="none">{event.location}</TableCell>
              <TableCell align="center" style={{width: 50}} padding="none">
                <EventStats
                  event={event}
                  allDebaters={this.props.allDebaters}
                  metric="Registration"/>
              </TableCell>
              <TableCell align="right"style={{width: 50}} padding="none">
                <EventStats
                  event={event}
                  allDebaters={this.props.allDebaters}
                  metric="Participation"/>
              </TableCell>
              <TableCell align="right"style={{width: 50}} padding="none">
                <EventStats
                  event={event}
                  allDebaters={this.props.allDebaters}
                  metric="Rooms"/>
              </TableCell>
              <TableCell align="center"style={{width: 50}} padding="none">
                <EventStats
                  event={event}
                  allDebaters={this.props.allDebaters}
                  metric="Feedbacks"/>
              </TableCell>
            </TableRow>
          );
        } else if(registrationsExpanded){
          return(
            <TableRow>
              <TableCell colSpan={this.props.columns.length}
                         padding="none"
                         align="center"
                         style={{maxWidth: this.props.tableWidth, padding: 0, margin: 0}}>
                <EventRegisteredDebaters
                  maxWidth={this.props.tableWidth - 50}
                  event={event}
                  allDebaters={this.props.allDebaters}
                  user={this.props.user}/>
              </TableCell>
            </TableRow>);
        } else if(feedbacksExpanded){
          return(
            <TableRow>
              <TableCell colSpan={this.props.columns.length}
                         style={{maxWidth: this.props.tableWidth - 50}}>
                <EventFeedbacks
                  event={event}
                  allDebaters={this.props.allDebaters}/>
              </TableCell>
            </TableRow>);
        } else {
          // Adding a "blank" row to set the columns width properly
          return (
            <TableRow>
              <TableCell padding="none"
                         colSpan={this.props.columns.length}
                         style={{border:'none', width: this.props.tableWidth, height: 0}}>
              </TableCell>
            </TableRow>
          );
        }})
      );
  }
}

