import React, { Component } from "react";
import { HomePageBody } from "../components/Homepage/HomePageBody";
import {
  get_user_events,
  add_user_to_special_registration
} from "../Backend/BackendFunctions";
import {
  get_event_user_status,
  get_relevant_events_for_user
} from "../Backend/helperFunctions";
import { get_chair_feedbacks } from "../Backend/feedbackBackendFunctions";
import { get_all_posts } from "../Backend/announcementsBackendFunctions";
import { handleError } from "../components/Utilities/OtherUtils";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import homePageBanner from "../images/debateImage2.png";
import { getCurrentLocation } from "../components/Utilities/OtherUtils";

export class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      width: window.innerWidth,
      keyboardOpen: false,
      events: {},
      feedbacks: {},
      selectedEvent: null,
      selectedFeedback: null,
      posts: null
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.isMobile = this.isMobile.bind(this);

    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.handleSelectFeedback = this.handleSelectFeedback.bind(this);

    this.onFeedbackSave = this.onFeedbackSave.bind(this);
    this.onSubmitRegistration = this.onSubmitRegistration.bind(this);
    this.onCancelOnTime = this.onCancelOnTime.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCheckIn = this.onCheckIn.bind(this);
    this.onUndoCancel = this.onUndoCancel.bind(this);
    this.isMobile = this.isMobile.bind(this);
    this.addSpecialReg = this.addSpecialReg.bind(this);
  }

  componentDidMount() {
    this.props.onLoad();
    this.addSpecialReg();
    get_user_events(this.props.user)
      .then(events => {
        let rel_events = get_relevant_events_for_user(events, this.props.user);
        get_chair_feedbacks()
          .then(feedbacks => {
            get_all_posts(true, this.props.user)
              .then(allposts => {
                this.setState({
                  posts: allposts,
                  feedbacks: this.initializeFeedbacks(feedbacks),
                  selectedFeedback: this.isMobile()
                    ? null
                    : feedbacks[Object.keys(feedbacks)[0]],
                  events: rel_events,
                  selectedEvent: this.isMobile()
                    ? null
                    : rel_events[Object.keys(rel_events)[0]],
                  loading: false
                });
              })
              .catch(error => handleError(error));
          })
          .catch(error => handleError(error));
      })
      .catch(error => handleError(error));
  }

  //add special registration to user
  addSpecialReg() {
    let location = getCurrentLocation();
    if (location.substring(0, 7) === "addReg=") {
      let eventIds = location.substring(7);
      eventIds = eventIds.split(",");
      eventIds.forEach(eventId => add_user_to_special_registration(eventId));
    }
  }

  //checking if on mobile or on desktop
  /*componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  // removing the listener when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }*/

  isMobile() {
    return window.innerWidth < 768;
  }

  initializeFeedbacks(feedbacks) {
    const res = {};
    for (const id in feedbacks) {
      if (
        !feedbacks[id].debaters ||
        Object.keys(feedbacks[id].debaters).length === 0
      )
        continue;
      res[id] = feedbacks[id];
      res[id].event.status = "feedback";
      for (const debaterId in res[id].debaters)
        res[id].debaters[debaterId]["feedback"] = {
          score: 0,
          positive_feedback: "",
          negative_feedback: "",
          notes: ""
        };
    }
    return res;
  }

  // When a different event is selected
  handleSelectEvent(eventId) {
    if (eventId in this.state.events) {
      if (this.state.selectedEvent && this.state.selectedEvent.id === eventId)
        this.setState({ selectedEvent: null });
      else this.setState({ selectedEvent: this.state.events[eventId] });
    } else {
      this.handleSelectFeedback(eventId);
    }
  }

  // Functions that update the selected event on registration, cancellation, check in, etc.
  handelEventUpdate(updateFunction) {
    const event = this.state.selectedEvent;
    updateFunction(event);
    this.setState({ selectedEvent: event });
  }
  onSubmitRegistration() {
    this.handelEventUpdate(
      event =>
        (event.status = event.event_type === "Lesson" ? "assigned" : "edit")
    );
  }
  onCancelOnTime() {
    this.handelEventUpdate(event => {
      event.status = "new";
      event.checkedIn = false;
    });
    this.setState({ selectedEvent: null });
  }
  onCancel() {
    this.handelEventUpdate(event => {
      event.status = "cancelled";
      event.checkedIn = false;
    });
  }
  onUndoCancel() {
    this.handelEventUpdate(
      event => (event.status = get_event_user_status(event))
    );
  }
  onCheckIn() {
    this.handelEventUpdate(event => (event.checkedIn = true));
  }

  // Feedback related functions
  handleSelectFeedback(feedbackId) {
    if (
      this.state.selectedFeedback &&
      this.state.selectedFeedback.event &&
      this.state.selectedFeedback.event.id === feedbackId
    )
      this.setState({ selectedFeedback: null });
    else this.setState({ selectedFeedback: this.state.feedbacks[feedbackId] });
  }
  onFeedbackSave(feedback, debaterId) {
    const feedbacks = this.state.feedbacks;
    const eventId = feedback.event.id;
    delete feedbacks[eventId]["debaters"][debaterId];

    // If that was the last feedback of the event - remove the event
    if (Object.keys(feedbacks[eventId]["debaters"]).length === 0) {
      delete feedbacks[eventId];
    }
    this.setState({ feedbacks: feedbacks });
  }

  render() {
    const homeProps = {
      user: this.props.user,
      event: this.state.selectedEvent,
      events: this.state.events,
      feedback: this.state.selectedFeedback,
      feedbacks: this.state.feedbacks,
      posts: this.state.posts,
      onSelectFeedback: this.handleSelectFeedback,
      onFeedbackSave: this.onFeedbackSave,
      onSelectEvent: this.handleSelectEvent,
      onSubmitRegistration: this.onSubmitRegistration,
      onCancelOnTime: this.onCancelOnTime,
      onCancel: this.onCancel,
      onUndoCancel: this.onUndoCancel,
      onCheckIn: this.onCheckIn,
      mobile: this.isMobile()
    };

    const loadingComponent = (
      <div align="center">
        <Paper style={{ marginTop: 10, padding: 20 }}>
          <CircularProgress />
        </Paper>
      </div>
    );

    return (
      <div align="center">
        <div style={{ maxWidth: 1000 }}>
          <img
            style={{ width: "100%", maxHeight: 300 }}
            src={homePageBanner}
            alt={"TAU Debate Club"}
          />
          {this.state.loading && loadingComponent}
          {!this.state.loading && <HomePageBody {...homeProps} />}
        </div>
      </div>
    );
  }
}
