import React, { Component } from "react"
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SvgIcon from "@material-ui/core/SvgIcon";

const red = "#FF4433";
const green = "#55D033";
const white = "#FFFFFF";
const gray = "#CCCCCC";
const lightGray = "#EEEEEE";

/**
 * Common buttons to be used across the whole app
 */
export class SubmitButton extends Component {

    render(){
        return (
            <Button disabled={this.props.disabled}
                    onClick={this.props.onClick}
                    style={{margin: 10}}
                    variant="contained"
                    color="primary">
                {this.props.label ? this.props.label : "Submit"}
            </Button>
        );
    }
}

export class DeleteButton extends Component {

    render(){
        return (
            <Button disabled={this.props.disabled}
                    onClick={this.props.onClick}
                    variant="contained"
                    style={{
                        backgroundColor: this.props.disabled ? gray:red,
                        color: this.props.disabled ? lightGray:white,
                        margin: 10}}>
                {this.props.label ? this.props.label : "Delete"}
            </Button>
        );
    }
}

export class CancelButton extends Component {

    render(){
        return (
            <Button disabled={this.props.disabled}
                    onClick={this.props.onClick}
                    style={{margin: 10}}
                    variant="contained">
                {this.props.label ? this.props.label : "Cancel"}
            </Button>
        );
    }
}

/* X button in the top right corner of dialogs */
export class CloseButton extends Component {
    render(){
        return (
            <IconButton style={{float: 'right', marginTop: 0, padding: 3 , display: 'inline', marginRight: 10}}
                        onClick={this.props.onClick}>
                <ClearIcon/>
            </IconButton>
        );
    }
}

export class CheckInButton extends Component {
    render(){
        return(
            <Button disabled={this.props.disabled}
                    onClick={this.props.onClick}
                    style={{
                        margin: 5,
                        backgroundColor: this.props.disabled ? gray:green,
                        color: this.props.disabled ? lightGray:white
                    }}
                    variant="contained">
                <SvgIcon style={{marginRight: 5}}>
                    <path fill={white}
                          d="M12,2C15.86,2 19,5.14 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9C5,5.14 8.14,2 12,2M10.47,14L17,7.41L15.6,6L10.47,11.18L8.4,9.09L7,10.5L10.47,14Z" />
                </SvgIcon>
                {this.props.label ? this.props.label : "Check In"}
            </Button>
        );
    }
}

export class CancelAttendanceButton extends Component {
    render(){
        return(
            <Button disabled={this.props.disabled}
                    onClick={this.props.onClick}
                    style={{
                        margin: 5,
                        backgroundColor: this.props.disabled ? gray:red,
                        color: this.props.disabled ? lightGray:white
                    }}
                    variant="contained">
                <ClearIcon style={{marginRight : 5}} />
                {this.props.label ? this.props.label : "Cancel"}
            </Button>
        );
    }
}

/* Previous versions of check in and cancel buttons:
    <Fab
        variant="extended"
        style={{ paddingRight:'5%', margin : '2%', background : '#2ECC71'}}
        onClick={this.onCheckIn}>
        <CheckInIcon style={{ marginRight : 20}} />
        Check In
     </Fab>

    <Fab
        variant="extended"
        style={{ paddingRight:'5%', margin : '7% 2% ', background : '#E74C3C'}}
        onClick={this.popupCancelConfirm}>
        <CancelIcon style={{ marginRight : 20}} />
        Cancel
    </Fab>
*/
