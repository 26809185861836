import React, { Component } from "react";
//import "./Login.css";
import { unsubscribe } from "../Backend/BackendFunctions";
import "../App.css";
import Paper from "@material-ui/core/Paper";

export class Unsubscribe extends Component {
  state = {};

  componentDidMount = () => {
    this.props.onLoad();
    unsubscribe(this.props.user);
    this.props.user.email_subscribed = false;
  };

  render() {
    const currentAddressArr = window.location.href.split("/");
    currentAddressArr.pop();
    const domain = currentAddressArr.join("/");
    const base = domain + "/profile";
    let link = base;
    return (
      <div
        className="marginTop"
        style={{
          marginTop: 70,
          marginRight: "auto",
          marginLeft: "auto",
          align: "center",
          width: "100%",
          maxWidth: "1000px"
        }}
      >
        <div className="container">
          <Paper
            id="content"
            style={{
              background: "#f9f9f9"
            }}
          >
            <form>
              <div className="App__Form" style={{ padding: "5%" }}>
                {/* <div className="image-cropper">
                  <img
                    src={require("../logo.png")}
                    alt="TAU Logo"
                    className="profile-pic"
                  />
                </div> */}
                <div style={{ textAlign: "center" }}>
                  <h1>You have unsubscribed from our mailing list :(</h1>
                  <h3>
                    You can allways resubsribe at your{" "}
                    <a href={link}>profile page</a>
                  </h3>
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </div>
    );
  }
}
