import React, { Component } from "react"
import Done from '@material-ui/icons/Done'
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {get_all_feedback_for_event, getDebatersInRoom, load_assignment} from "../../Backend/BackendFunctions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FeedbackDetails} from "../FeedbackComps/FeedbackDetails";
import {handleError} from "../Utilities/OtherUtils";
import {getAvatar, getFullName} from "../Utilities/DebaterUtilities";
import CancelIcon from "@material-ui/icons/Cancel";

export function isEmptyFeedback(feedback) {
    return !feedback || !feedback.score || feedback.score === 0;
}

/**
 * Tree-style display of all feedbacks per event
 * Shows also debaters that cancelled attendance or didn't recieve feedbacks
 */
export default class EventFeedbacks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            assignment: null,
            rooms: {}, // key is chair id
            feedbacks: {}, // key is debater id
            selectedDebaters: [],
            selectedChairId: null,
            selectedDebaterId: null,
            selectedFeedback: null
        };

        this.handleDebaterChange = this.handleDebaterChange.bind(this);
        this.handleRoomChange = this.handleRoomChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        load_assignment(this.props.event.id)
            .then(assignment => {
                const rooms = {"TRASH": {Name: " ", debaters: assignment.trashList}};
                if(!assignment || !assignment.rooms) {
                    this.setState({loading: false});
                    return;
                }
                for(const room of assignment.rooms) {
                    if(!room['Chair'][0]) continue;
                    rooms[room['Chair'][0].uid] = room;
                    room.debaters = getDebatersInRoom(room);
                }
                get_all_feedback_for_event(this.props.event.id)
                    .then(feedbacks => {
                        for(const id in feedbacks) {
                            if(feedbacks[id].feedbackBy && this.props.allDebaters[feedbacks[id].feedbackBy])
                            feedbacks[id].chairName = getFullName(this.props.allDebaters[feedbacks[id].feedbackBy]);
                        }
                        this.setState({feedbacks: feedbacks, rooms: rooms, assignment: assignment, loading: false})
                    }).catch(e=>handleError(e));

            }).catch(e=>handleError(e));
    }

    handleDebaterChange(debaterId){
        let feedback = this.state.feedbacks[debaterId];
        this.setState({selectedDebaterId: debaterId, selectedFeedback: feedback});
    }

    handleRoomChange(chairId){
        const rooms = this.state.rooms;
        this.setState({
            selectedChairId: chairId,
            selectedDebaters: rooms[chairId].debaters,
            selectedDebaterId: null,
            selectedFeedback: null
        });
    }

    render() {
        if(this.state.loading) {
            return <div align="center"><CircularProgress/></div>
        }
        if(!this.state.rooms || !this.state.feedbacks
            || Object.keys(this.state.rooms).length <= 0
            || Object.keys(this.state.feedbacks).length <= 0) {
            return <h3 align="center">No Feedbacks Found</h3>
        }
        let roomNumber = 0;
        return(
            <div>
                <table style={{borderCollapse: 'collapse', margin:'0px', padding:'0px', maxHeight: 400, marginTop:'1%'}}><tbody><tr>
                    <td style={{verticalAlign:'top', padding: '2px'}}>
                        <MenuList style={{margin:'0px', padding:'0px'}}>
                            {Object.keys(this.state.rooms).map(id=>{
                                roomNumber++;
                                const room = this.state.rooms[id];
                                const chair = id==="TRASH" ? null : room['Chair'][0];
                                const debaters = room.debaters;
                                let feedbacksFilled = 0;
                                let feedbacksUnFilled = 0;
                                if(debaters && debaters.length > 0){

                                    // Creating an empty feedback for registered debaters that with no feedback:
                                    for(const debater of debaters) {
                                        if(debater.uid===id || debater.rank !== "Junior") continue;
                                        const feedback = this.state.feedbacks[debater.uid];
                                        if(feedback && !isEmptyFeedback(feedback)) {
                                            feedbacksFilled++;
                                        } else {
                                            feedbacksUnFilled++;
                                            const feedbacks = this.state.feedbacks;
                                            feedbacks[debater.uid] = {feedbackBy: id, score: 0};
                                        }
                                    }

                                    return(
                                        <div style={{display:'block'}} key={id}>
                                            <MenuItem key={id}
                                                      value={id}
                                                      onClick={()=>this.handleRoomChange(id)}
                                                      style={{color: id === "TRASH" ?'#CC0000':'#000000', cursor:'pointer',
                                                              width: 320, backgroundColor: this.state.selectedChairId === id ? '#DDDDDD':'#FFFFFF' }}
                                            >
                                                <div style={{display:'inline', marginLeft:'5%'}}>
                                                    <table style={{cellSpacing: 0}}><tbody><tr>
                                                        <td>{id === "TRASH" ? <CancelIcon/> : getAvatar(chair)}</td>
                                                        <td valign="middle" style={{paddingLeft: 10}}>
                                                            <span style={{fontWeight:this.state.selectedChairId===id?'bold':'normal'}}>
                                                                {id === "TRASH" ? "Cancelled" : getFullName(chair)}
                                                            </span>
                                                            <span hidden={(id === "TRASH")}
                                                                  style={{color: feedbacksUnFilled===0 ? '#00BB00':'#999999'}}>
                                                                  {"    (" + feedbacksFilled.toString() + "/" + (feedbacksFilled + feedbacksUnFilled).toString() +")"}
                                                            </span>
                                                            <span hidden={!(id === "TRASH")}
                                                                  style={{color: '#999999'}}>
                                                                  {"    ("+(debaters.length).toString() +")"}
                                                            </span>

                                                            <div style={{color: '#777777', fontSize: 12}}>
                                                                {room.Name ? room.Name : ("Room " + roomNumber.toString())}
                                                                {room.Language ? (", " + room.Language) : ""}
                                                            </div>
                                                        </td>
                                                    </tr></tbody></table>
                                                </div>
                                            </MenuItem>
                                        </div>)
                                } else return null;
                            })}
                        </MenuList>
                    </td><td style={{verticalAlign:'top', padding: '2px'}}>

                    {this.state.feedbacks && this.state.selectedChairId &&
                    <MenuList style={{margin:'0px', padding:'0px'}}>
                        {this.state.selectedDebaters.map(debater => {
                            const id = debater.uid;
                            const feedback = this.state.feedbacks[id];
                            if(id === this.state.selectedChairId) return null;
                            if(feedback && feedback.feedbackBy !== this.state.selectedChairId) return null;
                            if(!debater) return null;
                            return(
                                <div style={{display:'block'}} key={id+"." /*To avoid duplicate key warning*/}>
                                    <MenuItem value={id}
                                              onClick={this.state.selectedChairId==="TRASH" ? null : ()=>this.handleDebaterChange(id)}
                                              style={{color: debater.id < 0?'#CC0000':'#000000',
                                                  cursor: this.state.selectedChairId==="TRASH" ? 'auto' : 'pointer',
                                                  width: 300, backgroundColor: this.state.selectedDebaterId === id ? '#DDDDDD':'#FFFFFF'}}
                                    >
                                        <div style={{display:'inline', marginLeft:'5%', verticalAlign:'top'}}>
                                            {getAvatar(debater)}
                                            <span style={{marginLeft:'3%', display:'inline',
                                                fontWeight:this.state.selectedDebaterId === id ? 'bold':'normal'}}>
                                                {getFullName(debater)}
                                            </span>
                                            {feedback && !isEmptyFeedback(feedback) &&
                                                <Done style={{marginLeft: 10, color: '#00BB00'}} titleAccess={"Feedback Submitted"}/>
                                            }
                                        </div>
                                    </MenuItem>
                                </div>)
                        })}
                    </MenuList>
                    }

                </td><td style={{verticalAlign:'top', padding: 2}}>
                    {this.state.selectedFeedback && !isEmptyFeedback(this.state.selectedFeedback) &&
                        <FeedbackDetails feedback={this.state.selectedFeedback} />
                    }
                    {this.state.selectedFeedback && isEmptyFeedback(this.state.selectedFeedback) &&
                        <h3>Feedback Wasn't Submitted</h3>
                    }
                </td></tr></tbody></table>
            </div>
        )
    }
}
