import React, { Component } from "react";
import Save from "@material-ui/icons/Save";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Publish from "@material-ui/icons/Publish";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import TrashList from "./trashList";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormGroup from "@material-ui/core/FormGroup";
//import FeedbackIcon from '@material-ui/icons/Feedback';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ProjectionIcon from "@material-ui/icons/CastConnected";
import AddRoomIcon from "@material-ui/icons/AddBox";
import CommentIcon from "@material-ui/icons/Chat";
import SortAlpha from "@material-ui/icons/SortByAlpha";
import SortScore from "@material-ui/icons/Filter5";
import AssignmentCSVExporter from "./assignmentCSVexporter";
import LiveHelpTooltip from "./liveHelpTooltip";

const rankFilterStyle = {
  width: 100,
  marginBottom: 10,
  marginLeft: 5,
  marginRight: 5
};
const studentFilterStyle = {
  width: 100,
  marginBottom: 10,
  marginLeft: 5,
  marginRight: 5
};
const languageFilterStyle = {
  width: 100,
  marginBottom: 10,
  marginLeft: 5,
  marginRight: 5
};
const requestFilterStyle = {
  width: 120,
  marginBottom: 10,
  marginLeft: 5,
  marginRight: 30
};

const getAppbarStyle = () => ({
  marginTop: 10,
  background: "#E0E1E9"
});

/*
 * The toolbar containig most of the assignment page's functionalities
 */
class AssignmentToolbar extends Component {
  state = {};
  render() {
    return (
      <AppBar position="static" style={getAppbarStyle()}>
        <Toolbar variant="dense">
          <IconButton onClick={this.props.addRoom} style={{ marginRight: 50 }}>
            <Tooltip title="Add Room" placement="top">
              <AddRoomIcon style={{ fontSize: 50 }} />
            </Tooltip>
          </IconButton>

          <TextField
            style={rankFilterStyle}
            id="rankFilter"
            select
            label="Rank"
            value={this.props.rankFilter}
            onChange={this.props.handleChangeRankFilter}
            margin="normal"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Seniors">Seniors</MenuItem>
            <MenuItem value="Juniors">Juniors</MenuItem>
          </TextField>

          <TextField
            style={studentFilterStyle}
            id="studentFilter"
            select
            label="Student"
            value={this.props.studentFilter}
            onChange={this.props.handleChangeStudentFilter}
            margin="normal"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Student">Student</MenuItem>
            <MenuItem value="Non-st">Non Student</MenuItem>
          </TextField>

          <TextField
            style={languageFilterStyle}
            id="languageFilter"
            select
            label="Language"
            value={this.props.languageFilter}
            onChange={this.props.handleChangeLanguageFilter}
            margin="normal"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Hebrew">Hebrew</MenuItem>
            <MenuItem value="English">English</MenuItem>
            <MenuItem value="No preference">No Preference</MenuItem>
          </TextField>

          <TextField
            style={rankFilterStyle}
            id="clubFilter"
            select
            label="Club"
            value={this.props.clubFilter}
            onChange={this.props.handleChangeClubFilter}
            margin="normal"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Club-mem">TAU</MenuItem>
            <MenuItem value="Club-non-mem">Non-TAU</MenuItem>
          </TextField>

          <TextField
            style={requestFilterStyle}
            id="requestFilter"
            select
            label="Request"
            value={this.props.requestFilter}
            onChange={this.props.handleChangeRequestFilter}
            margin="normal"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Comments">Comments</MenuItem>
            <MenuItem value="Debate">Debate</MenuItem>
            <MenuItem value="Judge">Judge</MenuItem>
            <MenuItem value="No-Pref">No Preferences</MenuItem>
          </TextField>

          <IconButton onClick={this.props.sortByAlphabet}>
            <Tooltip title="Sort Alphabetically" placement="top">
              <SortAlpha />
            </Tooltip>
          </IconButton>

          <IconButton
            onClick={this.props.sortByScore}
            style={{ marginRight: 40 }}
          >
            <Tooltip title="Sort By Score" placement="top">
              <SortScore />
            </Tooltip>
          </IconButton>

          <IconButton onClick={this.props.openDatabase}>
            <Tooltip title="Add Debater from Database" placement="top">
              <PersonAdd />
            </Tooltip>
          </IconButton>

          <IconButton onClick={this.props.automaticAssignment}>
            <Tooltip title="Automatic Assignment" placement="top">
              <PlayCircleFilled />
            </Tooltip>
          </IconButton>

          <IconButton onClick={this.props.openComment}>
            <Tooltip title="Add Comment" placement="top">
              <CommentIcon />
            </Tooltip>
          </IconButton>

          <IconButton
            onClick={this.props.saveAsDraft}
            style={{ marginLeft: 50 }}
          >
            <Tooltip title="Save as Draft" placement="top">
              <Save />
            </Tooltip>
          </IconButton>

          <IconButton onClick={this.props.publishAssignment}>
            <Tooltip title="Publish" placement="top">
              <Publish />
            </Tooltip>
          </IconButton>

          {/*
                        Commented out by Nadav, 22.6 after deciding to move the "request feedback" logic to events management page
                        To fully implement this decision, delete all referenced to "requestFeedbacks" in this component and Assignment.jsx

                        <IconButton onClick={this.props.requestFeedbacks} disabled={this.props.feedbackRequested}>
                        <Tooltip title="Request Feedbacks" placement="top">
                         <FeedbackIcon />
                         </Tooltip>
                        </IconButton>*/}

          {this.props.admin && (
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.incognito}
                  onChange={this.props.toggleIncognito}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Incognito"
              style={{ marginLeft: 50 }}
            />
          )}

          <IconButton
            onClick={() => {
              window.open("/projection/" + this.props.event.id, "_blank");
            }}
          >
            <Tooltip title="Project To Screen" placement="top">
              <ProjectionIcon />
            </Tooltip>
          </IconButton>

          <AssignmentCSVExporter
            rooms={this.props.rooms}
            event={this.props.event}
          />

          <div
            className="right-icons"
            style={{ position: "absolute", right: 0, marginRight: 10 }}
          >
            <FormGroup row>
              <TrashList
                registrations={this.props.registrations}
                incognito={this.props.incognito}
                onTrashListClose={this.props.toggleTrashRoom}
                trashListOpen={this.props.openTrashListDialog}
                trashListData={this.props.trashList}
                changeDebaterId={this.props.changeDebaterId}
                onTrashListOpen={this.props.toggleTrashRoom}
                updateRegistaration={this.props.updateRegistaration}
              />

              {/* <IconButton>
                            <Tooltip title="Trash List" placement="top">
                            <TrashListIcon style={{ fontSize: 50 }} />
                            </Tooltip>
                            </IconButton> */}
              <LiveHelpTooltip />
            </FormGroup>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default AssignmentToolbar;
