import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";
import MyDraggable from "./myDraggable";
import * as eventStatsFunctions from "../EventManagementComps/EventsTable/EventStats";

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#E0E1E9",
  boxShadow: "0px 2px 16px 2px rgba(0,0,0,0.75)",
  display: "flex",
  padding: "4px 4px 10px 4px",
  overflow: "auto",
  minHeight: 55
});

const getFixed = () => ({
  position: "fixed",
  bottom: 0,
  width: "100%"
});

/*
 * This list is displayed at the bottom of the assignment page and contains debaters left to assign
 */
class HorizontalDroppable extends Component {
  hasComments = debater => {
    let debaterRegistration = this.props.registrations[debater.uid];
    if (
      (debaterRegistration.additionalComments !== "" &&
        debaterRegistration.additionalComments !==
          "Manually registered by admins") ||
      debaterRegistration.language !== "No preference"
    ) {
      return true;
    } else {
      return false;
    }
  };

  wantsToDebate = debater => {
    let debaterRegistration = this.props.registrations[debater.uid];
    if (debaterRegistration.debateJudge === "Debate") {
      return true;
    } else {
      return false;
    }
  };

  wantsToJudge = debater => {
    let debaterRegistration = this.props.registrations[debater.uid];
    if (debaterRegistration.debateJudge === "Judge") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    let data = this.props.data;
    //if(data.length < 13){
    return (
      <div style={getFixed()}>
        <Droppable
          droppableId={`${this.props.droppableId}1`}
          direction="horizontal"
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {data.map((item, index) => {
                let rankFilter = this.props.rankFilter;
                let studentFilter = this.props.studentFilter;
                let languageFilter = this.props.languageFilter;
                let clubFilter = this.props.clubFilter;
                let requestFilter = this.props.requestFilter;

                if (
                  rankFilter === "All" ||
                  (eventStatsFunctions.juniorsFilter(item) &&
                    rankFilter === "Juniors") ||
                  (eventStatsFunctions.seniorsFilter(item) &&
                    rankFilter === "Seniors")
                ) {
                  if (
                    studentFilter === "All" ||
                    (eventStatsFunctions.studentFilter(item) &&
                      studentFilter === "Student") ||
                    (eventStatsFunctions.nonStudentFilter(item) &&
                      studentFilter === "Non-st")
                  ) {
                    if (
                      clubFilter === "All" ||
                      (eventStatsFunctions.clubMemFilter(item) &&
                        clubFilter === "Club-mem") ||
                      (eventStatsFunctions.nonClubMemFilter(item) &&
                        clubFilter === "Club-non-mem")
                    ) {
                      if (
                        requestFilter === "All" ||
                        (requestFilter === "Comments" &&
                          this.hasComments(item)) ||
                        (requestFilter === "Debate" &&
                          this.wantsToDebate(item)) ||
                        (requestFilter === "Judge" &&
                          this.wantsToJudge(item)) ||
                        (requestFilter === "No-Pref" &&
                          !this.wantsToJudge(item) &&
                          !this.wantsToDebate(item) &&
                          !this.hasComments(item))
                      ) {if (
                        languageFilter === "All" ||
                        (eventStatsFunctions.hebrewFilter(item) &&
                        languageFilter === "Hebrew") ||
                        (eventStatsFunctions.englishFilter(item) &&
                        languageFilter === "English")
                        ||
                        (eventStatsFunctions.noPrfLangFilter(item) &&
                        languageFilter === "No preference")
                      ){
                        return (
                          <MyDraggable
                            registrations={this.props.registrations}
                            incognito={this.props.incognito}
                            item={item}
                            key={item.uid}
                            draggableId={item.uid}
                            index={index}
                            changeDebaterId={this.props.changeDebaterId}
                            updateRegistaration={this.props.updateRegistaration}
                          />
                        );
                      }
                      }
                    }
                  }
                }
                return null;
              })}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

export default HorizontalDroppable;
