import {
  addDays,
  addHours,
  truncateDate,
  tsToDate
} from "../../Utilities/DateTimeUtils";

export function getAllEventDates(allEvents) {
  const dates = [];
  for (const eventId in allEvents) {
    dates.push(allEvents[eventId].date);
  }
  return dates;
}

function getNextAvailableTuesday(hour, allEvents) {
  return getNextAvailableDay(hour, allEvents, 0);
}

function getNextAvailableFriday(hour, allEvents) {
  return getNextAvailableDay(hour, allEvents, 5);
}

function getNextAvailableDay(hour, allEvents, dayOfWeek = null) {
  let date = today();
  const allEventDates = getAllEventDates(allEvents);

  if (dayOfWeek) {
    date.setDate(date.getDate() - date.getDay() + dayOfWeek);
    if (date.getTime() <= today().getTime()) date = addDays(date, 7);
  } else date = addDays(date, 1);

  let foundAvailableDate = false;
  while (!foundAvailableDate) {
    foundAvailableDate = true;
    for (const date2 of allEventDates) {
      if (tsToDate(date2).getDay() === date.getDay()) {
        if (tsToDate(date2).getMonth() === date.getMonth()) {
          if (tsToDate(date2).getFullYear() === date.getFullYear()) {
            foundAvailableDate = false;
          }
        }
      }
    }
    if (!foundAvailableDate) date = addDays(date, dayOfWeek ? 7 : 1);
  }
  date.setHours(hour);
  return date;
}

export function getEmptyEvent(date) {
  const time = truncateDate(new Date(date.valueOf()));
  time.setHours(18);
  return {
    time: time,
    event_type: "Custom Round",
    event_type_option: "Other",
    event_subject: "",
    open_to_jr: true,
    open_to_sr: true,
    open_to_other_clubs: true,
    open_to_non_students: true,
    location: "",
    requestedFeedbacks: false
  };
}

export function today() {
  const date = truncateDate(new Date());
  return date;
}

export function getDefaultEvents(name, allEvents) {
  let date, registration_start, registration_end, check_in_start, events;
  switch (name) {
    case "friday":
      date = getNextAvailableFriday(0, allEvents);
      events = [
        {
          time: getNextAvailableFriday(9, allEvents),
          event_type: "Round 1",
          event_type_option: "Round 1",
          event_subject: "",
          open_to_jr: false,
          open_to_sr: true,
          open_to_other_clubs: true,
          open_to_non_students: true,
          location: "",
          requestFeedbacks: false
        },
        {
          time: getNextAvailableFriday(11, allEvents),
          event_type: "Round 2",
          event_type_option: "Round 2",
          event_subject: "",
          open_to_jr: false,
          open_to_sr: true,
          open_to_other_clubs: true,
          open_to_non_students: true,
          location: "",
          requestFeedbacks: false
        }
      ];
      break;
    case "tuesday_with_lesson":
      date = getNextAvailableTuesday(0, allEvents);
      events = [
        {
          time: getNextAvailableTuesday(18, allEvents),
          event_type: "Lesson",
          event_type_option: "Lesson",
          event_subject: "",
          open_to_jr: true,
          open_to_sr: false,
          open_to_other_clubs: false,
          location: "",
          requestFeedbacks: false
        },
        {
          time: getNextAvailableTuesday(18, allEvents),
          event_type: "Round 1",
          event_type_option: "Round 1",
          event_subject: "",
          open_to_jr: false,
          open_to_sr: true,
          open_to_other_clubs: true,
          open_to_non_students: true,
          location: "",
          requestFeedbacks: false
        },
        {
          time: getNextAvailableTuesday(20, allEvents),
          event_type: "Round 2",
          event_type_option: "Round 2",
          event_subject: "",
          open_to_jr: true,
          open_to_sr: true,
          open_to_other_clubs: true,
          open_to_non_students: true,
          location: "",
          requestFeedbacks: false
        }
      ];
      break;
    case "tuesday_no_lesson":
      date = getNextAvailableTuesday(0, allEvents);
      events = [
        {
          time: getNextAvailableTuesday(18, allEvents),
          event_type: "Round 1",
          event_type_option: "Round 1",
          event_subject: "",
          open_to_jr: true,
          open_to_sr: true,
          open_to_other_clubs: true,
          open_to_non_students: true,
          location: "",
          requestFeedbacks: false
        },
        {
          time: getNextAvailableTuesday(20, allEvents),
          event_type: "Round 2",
          event_type_option: "Round 2",
          event_subject: "",
          open_to_jr: true,
          open_to_sr: true,
          open_to_other_clubs: true,
          open_to_non_students: true,
          location: "",
          requestFeedbacks: false
        }
      ];
      break;
    case "custom":
      date = getNextAvailableDay(0, allEvents);
      events = [getEmptyEvent(date)];
      break;
    default:
      break;
  }

  // Default registration times:
  registration_start = addHours(new Date(), -1);
  registration_end = new Date(date.valueOf());

  // Default check in time: 7:00 AM, day of the event
  check_in_start = addHours(date, 7);

  return {
    date: date,
    registration_start: registration_start,
    registration_end: registration_end,
    check_in_start: check_in_start,
    events: events
  };
}
