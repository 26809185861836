import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';

class ProjectionListItem extends Component {
    state = {  }
    render() {
        let name;
        if (this.props.item.full_name_eng) {
            name = this.props.item.full_name_eng;
          } else {
            name = `${this.props.item.first_name_eng} ${
              this.props.item.last_name_eng
            }`;
        }
        return (
            <ListItem style={{margin:0,padding:0, textAlign:'center', justifyContent:'center'}} >
                <p style={{ fontSize: '13px', margin:'2px 0'}}>{name}</p>
            </ListItem>
         );
    }
}

export default ProjectionListItem;
