import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  handleError,
  pageLoaded,
  removeDuplicates
} from "../components/Utilities/OtherUtils";
import {
  tsToDate,
  defaultFirstDate,
  defaultLastDate,
  truncateDate,
  getEventsArrayByDate,
  formatDateDayTime
} from "../components/Utilities/DateTimeUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { NewEventForm } from "../components/EventManagementComps/CreateEventsForm/NewEventForm";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  add_event,
  delete_event,
  get_all_debaters,
  get_events_nofuturelimit,
  load_assignment,
  publish_feedbacks,
  update_event
} from "../Backend/BackendFunctions";
import { EmailRegisteredDebaters } from "../components/EventManagementComps/EmailRegisteredDebaters";
import { getEventsManagementToolbar } from "../components/EventManagementComps/EventsTable/EventManagementToolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMore from "@material-ui/icons/KeyboardArrowRight";
import ExpandLess from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import ShareIcon from "@material-ui/icons/Share";
import { SingleEventTable } from "../components/EventManagementComps/EventsTable/SingleEventTable";
import { ShareEventsInvitations } from "../components/EventManagementComps/ShareEventsInvitations";

/**
 * The complete portal for managing debate events
 * Includes all relevant event information, including life-cycle elements (registrations, feedbacks, assignment data)
 * and components for creating, deleting and editing events
 */
export class EventsManagement extends Component {
  constructor(props) {
    super(props);
    this.props.history.push(this.props.match.url);
    this.state = {
      pastFutureFilter: "future",
      events: {},
      allEventDates: [],
      allDebaters: {},
      expandedDates: new Set(),
      pageSize: 20,
      lastEvent: null,
      toAssignmentsEvent: null,
      firstDate: defaultFirstDate,
      lastDate: defaultLastDate,
      refreshTable: true,
      emailDialogDate: null,
      invitationDialogDate: null,
      openDialog: false,
      editEventsArr: null,
      loading: true,
      width: window.innerWidth
    };

    this.countEventDates = this.countEventDates.bind(this);
    this.inRange = this.inRange.bind(this);
    this.closeEventsDialog = this.closeEventsDialog.bind(this);
    this.editEventsByDate = this.editEventsByDate.bind(this);
    this.updateEvents = this.updateEvents.bind(this);
    this.updateExistingEvents = this.updateExistingEvents.bind(this);
    this.toggleEventsByDate = this.toggleEventsByDate.bind(this);
    this.refresh = this.refresh.bind(this);
    this.requestFeedbacks = this.requestFeedbacks.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  componentDidMount() {
    if (pageLoaded("events")) {
      this.props.onLoad();
      this.refresh();
    }
  }

  // removing the listener when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  }

  // Get events from DB and process them to the table's format
  refresh() {
    this.setState({ loading: true });
    get_events_nofuturelimit(
      this.state.firstDate,
      this.state.pageSize,
      this.state.lastEvent
    )
      .then(events => {
        const allEventDates = [];
        for (const id in events) {
          const e = events[id];
          allEventDates.push(tsToDate(e.date));
        }
        const sortDatesFunc = (d1, d2) => {
          const todayTime = truncateDate(new Date()).getTime();

          // Both future - sort old-new
          if (d1.getTime() >= todayTime && d2.getTime() >= todayTime)
            return d1.getTime() < d2.getTime() ? -1 : 1;
          // Otherwise - sort new - old
          else return d1.getTime() < d2.getTime() ? 1 : -1;
        };
        allEventDates.sort(sortDatesFunc);
        get_all_debaters(1000, null)
          .then(debaters => {
            for (const id in debaters) debaters[id].id = id; // Adding the key of the dictionary to the items themselves
            this.setState({
              allDebaters: debaters,
              allEventDates: removeDuplicates(allEventDates),
              events: events,
              loading: false,
              editEventsArr: null
            });
          })
          .catch(e => handleError(e, "Failed Retrieving Debaters"));
      })
      .catch(e => handleError(e, "Failed Retrieving Events"));
  }

  // Opens the edit dialog with the events of @param date
  editEventsByDate(date) {
    const eventsArr = getEventsArrayByDate(date, this.state.events);
    this.setState({ openDialog: true, editEventsArr: eventsArr });
  }

  requestFeedbacks(event) {
    this.setState({ loading: true });
    const events = this.state.events;
    load_assignment(event.id)
      .then(assignment => {
        if (Object.keys(assignment).length === 0) {
          toast.error("No Assignment Yet");
          return;
        } else {
          publish_feedbacks(assignment, event)
            .then(() => {
              events[event.id].requestedFeedbacks = true;
              update_event([events[event.id]])
                .then(() => {
                  this.setState({ events: events, loading: false });
                  toast.info("Successfully Requested Feedbacks");
                })
                .catch(error => handleError(error));
            })
            .catch(error => handleError(error));
        }
      })
      .catch(error => handleError(error));
  }

  /*
   * The user edits this.state.editEventsArr and returns eventsArr after deleting / adding / editing existing events
   * This function handles all three - first delete, than add new events and finally update existing
   */
  updateEvents(eventsArr) {
    this.setState({ loading: true });
    this.closeEventsDialog();

    // If this.state.editEventsArr is null, only new events were added
    if (!this.state.editEventsArr && eventsArr.length > 0) {
      eventsArr = eventsArr.filter(event => !event.delete_me);
      if (eventsArr.length === 0) {
        this.refresh();
        return;
      }
      add_event(eventsArr)
        .then(() => {
          toast.info("Successfully Created Events");
          this.refresh();
        })
        .catch(e => handleError(e, "Failed Adding Events"));
      return;
    }

    // Split the events to three groups: needs to be added / removed / updated
    const eventsToAdd = [];
    const eventsToUpdate = [];
    const eventsToRemove = [];

    // Events that returned from the dialog (to add or to update)
    for (const event of eventsArr) {
      if (event.delete_me) eventsToRemove.push(event);
      else if (!event.id) eventsToAdd.push(event);
      else eventsToUpdate.push(event);
    }

    for (const event of eventsArr) delete event.delete_me; // Remove this key

    const afterDeleting = () => {
      if (eventsToAdd.length > 0) {
        add_event(eventsToAdd)
          .then(() => this.updateExistingEvents(eventsToUpdate))
          .catch(e => handleError(e, "Failed Adding Events"));
      } else this.updateExistingEvents(eventsToUpdate);
    };

    if (eventsToRemove.length > 0) {
      delete_event(eventsToRemove)
        .then(() => afterDeleting())
        .catch(e => handleError(e, "Failed Deleting Events"));
    } else {
      afterDeleting();
    }
  }

  // @pre: all events in eventsArr already exist in data base
  updateExistingEvents(eventsArr) {
    update_event(eventsArr)
      .then(() => {
        toast.info("Events Updated Successfully");
        this.refresh();
      })
      .catch(e => handleError(e, "Failed Updating Events"));
  }

  closeEventsDialog() {
    this.setState({ openDialog: false, editEventsArr: null });
  }

  toggleEventsByDate(date) {
    const expandedDates = this.state.expandedDates;
    if (expandedDates.has(date.valueOf())) expandedDates.delete(date.valueOf());
    else expandedDates.add(date.valueOf());
    this.setState({ expandedDates: expandedDates });
  }

  countEventDates() {
    let cnt = 0;
    for (const date of this.state.allEventDates) {
      if (this.inRange(date)) cnt++;
    }
    return cnt;
  }

  inRange(date) {
    return (
      this.state.pastFutureFilter === "both" ||
      (truncateDate(date).getTime() >= truncateDate(new Date()).getTime() &&
        this.state.pastFutureFilter === "future") ||
      (truncateDate(date).getTime() < truncateDate(new Date()).getTime() &&
        this.state.pastFutureFilter === "past")
    );
  }

  tableWidth = 1100;
  columns = [
    "Actions & Extra Info",
    "Event Type",
    "Time",
    "Location",
    "Registered",
    "Participated",
    "Rooms",
    "Feedbacks"
  ];
  render() {
    if (!pageLoaded("events")) return null;
    const tableTitle = getEventsManagementToolbar(this);
    return (
      <div align="center" style={{ width: "100%" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{ marginTop: "5%", maxWidth: this.tableWidth }}>
            {this.state.emailDialogDate && (
              <EmailRegisteredDebaters
                allDebaters={this.state.allDebaters}
                onClose={() => this.setState({ emailDialogDate: null })}
                eventsArr={getEventsArrayByDate(
                  this.state.emailDialogDate,
                  this.state.events
                )}
              />
            )}

            {this.state.invitationDialogDate && (
              <ShareEventsInvitations
                date={this.state.invitationDialogDate}
                onClose={() => this.setState({ invitationDialogDate: null })}
                events={getEventsArrayByDate(
                  this.state.invitationDialogDate,
                  this.state.events
                )}
              />
            )}

            <Dialog
              open={this.state.openDialog}
              onClose={this.closeEventsDialog}
            >
              <DialogContent>
                <NewEventForm
                  onClose={() => this.closeEventsDialog()}
                  eventsArr={this.state.editEventsArr}
                  allEvents={this.state.events}
                  onSubmit={eventsArr => this.updateEvents(eventsArr)}
                />
              </DialogContent>
            </Dialog>

            <div>
              <Paper style={{ paddingTop: 20 }}>
                {tableTitle}
                <hr />
                {this.state.loading && (
                  <div align={"center"}>
                    <CircularProgress />
                  </div>
                )}

                {!this.state.loading && this.countEventDates() === 0 && (
                  <p align={"center"} style={{ color: "#999999" }}>
                    No events to display
                    <br />
                    <br />
                  </p>
                )}

                {this.state.allEventDates &&
                  this.state.allEventDates.length > 0 &&
                  this.state.allEventDates.map(date => {
                    // Apply past/future filter
                    if (!this.inRange(date)) return null;

                    const expanded = this.state.expandedDates.has(
                      date.valueOf()
                    );
                    const events = getEventsArrayByDate(
                      date,
                      this.state.events
                    );
                    return (
                      <div key={date.valueOf()} align={"left"}>
                        <span style={{ marginLeft: 15, fontSize: 16 }}>
                          <IconButton
                            title={expanded ? "Hide Events" : "Show Events"}
                            onClick={() => this.toggleEventsByDate(date)}
                          >
                            {expanded ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                          {formatDateDayTime(date)}
                          <IconButton
                            title="Edit Events"
                            onClick={() => this.editEventsByDate(date)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            title="Email Registered Debaters"
                            onClick={() =>
                              this.setState({ emailDialogDate: date })
                            }
                          >
                            <EmailIcon />
                          </IconButton>
                          <IconButton
                            title="Share Invitation"
                            onClick={() =>
                              this.setState({ invitationDialogDate: date })
                            }
                          >
                            <ShareIcon />
                          </IconButton>
                        </span>
                        <hr />
                        <Table style={{ display: expanded ? "block" : "none" }}>
                          <TableHead>
                            <TableRow>
                              {this.columns.map(column => (
                                <TableCell key={column} align="center">
                                  {column}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {events.map(event => {
                              return (
                                <SingleEventTable
                                  key={event.id}
                                  columns={this.columns}
                                  tableWidth={this.tableWidth}
                                  user={this.props.user}
                                  event={event}
                                  allDebaters={this.state.allDebaters}
                                  onFeedbackRequest={event =>
                                    this.requestFeedbacks(event)
                                  }
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                    );
                  })}
              </Paper>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}
