import React, { Component } from 'react';
import UserProfileCard from '../UserComponents/UserDetailsCard/UserDetailsCard'
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';

/**
 * A card containg a user's extended details (a popper opened by clicking 
 * the info icon on the draggable debater card)
 */
class UserDetailsPopper extends Component {
    state = { };
    render() {
        if(!this.props.registration || !this.props.debater) return null;
        return (
            <Popper
                id={this.props.id}
                open={this.props.showUserId}
                placement='right'
                anchorEl={this.props.anchorEl}
                transition>
            {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <UserProfileCard
                    incognito = {this.props.incognito}
                    debater={this.props.debater}
                    registration={this.props.registration}
                    changeDebaterId={this.props.changeDebaterId}
                    close={this.props.close}/>
            </Fade>
            )}
            </Popper>
        );
    }
}

export default UserDetailsPopper;
