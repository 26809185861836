/**
 * @param str
 * @returns {String}
 */
export function titleCase(str){
    str = str.replace('_',' ').replace('_',' ').replace('_',' ').replace('_',' ');
    str = str.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    return str;
}


/**
 * @param object
 * @returns {object}
 */
export function deepClone(object){
    return JSON.parse(JSON.stringify(object));
}

/**
 * @param str
 * @returns {boolean}
 */
export function validEmail(str) {
    return !!str.match(/.+@.+/);
}
/**
 * @param str
 * @returns {boolean}
 */
export function validPhoneNumber(str) {
    return !!str.match("^05[0-9]{8}");
}
/**
 * @param str
 * @returns {boolean}
 */
export function validFullNameEng(str) {
    return str.match(/^[A-z]([-']?[A-z]+)*( [A-z]([-']?[A-z]+)*)+$/)
}
/**
 * @param str
 * @returns {boolean}
 */
export function validFullNameHeb(str) {
    return str.match(/^[א-ת]([-']?[א-ת]+)*( [א-ת]([-']?[א-ת]+)*)+$/)
}

