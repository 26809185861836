import React, { Component } from "react"
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import {deepClone, titleCase} from "../Utilities/StringUtils";

/**
 * Custom component for a multi-select checkbox menu
 */
export class MultiSelectMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            values: deepClone(this.props.values)
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleOpen(event) {
        this.setState({anchorEl: event.currentTarget});
    }

    handleClose() {
        this.setState({anchorEl: null});
    }

    changeValues(value, index) {
        const newValues = this.state.values;
        newValues[value] = !newValues[value];
        const props = this.props;
        this.setState({values: newValues}, () => {
            // Timeout makes the re-rendering of this state happen before the prop update which could be slower
            setTimeout(function() {
                props.onToggle(value, index);
            },0);
        });
    }

    render(){
        return (
            <div style={{margin: 0, display: 'inline', padding: 0}}>
                <IconButton onClick={(e)=>this.handleOpen(e)}
                            style={{margin: 0}}
                            title={this.props.title}>
                    {this.props.buttonIcon}
                </IconButton>
                <Menu
                    keepMounted
                    anchorEl={this.state.anchorEl}
                    open={this.state.anchorEl !== null}
                    onClose={this.handleClose}
                >
                    <br/><label style={{margin: 30}}>Add or Remove Columns</label><br/><br/>
                    {Object.keys(this.state.values).map((key, index) => (
                        <MenuItem key={key}>
                            <Checkbox checked={this.state.values[key]}
                                      onChange={()=>this.changeValues(key, index)}/>
                            {titleCase(key)}
                        </MenuItem>
                    ))}
                </Menu>

            </div>
        );
    }
}
