import React, { Component } from "react";
//import "./Login.css";
import "../../App.css";
import firebase from "../../Backend/fire";
import { auth } from "../../Backend/fire";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Redirect } from "react-router";
import Paper from "@material-ui/core/Paper";
import { getCurrentLocation } from "../../components/Utilities/OtherUtils";
export class Login extends Component {
  state = {
    isSignedIn: false,
    userId: undefined
  };

  uiConfig = {
    //signInSuccessUrl: "/home/" + getCurrentLocation(), //"/home",
    //signInFlow: "popup",
    signInSuccessUrl: "/" + getCurrentLocation(),
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => true
    }
  };

  componentDidMount = () => {
    this.props.onLoad();
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ isSignedIn: !!user, userId: user.uid });
      } else {
        this.setState({
          isSignedIn: false,
          userId: undefined
        });
      }
    });
  };

  authChanged() {}

  renderRedirect = () => {
    return <Redirect to={"/" + getCurrentLocation()} />;
  };

  render() {
    if (this.state.isSignedIn) {
      this.renderRedirect();
    }
    return (
      <div
        className="marginTop"
        style={{
          marginTop: 70,
          marginRight: "auto",
          marginLeft: "auto",
          maxWidth: 400,
          align: "center"
        }}
      >
        <div className="container">
          <Paper
            id="content"
            style={{
              background: "#f9f9f9"
            }}
          >
            <form>
              <div className="App__Form" style={{ padding: "5%" }}>
                <div className="image-cropper">
                  <img
                    src={require("../../logo.png")}
                    alt="TAU Logo"
                    className="profile-pic"
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h1>Sign In!</h1>
                  <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                  />
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </div>
    );
  }
}
