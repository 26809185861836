import React, { Component } from "react";
import MaterialTable, { MTableCell } from "material-table";
import {
  formatDate,
  defaultLastDate,
  defaultFirstDate,
  truncateDate
} from "../components/Utilities/DateTimeUtils";
import { pageLoaded, handleError } from "../components/Utilities/OtherUtils";
import { get_all_debaters, get_events } from "../Backend/BackendFunctions";
import { UserDetailsDialog } from "../components/UserComponents/UserDetailsPageComponents/UserDetailsDialog";
import { UsersTableTitle } from "../components/UserManagementComps/UsersTableTitle";
import { createTableData } from "../components/UserManagementComps/UsersTableData";
import { getDebaterColumns } from "../components/UserManagementComps/UsersTableColumns";
import {
  get_debate_history,
  update_debate_history
} from "../Backend/debateHistoryBackendFunctions";
import VirtualizedMaterialTable from "../components/VirtualizedMaterialTable";


// import { AutoSizer, List } from "react-virtualized";



/**
 * This page allows admins to view and analyze personal information and performance of the entire club's users
 * Consisted of three main components:
 * * Title: A toolbar with filters and actions
 * * Columns: Two arrays - one with personal info columns (email, phone number etc.) and one with
 *            a column per event (excluding lessons)
 * * Table Data: An array of rows. 3 Rows per User X Event.
 *               First row (score) is always visible, other two are expandable (chair+feedback and position)
 *
 * The table is based on material-table open source project: https://material-table.com/#/
 */
export class UserManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Routing, filtering etc.
      loading: true,
      selectedUser: null,
      showStudents: true,
      showNonStudents: true,
      showJuniors: true,
      showSeniors: false,
      nameFilter: null,
      firstDate: defaultFirstDate,
      lastDate: truncateDate(new Date()),
      width: window.innerWidth,

      // Data
      deleteDebaterIds: new Set(),
      eventColumns: [],
      debaterColumns: [],
      debaterColumnsFilter: {},
      allDebaters: {},
      exportData: [],
      tableData: [],
      events: null,
      fullHistory: null
    };

    this.refresh = this.refresh.bind(this);
    this.openUserDialog = this.openUserDialog.bind(this);
    this.closeUserDialog = this.closeUserDialog.bind(this);
    this.changeLastDate = this.changeLastDate.bind(this);
    this.changeFirstDate = this.changeFirstDate.bind(this);
    this.toggleStudents = this.toggleStudents.bind(this);
    this.toggleRank = this.toggleRank.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.rowFilter = this.rowFilter.bind(this);
    this.countFilteredDebaters = this.countFilteredDebaters.bind(this);
    this.countEvents = this.countEvents.bind(this);
    this.refreshHistory = this.refreshHistory.bind(this);
    this.setNameFilter = this.setNameFilter.bind(this);
    this.updateFilteredTableData = this.updateFilteredTableData.bind(this);
  }

  componentDidMount() {
    if (pageLoaded("users")) {
      this.props.onLoad();
      this.refresh();
    }
  }

  refresh(firstDate, lastDate) {
    let realFirstDate = firstDate ? firstDate : defaultFirstDate;
    let realLastDate = lastDate ? lastDate : defaultLastDate;

    get_events(realFirstDate, realLastDate, 1000, null)
      .then(events => {
        get_all_debaters(1000, null)
          .then(allDebaters => {
            const onUserClick = debater => this.openUserDialog(debater);
            const debaterColumns = getDebaterColumns(allDebaters, onUserClick);
            const debaterColumnsFilter = {};
            for (const column of debaterColumns) {
              if (column.field)
                debaterColumnsFilter[column.field] = !column.hidden;
            }
            get_debate_history()
              .then(fullHistory => {
                this.setState(
                  {
                    fullHistory: fullHistory,
                    events: events,
                    allDebaters: allDebaters,
                    loading: true,
                    debaterColumns: debaterColumns,
                    debaterColumnsFilter: debaterColumnsFilter
                  },
                  () => createTableData(this)
                );
              })
              .catch(e => handleError(e, "Failed Fetching Debate History"));
          })
          .catch(e => handleError(e, "Failed Fetching Users"));
      })
      .catch(e => handleError(e, "Failed Fetching Events"));
  }

  refreshHistory() {
    this.setState({ loading: true });
    update_debate_history()
      .then(() => this.refresh())
      .catch(e => handleError(e));
  }

  toggleRank(rank) {
    const showSeniors = rank === 'both' || rank === 'senior';
    const showJuniors = rank === 'both' || rank === 'junior';
    this.setState({ showSeniors: showSeniors, showJuniors: showJuniors },
      () => this.updateFilteredTableData());

  }

  toggleStudents(students) {
    const showStudents = students === 'students' || students === 'both';
    const showNonStudents = students === 'non_students' || students === 'both';
    this.setState({ showStudents: showStudents, showNonStudents: showNonStudents },
      () => this.updateFilteredTableData());
  }

  setNameFilter(name) {
    this.setState({ nameFilter: name }, () => this.updateFilteredTableData());
  }

  updateFilteredTableData() {
    const filteredTableData = [];
    for (const row of this.state.tableData) {
      if (!(this.rowFilter(row))) continue;
      const copyRow = {};
      for (const column in row) {
        copyRow[column] = row[column];
      }
      filteredTableData.push(copyRow);
    }
    this.setState({ filteredTableData: filteredTableData });
  }

  changeFirstDate(newDate) {
    this.setState({ firstDate: newDate ? newDate : defaultFirstDate });
    this.setState({ loading: true });
    this.refresh(newDate, null);
  }

  changeLastDate(newDate) {
    this.setState({ lastDate: newDate ? newDate : defaultLastDate });
    this.setState({ loading: true });
    this.refresh(null, newDate);
  }

  closeUserDialog() {
    this.setState({ selectedUser: null });
  }
  openUserDialog(user) {
    this.setState({ selectedUser: user });
  }

  toggleColumn(column, index) {
    const debaterColumns = this.state.debaterColumns;
    const debaterColumnsFilter = this.state.debaterColumnsFilter;
    debaterColumnsFilter[column] = !debaterColumnsFilter[column];
    debaterColumns[index + 1]["hidden"] = !debaterColumns[index + 1]["hidden"]; // +1 because first column should be fixed
    this.setState({
      debaterColumns: debaterColumns,
      debaterColumnsFilter: debaterColumnsFilter
    });
  }

  //checking if on mobile or on desktop
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // removing the listener when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  }

  handleDeleteUser() {
    const deleteDebaterIds = this.state.deleteDebaterIds;
    deleteDebaterIds.add(this.state.selectedUser.uid);
    this.setState({ deleteDebaterIds: deleteDebaterIds });
  }

  rowFilter(row) {
    if (
      this.state.deleteDebaterIds.has(row.id) ||
      this.state.deleteDebaterIds.has(row.parentId)
    )
      return false;
    let isStudent = row.student;
    let isJunior = row.rank === "Junior";
    return !(
      (isStudent && !this.state.showStudents) ||
      (!isStudent && !this.state.showNonStudents) ||
      (isJunior && !this.state.showJuniors) ||
      (!isJunior && !this.state.showSeniors) ||
      (this.state.nameFilter &&
        this.state.nameFilter.length >= 3 &&
        !row.full_name_search
          .toLowerCase()
          .includes(this.state.nameFilter.toLowerCase()))
    );
  }

  countFilteredDebaters() {
    if (!this.state.tableData) return 0;
    let count = 0;
    for (const row of this.state.tableData) {
      if (this.rowFilter(row) && row.parentId === null) count++;
    }
    return count;
  }
  countEvents() {
    if (!this.state.eventColumns) return 0;
    return this.state.eventColumns.length;
  }

  render() {
    /* Overriding the default Title component with more filters */
    const tableTitle = (
      <UsersTableTitle
        width={this.state.width * 0.88}
        firstDate={this.state.firstDate}
        lastDate={this.state.lastDate}
        changeFirstDate={dt => this.changeFirstDate(dt)}
        changeLastDate={dt => this.changeLastDate(dt)}
        onChangeRankFilter={rank => this.toggleRank(rank)}
        onChangeStudentFilter={students => this.toggleStudents(students)}
        onRefresh={() => this.refreshHistory()}
        onNameSearch={name => this.setNameFilter(name)}
        handleColumnToggle={(column, index) => this.toggleColumn(column, index)}
        exportData={this.state.exportData}
        columnsFilter={this.state.debaterColumnsFilter}
        columns={this.state.debaterColumns.concat(this.state.eventColumns)}
        debaterCount={this.countFilteredDebaters()}
        eventCount={this.countEvents()}
      />
    );

    if (this.state.loading) {
      return (
        <div style={{ marginLeft: "5%", marginRight: "5%", marginTop: "5%" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <MaterialTable
            isLoading={this.state.loading}
            title={tableTitle}
            options={{
              search: false,
              columnsButton: false,
              exportButton: false
            }}
          />
        </div>
      );
    }
    return (
      <div style={{ marginLeft: "5%", marginRight: "5%", marginTop: "5%" }}>
        {this.state.selectedUser && (
          <UserDetailsDialog
            open={true}
            debater={this.state.selectedUser}
            user={this.props.user}
            onClose={this.closeUserDialog}
            onDeleteUser={this.handleDeleteUser}
          />
        )}

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />

        {/*This is really weird, but DO NOT REMOVE THIS <br/>*/}
        <br />
        {/* {
          this.state.filteredTableData && <VirtualizedMaterialTable
            tableTitle={tableTitle}
            isLoading={this.state.loading}
            eventColumns={this.state.debaterColumns.concat(this.state.eventColumns)}
            data={this.state.filteredTableData}
          />
        } */}
        <MaterialTable
          components={{
            Cell: props => {
              // Coloring duplicate data (from expanded rows) in white
              if (
                props["rowData"]["event_attribute_type"] !== "Score" &&
                !props["columnDef"]["isDateColumn"]
              ) {
                return <MTableCell {...props} style={{ color: "#FFFFFF" }} />;
              } else {
                return <MTableCell {...props} />;
              }
            }
          }}
          localization={{ toolbar: { searchTooltip: "Search Users" } }}
          isLoading={this.state.loading}
          columns={this.state.debaterColumns.concat(this.state.eventColumns)}
          data={this.state.filteredTableData}
          title={tableTitle}
          parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          options={{
            rowStyle: rowData => ({
              // Marking "Boxes" around expanded rows
              borderTop:
                rowData.tableData.isTreeExpanded &&
                  rowData.event_attribute_type === "Score"
                  ? "2px solid #ccc"
                  : "1px solid #ccc",
              borderBottom:
                rowData.event_attribute_type === "Position"
                  ? "2px solid #ccc"
                  : "1px solid #ccc"
            }),
            exportFileName:
              "TAU Debate Users Report - " + formatDate(new Date()),
            search: false,
            sorting: true,
            filtering: false,
            columnsButton: false,
            exportButton: false, //TODO: My export function
            doubleHorizontalScroll: true,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 50, 100],
            pageSize: 20
          }}
        />
      </div>
    );
  }
}
