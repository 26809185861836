import { sendEmails } from "./fire";
import { get_all_debaters, update_user_details } from "./BackendFunctions";
/* send email to new users, allowing them to know they where approved by admins */
export function sendApprovedEmail(debaters) {
  let subject = "Your TAU Debate membership has been approved!";
  let html =
    "<p>Your TAU Debate membership has been approved!<br />You can now register to upcoming practices and lessons at <a href=https://www.debate.org.il/>www.Debate.org.il</a>.</p>";
  return sendEmailsTo({ id: debaters }, subject, html);
}

/* the function get users (as an object) and sends them the email
the function returns a promise
*/
export function sendEmailsTo(debaters, subject, html) {
  //remove users who unsubscribed
  let Finaldebaters = [];
  Object.values(debaters).forEach(debater => {
    if (debater.email_subscribed) {
      Finaldebaters.push(debater.email);
    }
  });

  html = "<div>" + html + "</div>";
  let finalHtml = addUnsubsribeOption(html);
  let emailData = {
    subject: subject || "TAU Debate Society",
    emails: Finaldebaters, // a list of emails
    html: finalHtml
  };
  return sendEmails(emailData);
}

function addUnsubsribeOption(html) {
  let url = "";
  if (
    window.location.hostname.includes("debate.org.il") ||
    window.location.hostname.includes("debatetest-1399a.firebaseapp.com")
  ) {
    url = "www.debate.org.il/unsubscribe";
  } else {
    url = "https://debateproject.firebaseapp.com/unsubscribe";
  }
  let finalHtml =
    html +
    '<br /><div dir="rtl" style="direction: ltr;"><a href="https://www.facebook.com/taudebate/" target="_blank" rel="noopener"><img src="https://drive.google.com/uc?id=1JREyLQ3bCaRcUH4UmouTOQhkAYi_Sd76&amp;export=download" alt="https://www.facebook.com/taudebate/" width="96" height="96" /></a></div><div dir="rtl" style="direction: ltr;">Have you visited our <a href="https://www.facebook.com/taudebate/">Facebook page</a>?</div>';
  finalHtml =
    finalHtml +
    "<small>If you no longer wish to recieve our emails, <a href=" +
    url +
    ">click here to unsubscribe</a>.</small>";
  return finalHtml;
}

export function sendEmailsToDebaterGroup(
  sendToJuniors,
  sendToSeniors,
  sendToOthers,
  sendToNonStudents,
  subject,
  html
) {
  let p = new Promise((resolve, reject) => {
    get_all_debaters()
      .then(debaters => {
        let Finaldebaters = [];
        Object.values(debaters).forEach(debater => {
          if (
            is_this_email_for_me(
              debater,
              sendToJuniors,
              sendToSeniors,
              sendToOthers,
              sendToNonStudents
            )
          ) {
            Finaldebaters.push(debater.email);
          }
        });
        
        let finalHtml = addUnsubsribeOption(html);

        let emailData = {
          subject: subject || "TAU Debate Society",
          emails: Finaldebaters, // a list of emails
          html: finalHtml
        };
        sendEmails(emailData).then(resolve(true));
      })
      .catch(error => reject(error));
  });
  return p;
}

/* get event and return if current user should see it */
export function is_this_email_for_me(
  debater,
  sendToJuniors,
  sendToSeniors,
  sendToOthers,
  sendToNonStudents
) {
  let rank = debater.rank;
  let student = debater.student;
  let club = debater.club;
  if (
    !debater.email_subscribed ||
    debater.permissions === "Pending" ||
    debater.permissions === "Login"
  ) {
    return false;
  }
  if (!sendToJuniors && rank === "Junior") {
    return false;
  }
  if (!sendToOthers && club !== "TAU") {
    return false;
  }
  if (!sendToSeniors && rank === "Senior") {
    return false;
  }
  if (!sendToNonStudents & (student === false)) {
    return false;
  }
  return true;
}

export function unsubscribe(debater) {
  debater.email_subscribed = false;
  return update_user_details(debater);
}
