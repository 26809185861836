import React, { Component } from 'react';
import Paper from "@material-ui/core/Paper";
import {UserDetails} from "./UserDetails";

class ApprovalPage extends Component {
    state = {
        showUserDetails: false
    };
    componentDidMount(){
        this.props.onLoad();
    }
    openUserDetails(user){
        user.permissions = "Registration";
        this.setState({showUserDetails: true})
    }
    closeUserDetails(user){
        user.permissions = "Pending";
        this.setState({showUserDetails: false})
    }
    render() {
        return (
            <div style={{margin: 0, padding: 0}}>
            <div  style={{margin: 0, padding: 0}} align="center"><br/>
            <Paper style={{margin: 0, padding: 20, width: '85%', maxWidth: 630, textAlign: 'left'}}>
                <h1>Thank you for registering to TAU Debate!</h1>
                <h3>
                    Your registration is now pending approval of one of our admins.<br/>
                    We will notify you by email once you are approved.
                </h3>

                {!this.state.showUserDetails &&
                <label>
                    <span style={{color: "#7799DD", cursor: 'pointer'}}
                          onClick={() => this.openUserDetails(this.props.user)}>Click Here</span>
                    <span> to edit your personal information</span></label>
                }

                {this.state.showUserDetails &&
                <label>
                    <span style={{fontWeight:'bold', color: "#7799DD", cursor: 'pointer'}}
                          onClick={() => this.closeUserDetails(this.props.user)}>
                        Close
                    </span>
                    <span> personal information</span>
                </label>
                }


            </Paper>
            </div>
            {this.state.showUserDetails &&
                <UserDetails permissions={"Registration"}
                             debater={this.props.user}
                             user={this.props.user}
                             onClose={()=>this.closeUserDetails(this.props.user)}/>
            }
            </div>
         );
    }
}

export default ApprovalPage;
