import firebase from "./fire";
import "firebase/storage";
import { db } from "./fire";

/* this page contains all backend functions regarding uploading and downloading files to firebasebase Storage
for now we are saving user picturs under profilePic/userid
*/

/* uploads new img, delete old img if exists and return a URL */
export function upload_img(img, oldImgUrl, userId) {
  let storageRef = firebase.storage().ref();
  //create user pic name
  let picRef = storageRef.child(
    "profilePic/" + userId + img.name.split(".").pop()
  );

  let p = new Promise((resolve, reject) => {
    let upload = picRef.put(img);
    upload.on(
      "state_changed",
      // can send upload state, i dont use it
      () => {},
      //hendel error
      function(error) {
        reject(error);
      },
      // Handle successful uploads on complete
      function() {
        //delete old image
        delete_img_from_storage(oldImgUrl);

        //return new img URL
        upload.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          //update profile pic
          db.collection("Debaters")
            .doc(userId)
            .update({ picture: downloadURL });
          db.collection("Debaters")
            .doc("All_Debaters")
            .update({ [userId + ".picture"]: downloadURL });

          //return url
          resolve(downloadURL);
        });
      }
    );
  });
  return p;
}

/* delete img from storage (if it is from storage) */
function delete_img_from_storage(oldImgUrl) {
  if (oldImgUrl) {
    if (oldImgUrl.includes("https://firebasebasestorage.googleapis.com")) {
      var imgReg = firebase.storage().refFromURL(oldImgUrl);
      imgReg.delete();
    }
  }
}
