import React, { Component } from "react"
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import {formatDate, tsToDate} from "../Utilities/DateTimeUtils";
import {handleError} from "../Utilities/OtherUtils";
import {add_registration_for_user} from "../../Backend/BackendFunctions";
import {toast} from "react-toastify";
import {SelectDebatersFromDatabase} from "./SelectDebatersFromDatabase";
import {CloseButton} from "../Common/Buttons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {AddFakeDebaters} from "./AddFakeDebaters";

/**
 * A dialog for adding registrations to an event.
 * Divided to real debaters, selected from the database, and temporary external debaters not signed up in the system
 */
export class ManuallyRegisterDebatersDialog extends Component {

  constructor(props) {
      super(props);
      this.state={
          loading: false,
          open: true,
          openTab: "real"
      };
      this.handleClose = this.handleClose.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.recursiveRegistration = this.recursiveRegistration.bind(this);
      this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleChangeTab(event, newValue) {
      this.setState({openTab: newValue});
  }

  handleClose(){
      this.setState({open: false});
      this.props.onClose();
      this.setState({open: true});
  }

  handleSubmit(debaters) {
      this.setState({loading: true});
      this.recursiveRegistration(debaters, 0);
  }

  // Recursively registering the array of users in order to keep everything async
  recursiveRegistration(debaters, index) {
      if(index >= debaters.length) {
          toast.info("Successfully registered " + debaters.length.toString() + " debaters");
          this.setState({loading: false},()=> this.handleClose());
          return;
      }
      add_registration_for_user(this.props.event.id, debaters[index].uid)
          .then(()=> this.recursiveRegistration(debaters, index+1))
          .catch(e => handleError(e));
  }

  debaterFilter = (debater) => {
      return !(
          (debater.uid in this.props.registeredDebaterIds) ||
          (Array.isArray(this.props.registeredDebaterIds) && this.props.registeredDebaterIds.includes(debater.uid))
      );
  };

  render() {
      if(!this.props.event) return null;
      const title = this.props.event.event_type +", "
          +formatDate(this.props.event.date instanceof Date ? this.props.event.date : tsToDate(this.props.event.date));
      return (
          <Dialog open={this.state.open}
                  maxWidth={"md"}
                  style={{overflowX: 'hidden'}}
                  onClose={this.handleClose}>
              <DialogTitle style={{padding: 0, margin: 0, overflowX: 'hidden'}}>
                  <CloseButton onClick={()=>this.handleClose()}/>
                  <p style={{margin: 20, width: '100%'}}>
                      Register Debaters to {title}
                  </p>

                  {this.props.onAddFakeDebaters &&
                  <Tabs value={this.state.openTab}
                        indicatorColor="primary"
                        textColor="primary"
                        style={{margin: 0, padding: 0, width: '100%', borderBottom: '1px solid #ccc'}}
                        onChange={this.handleChangeTab}>
                      <Tab style={{width: '50%'}}
                           disabled={this.state.loading}
                           value="real"
                           label="real"/>
                      <Tab style={{width: '50%'}}
                           disabled={this.state.loading}
                           value="fake"
                           label="Mock"/>
                  </Tabs>}

              </DialogTitle>
              <DialogContent style={{overflowX: 'hidden'}}>
                  {this.state.loading &&
                  <div align="center"><br/><br/><CircularProgress/></div>}


                  {this.state.openTab === "real" &&
                  <div style={{marginTop: 10}}>
                      <SelectDebatersFromDatabase filter={this.debaterFilter}
                                                  loading={this.state.loading}
                                                  onClose={this.handleClose}
                                                  onSubmit={(debaters)=>this.handleSubmit(debaters)}/>
                  </div>}

                  {this.state.openTab === "fake" &&
                  <AddFakeDebaters loading={this.state.loading}
                                   onClose={this.handleClose}
                                   onSubmit={(debaters)=>{
                                       this.props.onAddFakeDebaters(debaters);
                                       this.handleClose();
                                   }}/>}

              </DialogContent>
          </Dialog>
      )
  }
}
