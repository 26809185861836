import React, { Component } from "react"
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import {ToggleButton} from "../Common/ToggleButton";
import {MultiSelectMenu} from "../Common/MultiSelectMenu";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import {CSVExporter} from "../Common/CSVExporter";
import {TextSearch} from "../Common/TextSearch";

/**
 * A toolbar component for the user management page
 * Includes several widgets for filtering, searching and exporting data
 */
export class UsersTableTitle extends Component {

  constructor(props) {
      super(props);
      this.state = {
          rank: 'junior',
          students: 'both',
          searchName: '',
          columns: [],
          anchorEl: null
      };
      this.toggleRank = this.toggleRank.bind(this);
      this.toggleStudents = this.toggleStudents.bind(this);
      this.searchName = this.searchName.bind(this);
  }

  toggleRank(rank) {
      this.setState({rank: rank},()=>this.props.onChangeRankFilter(rank));
  }

  toggleStudents(students) {
      this.setState({students: students},()=>this.props.onChangeStudentFilter(students));
  }

  searchName(name) {
      this.setState({searchName: name});
      this.props.onNameSearch(name);
  }

  render(){

      const rankToggle = <ToggleButton values={['junior', 'senior', 'both']}
                                       labels={['Junior', 'Senior', 'Both']}
                                       default={'junior'}
                                       onChange={(value)=>this.toggleRank(value)}/>;
      const studentToggle = <ToggleButton values={['students', 'non_students', 'both']}
                                          labels={['Students', 'Non Students', 'Both']}
                                          default={'both'}
                                          onChange={(value)=>this.toggleStudents(value)}/>;

      const datePickers =
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                  showTodayButton={true}
                  style={{width: 100, marginRight: 20}}
                  value={this.props.firstDate}
                  onChange={(dt)=>this.props.changeFirstDate(dt)}
                  maxDate={this.props.lastDate}
                  format="MMM dd"
                  label="From"
              />
              <DatePicker
                  showTodayButton={true}
                  style={{width: 100}}
                  value={this.props.lastDate}
                  onChange={(dt)=>this.props.changeLastDate(dt)}
                  minDate={this.props.firstDate}
                  format="MMM dd"
                  label="To"
              />
          </MuiPickersUtilsProvider>;

      const actionIcons =
          <div style={{display: 'inline'}}>
              <span style={{maxWidth: 150, textAlign: 'right'}}>
                  <TextSearch
                      label={"Search Debater"}
                      value={this.state.searchName}
                      style={{display: 'inline', margin: 0}}
                      onChange={(value)=>this.searchName(value)}
                  />
              </span>
              <span style={{width: 25, textAlign: 'right'}}>
              <MultiSelectMenu
                  values={this.props.columnsFilter}
                  title={"Add / Remove Columns"}
                  buttonIcon={<ViewColumnIcon/>}
                  onToggle={(column, index) => this.props.handleColumnToggle(column, index)}/>
              </span>
              <span style={{width: 10, textAlign: 'right'}}>
              <CSVExporter
                  data={this.props.exportData}
                  title={"Export CSV"}
              />
          </span>
              <span style={{width: 10, textAlign: 'right'}}>
              <IconButton onClick={()=>this.props.onRefresh()}
                          title={"Refresh History"}>
                  <RefreshIcon/>
              </IconButton>
          </span>
          </div>;

      const header =
          <h1 style={{display: 'inline'}}>
              Debaters
          </h1>;

      const debaterCountSpan =
        <span style={{float: 'left', marginTop: 10, color: '#999999', fontSize: 12}}>
          Debaters: {this.props.debaterCount}
        </span>;

      const eventCountSpan =
        <span style={{float: 'left', marginTop: 10, color: '#999999', fontSize: 12, marginLeft: 10}}>
          Events: {this.props.eventCount}
        </span>;

      if(this.props.width > 1000) {
          return (
              <div style={{display: 'inline-block', width: this.props.width}}>
                  <br/>{header}<br/>
                  <table style={{borderSpacing: 0, width: '100%', marginTop: 10}}>
                      <tbody style={{padding: 0, borderCollapse: 'collapse', width: '100%'}}>
                      <tr>
                          <td style={{width: 250}}>
                              {datePickers}
                          </td>
                          <td style={{width: 250}} align={"center"}>
                              {rankToggle}
                          </td>
                          <td style={{width: 300}}>
                              {studentToggle}
                          </td>
                          <td align={"left"}>
                              {actionIcons}
                          </td>
                      </tr>
                      </tbody>
                  </table>
                {debaterCountSpan}
                {eventCountSpan}
              </div>
          );
      } else {
          return (
              <div>
                  <br/>
                  {header}
                  <br/>
                  {actionIcons}
                  <br/>
                  {datePickers}
                  <br/><br/>
                  {rankToggle}
                  <br/><br/>
                  {studentToggle}
                  <br></br>
                  {debaterCountSpan}
                  {eventCountSpan}
              </div>
          );
      }
  }
}
