import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { FeedbackForm } from "./FeedbackForm";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { toast } from "react-toastify";
import { add_feedback } from "../../Backend/BackendFunctions";
import { getFullName, getInitials } from "../Utilities/DebaterUtilities";
import { handleError, isMobile } from "../Utilities/OtherUtils"
import { DebaterAvatar } from "../UserComponents/DebaterAvatar";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Feedback component for one event of a chair's feedback feed
 * Includes a feedback form for each debater that needs feedback, and a selector to toggle between them
 */
export class FeedbackFormMenu extends Component {

    //@pre: There is at least one feedback left to fill, otherwise no point of loading this component
    constructor(props) {
        super(props);
        this.state = {
            debaters: [],
            debaterId: null,
            loading: true
        };

        this.handleDebaterChange = this.handleDebaterChange.bind(this);
        this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
        this.handleFeedbackSave = this.handleFeedbackSave.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    // Switching event
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.feedback && this.props.feedback) ||
            (prevProps.feedback && prevProps.feedback.event &&
            this.props.feedback && this.props.feedback.event &&
            prevProps.feedback.event.id !== this.props.feedback.event.id)) {
            this.refresh();
        }
    }

    refresh() {
        let debatersToFeedback = [];
        if(!this.props.feedback) return;
        for (const key of Object.keys(this.props.feedback.debaters)) {
            const debater = this.props.feedback.debaters[key];
            debater.uid = key;
            debatersToFeedback.push(this.props.feedback.debaters[key]);
        }

        if (debatersToFeedback.length > 0) {
            debatersToFeedback = this.sortDebatersToFeedback(debatersToFeedback);
            this.setState({
                debaters: debatersToFeedback,
                debaterId: debatersToFeedback[0].uid,
                loading: false
            });
        }
    }

    sortDebatersToFeedback(debaters) {
        const positions = {
            'Opening Government': 1,
            'Opening Opposition': 2,
            'Closing Government': 3,
            'Closing Opposition': 4,
            'Panel': 5,
        };
        return debaters.sort((a, b) => {
            if(a.position === b.position) return getFullName(a).toLowerCase() < getFullName(b).toLowerCase() ? 1:-1;
            return positions[a.position] < positions[b.position] ? -1:1;
        });
    }

    handleDebaterChange(event) {
        const debaterId = event.target.value;
        this.setState({debaterId: debaterId});
    }

    handleFeedbackChange() {
        this.forceUpdate();
    }

    /* save feedback to DB  */
    handleFeedbackSave() {
        this.setState({loading: true});
        const newDebaters = [];
        for (const debater of this.state.debaters) {
            if (debater.uid !== this.state.debaterId) {
                newDebaters.push(debater);
            }
        }
        const finished = newDebaters.length <= 0;
        const feedback = this.props.feedback.debaters[this.state.debaterId].feedback;
        feedback.score = parseInt(feedback.score, 10);

        add_feedback(this.props.feedback.event, feedback, this.state.debaterId, finished)
            .then(() => {
                toast.info("Feedback Successfully Submitted");
                this.props.onFeedbackSave(
                    this.props.feedback,
                    this.state.debaterId
                );
                this.setState({
                    debaterId: newDebaters.length > 0 ? newDebaters[0].uid : null,
                    debater: newDebaters,
                    loading: false
                });
            }).catch(e => {
                handleError(e);
                this.setState({loading: false});
        });
    }

    render() {
        if(this.state.loading) {
            return <div align={"center"}><CircularProgress/></div>;
        }
        if(!this.props.feedback
            || !this.props.feedback.debaters
            || !this.state.debaterId
            || !this.props.feedback.debaters[this.state.debaterId]) {
            return null;
        }
        return (
            <div className="app-container-2">
                <div align={"left"}>
                    <Paper style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, maxWidth: 500}}>
                        <br />
                        <label style={{ fontSize: 16, marginLeft: 10 }}>Debater: </label>
                        <Select
                            label={"Debater"}
                            style={{ marginLeft: 10, padding: 0, marginBottom: 0 }}
                            onChange={e => this.handleDebaterChange(e)}
                            value={this.state.debaterId}>
                            {this.state.debaters.map(debater => {
                                const feedback = this.props.feedback.debaters[debater.uid];
                                if (feedback) {
                                    return (
                                        <MenuItem
                                            key={debater.uid}
                                            value={debater.uid}
                                            style={{
                                                display: "flex",
                                                border: "1px solid #ccc",
                                                borderRadius: "0px",
                                                backgroundColor:
                                                    debater.uid === this.state.debaterId
                                                        ? "#DDDDDD"
                                                        : "#FFFFFF"
                                            }}>
                                            <div style={{display: "inline-block"}}>
                                                <DebaterAvatar debater={debater} size={40} />
                                            </div>
                                            <ListItemText
                                                style={{display: "inline", marginLeft: 10}}
                                                primary={
                                                    <span>
                                                        {getFullName(debater)}
                                                        <span style={{fontWeight:'normal', marginLeft: 10, color:'#999999', fontSize: 12}}>
                                                            ({isMobile() ? getInitials(debater.position) : debater.position})
                                                        </span>
                                                    </span>
                                                }
                                            />
                                            <br/>
                                        </MenuItem>
                                    );
                                } else return null;
                            })}
                        </Select>

                        <FeedbackForm
                            style={{marginTop: 0}}
                            event={this.props.feedback.event}
                            debater={this.props.feedback.debaters[this.state.debaterId]}
                            feedback={this.props.feedback.debaters[this.state.debaterId].feedback}
                            onFinish={() => this.handleFeedbackSave()}
                            onChange={() => this.handleFeedbackChange()}
                        />
                    </Paper>
                </div>
            </div>
        );
    }
}
