import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import TrashListIcon from '@material-ui/icons/DeleteSweep';
import Tooltip from '@material-ui/core/Tooltip';
import { Droppable} from 'react-beautiful-dnd';
import Dialog from '@material-ui/core/Dialog';
import MyDroppable from './myDroppable';

/*
* The assignemnt's trash list (containing debaters we don't wish to assign).
* This component is a popup (dialog)
*/
class TrashList extends Component {
    state = {  }
    render() { 
        return (
        
        <div className="trashList">
        
        <Dialog 
                onClose={this.props.onTrashListClose}  
                aria-labelledby="simple-dialog-title" 
                open={this.props.trashListOpen}
                style = {{padding : 20,}}
                >

                <MyDroppable 
                    registrations={this.props.registrations}
                    incognito={this.props.incognito}
                    data={this.props.trashListData} 
                    droppableId="trashList"
                    changeDebaterId={this.props.changeDebaterId}
                    chair={false}
                    updateRegistaration={this.props.updateRegistaration}/>
                
            </Dialog>

            <Droppable droppableId="trashListIcon">

                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}>
                            <IconButton style={{marginRight : 40}} onClick={this.props.onTrashListOpen}>
                                <Tooltip title="Trash List" placement="top">
                                    <TrashListIcon style={{ fontSize: 50 }} />
                                </Tooltip>
                            </IconButton>
                            {provided.placeholder }
                        </div>
                    )}



            
            </Droppable>
        </div>);
    }
}
 
export default TrashList;