import React, { Component } from 'react';
import List from '@material-ui/core/List';
import EventListItem from './EventListItem';
import {tsToDate , formatDateDayTime} from "../Utilities/DateTimeUtils";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {eventStatusSummary} from "../EventManagementComps/EventTimelineStatus";
import EventForm from "./EventForm";
import Collapse from "@material-ui/core/Collapse";

/**
 * Generates a list of event items for the debater's "My Events" feed
 * Events are visually grouped by date
 */
class EventList extends Component {

    generateList = (dictionary, isFeedback) => {
        let list = [];
        let date = "";
        let prevDate = "";

        for (let id in dictionary) {
            const feedback = isFeedback ? dictionary[id]:null;
            const event = isFeedback ? feedback.event : dictionary[id];
            const selected =
                (isFeedback && this.props.feedback && this.props.feedback.event && this.props.feedback.event.id === id) ||
                (!isFeedback && this.props.event && this.props.event.id === id);
            date = tsToDate(event.date).valueOf();
            if (event.event_type === 'Lesson' && event.status === "edit") event.status = "assigned";
            const dateDivider =
                <span style={{float: 'left', textAlign: 'left', marginLeft: 2, marginBottom: 5}}>
                    <span style={{fontSize: 16}} key={date}>
                        {formatDateDayTime(tsToDate(event.time))}
                    </span><br/>
                    {eventStatusSummary(event, false)}
                    <br/>
                </span>;
            if (date !== prevDate) {
                list.push(getDivider(id, dateDivider, prevDate))
            }
            prevDate = date;
            const onSelectEvent = () => this.props.onSelectEvent(id);
            list.push(
                <div key={id + (new Date()).valueOf().toString()}>
                    <EventListItem
                        status={event.status}
                        event={event}
                        selected={selected}
                        onSelectEvent={onSelectEvent}
                        feedback={feedback}
                        onFeedbackSave={this.props.onFeedbackSave}/>

                    {this.props.mobile  &&
                    <Collapse in={selected}
                              timeout="auto"
                              unmountOnExit>
                        <EventForm
                            event={event}
                            feedback={feedback}
                            user={this.props.user}
                            onSubmitRegistration={this.props.onSubmitRegistration}
                            onCancelOnTime={this.props.onCancelOnTime}
                            onFeedbackSave={this.props.onFeedbackSave}
                            onUndoCancel={this.props.onUndoCancel}
                            onCancel={this.props.onCancel}
                            onCheckIn={this.props.onCheckIn}/>
                    </Collapse>}
                </div>
            );
        };
        return list;
    };

    render() {
        return (
            <List style={{maxHeight : '100%', position: 'relative'}}>
                {this.props.tab === "feedback" ?
                    this.generateList(this.props.feedbacks, true) : this.generateList(this.props.events, false)}
            </List>
        );
    }
}

export default EventList;

export function getDivider(key, dateDivider, prevDate){
    return (
        <div key={key} style={{margin: 0, padding: 0}}>
            {prevDate !== "" &&
            <Divider component="li" style={{marginTop: 25, marginBottom: 10}} />}
            <li style={{margin: 0, padding: 0, float: 'left', textAlign: 'left'}}>
                <Typography
                    color="textSecondary"
                    display="block"
                    variant="subtitle2">
                    {dateDivider}
                </Typography>
            </li>
        </div>
    );
}
