import React, { Component } from "react"
import {getAvatar, getFullName} from "../Utilities/DebaterUtilities";
import CardHeader from "@material-ui/core/CardHeader";

/**
 * Compact display of profile picture, full name and basic details (gender, rank, student)
 * Can be clickable if an onClick prop is given
 */
export class DebaterCardHeader extends Component {

  render(){
      if(!this.props.debater) return null;
      const subheader =
          (this.props.debater.rank[0]) +'r | '+
          (this.props.debater.student ? 'Student':'Non Student') + ' | ' +
          (this.props.debater.gender !== 'Male' && this.props.debater.gender !== 'Female' ? '-' : this.props.debater.gender[0]);

      const fullName = getFullName(this.props.debater);
      const title = this.props.onClick ?
          <span style={{color:"#3377AA", cursor: 'pointer'}}
                onClick={this.props.onClick}>{fullName}</span> : fullName;

      return (
          <CardHeader style={{margin: 0, padding: 0, paddingLeft: 10, whiteSpace: 'nowrap'}}
                      avatar={
                          <div onClick={this.props.onClick ? this.props.onClick : ()=>null}
                               title={this.props.tooltip}
                               style={{cursor: this.props.onClick?'pointer':'auto',
                                       marginRight: 5, margin: 0, padding: 0, whiteSpace: 'nowrap'}}>
                              {getAvatar(this.props.debater)}
                          </div>}
                      title={title}
                      subheader={subheader}
          />
      );
  }
}
